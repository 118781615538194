import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useLocalization } from "@progress/kendo-react-intl";
import { AppConstant, LocalizedMessages } from "common/constant";
export var Transform = function (_a) {
    var mkey = _a.mkey, values = _a.values;
    var localization = useLocalization();
    var localizedString = localization.toLanguageString(mkey, LocalizedMessages[AppConstant.DEFAULT_LOCALE][mkey]);
    // Replace placeholders in the localized string with provided values
    if (values) {
        Object.keys(values).forEach(function (key) {
            var placeholder = "{{".concat(key, "}}");
            localizedString = localizedString.replace(new RegExp(placeholder, "g"), values[key]);
        });
    }
    return _jsx(_Fragment, { children: localizedString });
};
