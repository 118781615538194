var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { ROUTE_TIME_SHEETS } from "common/constant/CustomRoutes";
import { ONLOCATION_CLIENTS, WEBUSERROLEID } from "common/constant/Enums";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppConstant, ComponentConstant, CustomCalendar, DialogBox, FormButtons, FormDatePicker, FormDropDownListWithValue, FormHeading, Transform, TranslationConstant, createEncodedURL, formatDate, getUserDetailsFromLocalStorage, requiredValidator, useCurrentViewContext, useI9VerificationViewContext, useTimesheetViewContext, useWorker1099, } from "./index";
var ifExistMessage = {
    ACTIVE: "Timesheet for the above weekend already exist in active tab, please click on edit and make changes to that timesheet.",
    INPROGRESS: "Timesheet for the above weekend already exist in in-process tab, please click on RECALL and make changes to that timesheet in ACTIVE tab .",
};
var AddTimesheetDialogContent = function (_a) {
    var _b = _a.isModalContent, isModalContent = _b === void 0 ? true : _b, clientData = _a.clientData, handleClose = _a.handleClose;
    var _c = useTimesheetViewContext(), createTimeSheet = _c.createTimeSheet, fetchAgreementDateRange = _c.fetchAgreementDateRange, agreementDateRange = _c.agreementDateRange;
    var i9clientDropDownList = useI9VerificationViewContext().i9clientDropDownList;
    var _d = useCurrentViewContext(), employeeList = _d.employeeList, userAccessType = _d.userAccessType;
    var states = useWorker1099().states;
    var _e = getUserDetailsFromLocalStorage("pro"), ClientId = _e.ClientId, IndexNumber = _e.IndexNumber, WebUserRoleId = _e.WebUserRoleId, FirstName = _e.FirstName, LastName = _e.LastName;
    var employeeID = IndexNumber || null;
    var Navigate = useNavigate();
    var _f = useState(false), modalState = _f[0], changeModalState = _f[1];
    var _g = useState({}), modalContent = _g[0], updateModalContent = _g[1];
    var _h = useState(false), isEndeavorClient = _h[0], setIsEndeavorClient = _h[1];
    var _j = useState(false), isOnLocationPerdiemClient = _j[0], setIsOnLocationPerdiemClient = _j[1];
    var employeeInfoDetails = JSON.parse(localStorage.getItem("employeeInformation"));
    useEffect(function () {
        if (i9clientDropDownList && i9clientDropDownList.length >= 0) {
            var selectedClientData = i9clientDropDownList.find(function (item) { return item.clientID === ClientId; });
            setIsEndeavorClient(WEBUSERROLEID.ENDEAVOR_CLIENT_ID === (selectedClientData === null || selectedClientData === void 0 ? void 0 : selectedClientData.masterClientID));
        }
    }, [i9clientDropDownList]);
    useEffect(function () {
        if (employeeID &&
            (agreementDateRange === null || agreementDateRange === void 0 ? void 0 : agreementDateRange.length) === 0 &&
            isEndeavorClient &&
            !isOnLocationPerdiemClient) {
            fetchAgreementDateRange(employeeID);
        }
    }, [employeeID, isEndeavorClient]);
    useEffect(function () {
        setIsOnLocationPerdiemClient(// OnLocation Perdiem Client has it's own agreement so no need to fetch agreement date range
        (ONLOCATION_CLIENTS.OnLocation === ClientId) &&
            !(employeeInfoDetails === null || employeeInfoDetails === void 0 ? void 0 : employeeInfoDetails.exempt));
    }, [ClientId]);
    var handleCreateTimeSheetSubmit = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var weekEndingDate, formatedWeekEndingDate, dataObj, response, url;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!e.isValid) return [3 /*break*/, 2];
                    weekEndingDate = formatDate(new Date(e.values.weekEndingDate), "yyyy-MM-dd");
                    formatedWeekEndingDate = formatDate(new Date(e.values.weekEndingDate), "MM/dd/yyyy");
                    dataObj = {
                        clientId: ClientId,
                        employeeId: !isModalContent ? e.values.employee : IndexNumber,
                        weekEndingDate: weekEndingDate,
                        createdByUserRole: WebUserRoleId,
                        createdByFullName: "".concat(FirstName, " ").concat(LastName),
                        workStateId: !isModalContent && (clientData === null || clientData === void 0 ? void 0 : clientData.multiStateTimesheets) !== true
                            ? e.values.state
                            : undefined,
                    };
                    return [4 /*yield*/, createTimeSheet(dataObj)];
                case 1:
                    response = _a.sent();
                    if (response.data.isSuccess === true) {
                        url = !isModalContent
                            ? createEncodedURL("".concat(ROUTE_TIME_SHEETS.VIEW_TIME_SHEET), {
                                weekEndingDate: formatedWeekEndingDate,
                                TimeSheetId: response.data.timesheetID,
                                employeeId: e.values.employee,
                                page: "addEdit",
                                FirstName: e.values.employee.firstName,
                                LastName: e.values.employee.lastName,
                                UserRoleId: WebUserRoleId,
                                clientId: ClientId,
                                pageRoute: ROUTE_TIME_SHEETS.MANAGE_UNSIGNED_TIME_SHEETS,
                            })
                            : createEncodedURL("/timesheet", {
                                weekEndingDate: formatedWeekEndingDate,
                                TimeSheetId: response.data.timesheetID,
                                UserRoleId: WebUserRoleId,
                            });
                        Navigate(url);
                    }
                    else {
                        onSubmitFailure(response.data);
                    }
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    var onSubmitFailure = function (errorType) {
        updateModalContent({
            headingText: _jsx(Transform, { mkey: TranslationConstant.ADD_TIMECARD_ALERT_HEADING }),
            bodyText: (_jsxs("div", __assign({ className: "block subheading d-flex align-items-center" }, { children: [_jsx("i", { className: "fa fa-times-circle fa-lg" }), _jsx("span", { children: ifExistMessage[errorType] })] }))),
            cancelText: _jsx(Transform, { mkey: TranslationConstant.CLOSE_BUTTON }),
            isErrorDialog: true,
            fixedWidth: true,
        });
        changeModalState(true);
    };
    var CustomCalendarWrapper = function (props) {
        return (_jsx(CustomCalendar, __assign({ enabledDays: [6], enabledDateRanges: isEndeavorClient && !isOnLocationPerdiemClient ? agreementDateRange : null }, props)));
    };
    var DatePickerField = function (props) {
        return _jsx(FormDatePicker, __assign({}, props, { calendar: CustomCalendarWrapper }));
    };
    return (_jsxs(_Fragment, { children: [!isModalContent && (_jsx(FormHeading, { data: {
                    title: (_jsx(Transform, { mkey: TranslationConstant.PLEASE_FILL_THE_DETAILS_BELOW })),
                } })), _jsx(Form, { onSubmitClick: handleCreateTimeSheetSubmit, render: function (formRenderProps) { return (_jsx(FormElement, __assign({ className: "general-form ".concat(isModalContent && "border-0 mx-0 pb-0 mb-0") }, { children: _jsxs("fieldset", __assign({ className: !isModalContent && "k-form-fieldset row" }, { children: [_jsx("div", __assign({ className: !isModalContent && "col-12 col-sm-6 col-md-4 mb-3" }, { children: _jsx(Field, { id: "weekEndingDate", label: _jsx(Transform, { mkey: TranslationConstant.LABEL_WEEK_END_DATE }), isRequired: true, validator: requiredValidator, readonlyInput: true, name: "weekEndingDate", component: DatePickerField, showHint: true, hint: _jsx(Transform, { mkey: TranslationConstant.CLICK_ON_CALENDAR_ICON }) }) })), !isModalContent && (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "col-12 col-sm-6 col-md-4 mb-3" }, { children: _jsx(Field, { id: "employee", label: _jsx(Transform, { mkey: TranslationConstant.SELECT_EMPLOYEE }), name: "employee", isRequired: true, component: FormDropDownListWithValue, defaultItem: {
                                                employeeName: "Select...",
                                                employeeID: 0,
                                            }, data: employeeList, textField: "employeeName", valueField: "employeeID", validator: requiredValidator }) })), !(clientData === null || clientData === void 0 ? void 0 : clientData.multiStateTimesheets) && (_jsx("div", __assign({ className: "col-12 col-sm-6 col-md-4  mb-3" }, { children: _jsx(Field, { id: "state", name: "state", isRequired: true, label: _jsx(Transform, { mkey: TranslationConstant.LABEL_STATE }), component: FormDropDownListWithValue, defaultItem: {
                                                longName: "Select",
                                                stateId: 0,
                                            }, textField: "longName", valueField: "stateID", data: states, placeholder: ComponentConstant.PLACEHOLDER_STATE, validator: requiredValidator }) })))] })), _jsx("div", __assign({ className: "k-form-buttons mt-4 pb-0 pe-0" }, { children: _jsx(FormButtons, { wrapperClass: "pe-0 pb-1", hideResetButton: true, customSubmitText: TranslationConstant.CREATE, closeText: isModalContent && (_jsx(Transform, { mkey: TranslationConstant.CLOSE_BUTTON })), showCancelButton: true, disabled: userAccessType === AppConstant.READ_ONLY_USER_ACCESS_VALUE, cancelHandler: handleClose }) }))] })) }))); } }), modalState && (_jsx(DialogBox, __assign({}, modalContent, { cancelText: _jsx(Transform, { mkey: TranslationConstant.CLOSE_BUTTON }), isDialogOpen: modalState, onModalChangeState: function () { return changeModalState(false); } })))] }));
};
export default AddTimesheetDialogContent;
