var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { process as kendrprocess } from "@progress/kendo-data-query";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { GridColumn as Column, Grid } from "@progress/kendo-react-grid";
import { URL_TYPE_CODES } from "common/constant/Enums";
import MobileGridCard from "components/shared/MobileScreens/MobileGridCard";
import useWindowDimension from "hooks/useWindowDimension";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { DownloadDocuments } from "./DownloadDocuments";
import { EmployeeDashboardDialogBody } from "./EmployeeDashboardDialogBody";
import { AppConstant, ComponentConstant, DialogBox, FormHeading, GridFilters, GridRefresher, TabsComponent, Transform, TranslationConstant, WEBUSERROLEID, createEncodedURL, useCurrentViewContext, useDashboardContext, withValueField, } from "./index";
var initialDataState = {
    sort: [{ field: "no-value", dir: "asc" }],
    skip: 0,
    take: AppConstant.GRID_ITEM_TAKE_SIZE,
};
export var EmployeeTaskGrid = function (props) {
    var DropDownListWithValueField = withValueField(DropDownList);
    var windowDimension = useWindowDimension();
    var _a = useDashboardContext(), employeeTaskGridResponse = _a.employeeTaskGridResponse, fetchEmployeeTasks = _a.fetchEmployeeTasks, declineNewHireDocument = _a.declineNewHireDocument, paymentTypeList = _a.paymentTypeList, fetchPaymentTypeByJobId = _a.fetchPaymentTypeByJobId, updatePaymentTypeForDocuments = _a.updatePaymentTypeForDocuments, checkPaymentTypeForDocuments = _a.checkPaymentTypeForDocuments, employeeDocumentsCompleted = _a.employeeDocumentsCompleted, getShowEmployeeCompleteDocument = _a.getShowEmployeeCompleteDocument, enablePaymentDropdown = _a.enablePaymentDropdown, getPaymentDropDownStatus = _a.getPaymentDropDownStatus;
    var userAccessType = useCurrentViewContext().userAccessType;
    var localStorageKey = "".concat(AppConstant.LOCAL_STORAGE_EMPLOYEE_DATA, ":").concat(process.env.AUTHORITY_URL, ":").concat(process.env.CLIENT_ID);
    var employeeProfile = JSON.parse(atob(localStorage.getItem(localStorageKey)));
    var localStorageKey_user = "".concat(AppConstant.LOCAL_STORAGE_USER_PROFILE, ":").concat(process.env.AUTHORITY_URL, ":").concat(process.env.CLIENT_ID);
    var userDetails = (localStorage.getItem(localStorageKey_user) &&
        JSON.parse(atob(localStorage.getItem(localStorageKey_user)))) ||
        null;
    var WebUserRoleId = (userDetails || {}).WebUserRoleId;
    var oidcStorage = JSON.parse(localStorage.getItem("oidc.user:".concat(process.env.AUTHORITY_URL, ":").concat(process.env.CLIENT_ID)));
    var applicationId = oidcStorage && parseInt(oidcStorage.profile.ApplicationId);
    var _b = React.useState(initialDataState), dataState = _b[0], setDataState = _b[1];
    var _c = React.useState(initialDataState), optionalDataState = _c[0], setOptionalDataState = _c[1];
    var _d = React.useState(), showFilter = _d[0], setShowHideFilter = _d[1];
    var _e = React.useState([]), filter = _e[0], setFilter = _e[1];
    var _f = React.useState(), showOptionalFilter = _f[0], setShowHideOptionalFilter = _f[1];
    var _g = React.useState([]), optionalFilter = _g[0], setOptionalFilter = _g[1];
    var _h = React.useState(false), modalState = _h[0], changeModalState = _h[1];
    var _j = React.useState(true), popUpState = _j[0], changePopUpState = _j[1];
    var _k = React.useState(null), paymentOption = _k[0], updatePaymentOption = _k[1];
    var _l = useState(false), showPaymentDropdown = _l[0], setPaymentDropdownValue = _l[1];
    var _m = useState(false), showCompletedPopup = _m[0], updateShowCompltedPopup = _m[1];
    var _o = React.useState({
        headingText: "",
        bodyText: "",
        isErrorDialog: false,
    }), modalContent = _o[0], updateModalContent = _o[1];
    var _p = React.useState([]), requiredFormData = _p[0], setRequiredFormData = _p[1];
    var _q = React.useState([]), optionalFormData = _q[0], setOptionalFormData = _q[1];
    useEffect(function () {
        fetchEmpTasks();
    }, []);
    useEffect(function () {
        updateShowCompltedPopup(employeeDocumentsCompleted);
    }, [employeeDocumentsCompleted]);
    useEffect(function () {
        if (employeeTaskGridResponse) {
            var requiredData = employeeTaskGridResponse.filter(function (item) {
                return item.required === true;
            });
            setRequiredFormData(requiredData);
            setDataState(__assign(__assign({}, dataState), { take: requiredData.length }));
            var optionalData = employeeTaskGridResponse.filter(function (item) {
                return item.required === false;
            });
            setOptionalFormData(optionalData);
            setOptionalDataState(__assign(__assign({}, dataState), { take: optionalData.length }));
        }
    }, [employeeTaskGridResponse]);
    var fetchEmpTasks = function () { return __awaiter(void 0, void 0, void 0, function () {
        var err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    getShowEmployeeCompleteDocument(employeeProfile.newHireJobID);
                    fetchEmployeeTasks(employeeProfile.newHireJobID);
                    fetchPaymentTypeByJobId(employeeProfile.newHireJobID);
                    fetchEmpPaymentType();
                    return [4 /*yield*/, getPaymentDropDownStatus(employeeProfile.newHireJobID)];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    err_1 = _a.sent();
                    console.log("error in fetchEmpTasks", err_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var fetchEmpPaymentType = function () { return __awaiter(void 0, void 0, void 0, function () {
        var paymentResponse, err_2;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, checkPaymentTypeForDocuments(employeeProfile.newHireJobID)];
                case 1:
                    paymentResponse = _b.sent();
                    if ((_a = AppConstant.HTTP_SUCCESS_CODES) === null || _a === void 0 ? void 0 : _a.includes(paymentResponse.status)) {
                        if (paymentResponse.data.paymentTypeID !== null) {
                            setPaymentDropdownValue(true);
                            updatePaymentOption(paymentResponse.data.paymentTypeID);
                        }
                        else {
                            setPaymentDropdownValue(false);
                        }
                    }
                    return [3 /*break*/, 3];
                case 2:
                    err_2 = _b.sent();
                    console.log(err_2);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var openDownloadModal = function () {
        changeModalState(true);
        onDownloadModal();
    };
    var onDownloadModal = function () {
        updateModalContent({
            headingText: _jsx(Transform, { mkey: TranslationConstant.DOWNLOAD_DOCUMENTS_TITLE }),
            bodyText: (_jsx(DownloadDocuments, { hideModal: hideModal, newHireJobID: employeeProfile.newHireJobID, applicationId: applicationId, employeeProfile: employeeProfile })),
            isErrorDialog: false,
            isFixedWidth: false,
            hidedialogBtns: true,
        });
    };
    var newEmployeePopUp = function (allowEdit, docName) {
        if (allowEdit === "True" &&
            docName === "Employee Information" &&
            popUpState &&
            localStorage.getItem("isEmpInfoFormSubmit") !== "true" &&
            localStorage.getItem("isnewEmployeePopUpDismissed") !== "true") {
            updateModalContent({
                headingText: _jsx(Transform, { mkey: TranslationConstant.EMPLOYEE_DASHBOARD_HEADING }),
                bodyText: (_jsx("div", __assign({ className: "block subheading" }, { children: _jsx(EmployeeDashboardDialogBody, {}) }))),
                cancelText: _jsx(Transform, { mkey: TranslationConstant.CLOSE_BUTTON }),
                isFixedWidth: true,
            });
            localStorage.setItem("isnewEmployeePopUpDismissed", "true");
            changePopUpState(false);
            changeModalState(true);
        }
        return null;
    };
    var DocumentNameFormCell = function (cellprops) {
        return (_jsx(_Fragment, { children: _jsxs("td", __assign({ colSpan: cellprops.colSpan, role: "gridcell", "aria-colindex": cellprops.ariaColumnIndex, "aria-selected": cellprops.isSelected }, { children: [cellprops.dataItem.name, cellprops.dataItem.showI9Verifier == "I9Verifier" ? (_jsx(Link, __assign({ to: "/changei9verifier", className: "change-verifier-link" }, { children: _jsx(Transform, { mkey: TranslationConstant.CHANGE_I9_VERIFIER }) }))) : ("")] })) }));
    };
    var CompleteFormOptionalCell = function (cellprops) {
        return (_jsx(_Fragment, { children: _jsx("td", __assign({ colSpan: cellprops.colSpan, role: "gridcell", "aria-colindex": cellprops.ariaColumnIndex, "aria-selected": cellprops.isSelected, className: "url-link-cell" }, { children: createLink(cellprops === null || cellprops === void 0 ? void 0 : cellprops.dataItem) })) }));
    };
    var confirmDeclineForm = function (cellprops) {
        updateModalContent({
            headingText: _jsx(Transform, { mkey: TranslationConstant.LOGOUT_CONFIRM_HEADING }),
            bodyText: (_jsxs("div", __assign({ className: "block subheading" }, { children: [_jsx("i", { className: "fa fa-info-circle fa-lg" }), _jsxs("span", { children: [_jsx(Transform, { mkey: TranslationConstant.CONFIRM_DECLINING_FORM }), " ", cellprops.name] })] }))),
            acceptText: _jsx(Transform, { mkey: TranslationConstant.YES }),
            acceptHandler: function () { return declineNewHireDocumentPatch(cellprops.newHireDocumentID); },
            cancelText: _jsx(Transform, { mkey: TranslationConstant.NO }),
            isErrorDialog: false,
        });
        changeModalState(true);
    };
    var declineNewHireDocumentPatch = function (newHireDocumentID) { return __awaiter(void 0, void 0, void 0, function () {
        var response, e_1;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, declineNewHireDocument(newHireDocumentID)];
                case 1:
                    response = _b.sent();
                    if ((_a = AppConstant.HTTP_SUCCESS_CODES) === null || _a === void 0 ? void 0 : _a.includes(response.status)) {
                        onSaveSuccess();
                        fetchEmpTasks();
                    }
                    else {
                        onSaveFailure();
                    }
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _b.sent();
                    changeModalState(false);
                    console.log(e_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var onSaveSuccess = function () {
        updateModalContent({
            headingText: _jsx(Transform, { mkey: TranslationConstant.DECLINED_SUCCESS }),
            bodyText: (_jsxs("div", __assign({ className: "block subheading" }, { children: [_jsx("i", { className: "fa fa-check fa-lg" }), _jsx("span", { children: _jsx(Transform, { mkey: TranslationConstant.DECLINED_SUCCESS }) })] }))),
            cancelText: _jsx(Transform, { mkey: TranslationConstant.CLOSE_BUTTON }),
            isErrorDialog: false,
        });
        changeModalState(true);
    };
    var onSaveFailure = function () {
        updateModalContent({
            headingText: _jsx(Transform, { mkey: TranslationConstant.DECLINE_FAILED }),
            bodyText: (_jsxs("div", __assign({ className: "block subheading" }, { children: [_jsx("i", { className: "fa fa-times-circle fa-lg" }), _jsx("span", { children: _jsx(Transform, { mkey: TranslationConstant.DECLINE_FAILED }) })] }))),
            cancelText: _jsx(Transform, { mkey: TranslationConstant.CLOSE_BUTTON }),
            isErrorDialog: true,
        });
        changeModalState(true);
    };
    var createLink = function (dataItem) {
        if (dataItem.viewEditText === ComponentConstant.VIEW_THIS_FORM) {
            return (_jsxs("tr", { children: [_jsx("td", { children: _jsxs(Link, __assign({ to: createEncodedURL("/documentview", {
                                fileUrl: dataItem["url"],
                                newHireDocumentID: dataItem["newHireDocumentID"],
                                isAddOnboardingFilePrefix: true,
                                backButtonPath: "/employee",
                            }) }, { children: [_jsx("i", { className: "fa fa-eye fa-lg" }), dataItem.viewEditText] })) }), _jsx("td", {})] }));
        }
        else if (dataItem.viewEditText === ComponentConstant.COMPLETE_THIS_FORM) {
            return (_jsxs("tr", { children: [dataItem.newHireDocumentFormTypeCode === URL_TYPE_CODES.LEGACY ? (_jsx("td", __assign({ onClick: function () { return dialogForRefeshMessage(dataItem); } }, { children: _jsxs(Link, __assign({ to: "".concat(dataItem.url), className: userAccessType === AppConstant.READ_ONLY_USER_ACCESS_VALUE
                                ? "access-disabled"
                                : "", target: "_blank" }, { children: [_jsx("i", { className: "fa fa-edit fa-lg" }), dataItem.viewEditText] })) }))) : (_jsx("td", { children: _jsxs(Link, __assign({ to: "/".concat(dataItem.url), className: userAccessType === AppConstant.READ_ONLY_USER_ACCESS_VALUE
                                ? "access-disabled"
                                : "" }, { children: [_jsx("i", { className: "fa fa-edit fa-lg" }), dataItem.viewEditText] })) })), _jsx("td", { children: dataItem.newHireDocumentTypeID.toString() !== "127" && (_jsxs(Link, __assign({ to: "#", className: "decline-form-link ".concat(userAccessType === AppConstant.READ_ONLY_USER_ACCESS_VALUE
                                ? "access-disabled"
                                : ""), onClick: function (e) {
                                e.preventDefault();
                                confirmDeclineForm(dataItem);
                            } }, { children: [_jsx("i", { className: "fa fa-minus-circle fa-lg" }), ComponentConstant.DECLINE_THIS_FORM] }))) })] }));
        }
        else if (dataItem.viewEditText === ComponentConstant.FORM_DECLINED) {
            return (_jsxs("tr", { children: [_jsx("td", { className: "blank-td" }), _jsxs("td", { children: [_jsx("i", { className: "fa fa-ban fa-lg" }), ComponentConstant.FORM_DECLINED] })] }));
        }
    };
    var completeFormCustomCell = function (cellprops) {
        return (_jsx(_Fragment, { children: _jsx("td", __assign({ colSpan: cellprops.colSpan, role: "gridcell", "aria-colindex": cellprops.ariaColumnIndex, "aria-selected": cellprops.isSelected }, { children: CompleteFormCell(cellprops.dataItem) })) }));
    };
    var CompleteFormCell = function (dataItem) {
        return (_jsxs(_Fragment, { children: [dataItem.viewEditText === ComponentConstant.COMPLETE_THIS_FORM
                    ? newEmployeePopUp(dataItem.allowEdit, dataItem.name)
                    : null, dataItem.viewEditText === ComponentConstant.VIEW_THIS_FORM ? (_jsxs(Link, __assign({ to: createEncodedURL("/documentview", {
                        fileUrl: dataItem["url"],
                        newHireDocumentID: dataItem["newHireDocumentID"],
                        backButtonPath: "/employee",
                        newHireDocumentTypeID: dataItem["newHireDocumentTypeID"],
                    }) }, { children: [_jsx("i", { className: "fa fa-eye fa-lg" }), dataItem.viewEditText] }))) : (dataItem.viewEditText === ComponentConstant.COMPLETE_THIS_FORM && (_jsx(_Fragment, { children: dataItem.newHireDocumentFormTypeCode === URL_TYPE_CODES.LEGACY ? (_jsx("span", __assign({ onClick: function () { return dialogForRefeshMessage(dataItem); } }, { children: _jsxs(Link, __assign({ to: "".concat(dataItem.url), className: userAccessType ===
                                AppConstant.READ_ONLY_USER_ACCESS_VALUE
                                ? "access-disabled"
                                : "", target: "_blank" }, { children: [_jsx("i", { className: "fa fa-edit fa-lg" }), dataItem.viewEditText] })) }))) : (_jsxs(Link, __assign({ to: "/".concat(dataItem.url), className: userAccessType === AppConstant.READ_ONLY_USER_ACCESS_VALUE
                            ? "access-disabled"
                            : "" }, { children: [_jsx("i", { className: "fa fa-edit fa-lg" }), dataItem.viewEditText] }))) })))] }));
    };
    var documentNameFormCustomCell = function (props) {
        return _jsx(DocumentNameFormCell, __assign({}, props));
    };
    var completeFormOptionalCustomCell = function (props) {
        return _jsx(CompleteFormOptionalCell, __assign({}, props));
    };
    var dialogForRefeshMessage = function (cellObj) {
        updateModalContent({
            headingText: _jsx(Transform, { mkey: TranslationConstant.REFRESH_HEADING }),
            bodyText: (_jsxs("div", __assign({ className: "block subheading" }, { children: [_jsx("i", { className: "fa fa-info-circle fa-lg" }), _jsxs("span", { children: ["Please fill the ", _jsx("b", { children: cellObj.name }), " form prior to hit the refresh"] })] }))),
            acceptText: _jsx(Transform, { mkey: TranslationConstant.REFRESH_GRID_BTN_TEXT }),
            acceptHandler: gridRefresher,
            isErrorDialog: false,
        });
        if (cellObj.viewEditText === ComponentConstant.COMPLETE_THIS_FORM &&
            userAccessType !== AppConstant.READ_ONLY_USER_ACCESS_VALUE) {
            changeModalState(true);
        }
    };
    var gridRefresher = function () {
        fetchEmpTasks();
        fetchEmpPaymentType();
        changeModalState(false);
    };
    var hideModal = function () {
        changeModalState(false);
    };
    var onPaymentChange = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var response, err_3;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    updatePaymentOption(e.value);
                    if (!(e.value !== null)) return [3 /*break*/, 4];
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, updatePaymentTypeForDocuments(employeeProfile.newHireJobID, e.value)];
                case 2:
                    response = _b.sent();
                    if (((_a = AppConstant.HTTP_SUCCESS_CODES) === null || _a === void 0 ? void 0 : _a.includes(response.status)) &&
                        response.data === true) {
                        fetchEmpTasks();
                    }
                    return [3 /*break*/, 4];
                case 3:
                    err_3 = _b.sent();
                    console.log("error in onPaymentChange", err_3);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var formHeadingData = {
        title: _jsx(Transform, { mkey: TranslationConstant.EMPLOYEE_BOARD_HEADING }),
        tooltip: _jsx(Transform, { mkey: TranslationConstant.DOWNLOAD_DOCUMENTS }),
    };
    var getMobileGridColumns = function (type) {
        var actionCell = type === "required" ? CompleteFormCell : createLink;
        return [
            { field: "name", title: "Document", sortable: true, heading: "h1" },
            { field: "", title: "", type: "link", actionCell: actionCell },
            { field: "effectiveDate", title: "Effective Date", type: "date", sortable: true },
        ];
    };
    var requiredFormGridTabs = [
        {
            title: _jsx(Transform, { mkey: TranslationConstant.REQUIRED_FORMS_HEADING }),
            children: (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "r-filter-wrapper" }, { children: [showPaymentDropdown && (_jsx(DropDownListWithValueField, { id: "payment", name: "payment", label: _jsx(Transform, { mkey: TranslationConstant.LABEL_PAYMENT }), data: paymentTypeList, disabled: paymentTypeList.length === 1 ||
                                    WebUserRoleId === WEBUSERROLEID.Employee ||
                                    !enablePaymentDropdown, value: paymentOption, onChange: onPaymentChange, textField: "paymentType", valueField: "paymentTypeID" })), windowDimension.width > AppConstant.MOBILE_SCREEN_WIDTH && (_jsx(GridFilters, { filter: filter, showFilter: showFilter, setShowHideFilter: setShowHideFilter }))] })), windowDimension.width <= AppConstant.MOBILE_SCREEN_WIDTH ? (_jsx(MobileGridCard, { data: requiredFormData, gridColumnData: getMobileGridColumns("required"), actionItems: [], isTabView: true })) : (_jsxs("div", __assign({ className: "general-grid grid-without-paging" }, { children: [_jsxs(Grid, __assign({ sortable: true, filterable: showFilter, pageable: true, data: kendrprocess(requiredFormData, dataState) }, dataState, { onDataStateChange: function (e) {
                                    setDataState(e.dataState);
                                    setFilter(e.dataState.filter && e.dataState.filter.filters);
                                } }, { children: [_jsx(Column, { field: "name", title: "Document", width: "150px", cell: documentNameFormCustomCell }), _jsx(Column, { field: "effectiveDate", title: "Effective Date", width: "120px" }), _jsx(Column, { field: "", title: "", width: "170px", filterable: false, cell: completeFormCustomCell })] })), _jsx(GridRefresher, { refreshGridDataHandler: gridRefresher })] })))] })),
        },
    ];
    var optionalFormGridTabs = [
        {
            title: _jsx(Transform, { mkey: TranslationConstant.OPTIONAL_FORMS_HEADING }),
            children: (_jsx(_Fragment, { children: windowDimension.width <= AppConstant.MOBILE_SCREEN_WIDTH ? (_jsx(MobileGridCard, { data: optionalFormData, gridColumnData: getMobileGridColumns("optional"), actionItems: [], isTabView: true })) : (_jsxs(_Fragment, { children: [_jsx(GridFilters, { filter: optionalFilter, showFilter: showOptionalFilter, setShowHideFilter: setShowHideOptionalFilter }), _jsxs("div", __assign({ className: "general-grid grid-without-paging" }, { children: [_jsxs(Grid, __assign({ sortable: true, filterable: showOptionalFilter, pageable: true, style: {
                                        height: optionalFormData.length > 10 ? "400px" : "auto",
                                    }, data: kendrprocess(optionalFormData, optionalDataState) }, optionalDataState, { onDataStateChange: function (e) {
                                        setOptionalDataState(e.dataState);
                                        setOptionalFilter(e.dataState.filter && e.dataState.filter.filters);
                                    } }, { children: [_jsx(Column, { field: "name", title: "Document", width: "150px" }), _jsx(Column, { field: "effectiveDate", title: "Effective Date", width: "120px" }), _jsx(Column, { field: "", title: "", width: "250px", filterable: false, cell: completeFormOptionalCustomCell })] })), _jsx(GridRefresher, { gridIndex: 1, refreshGridDataHandler: gridRefresher })] }))] })) })),
        },
    ];
    var completedFormsBox = function () {
        return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: "completeBtnDiv" }, { children: [_jsx("div", __assign({ className: "close-icon", onClick: function () {
                            updateShowCompltedPopup(false);
                        } }, { children: _jsx("i", { className: "fa fa-times-circle fa-lg" }) })), _jsxs("div", __assign({ className: "col-md-12 panel-stat" }, { children: [_jsxs("p", { children: [_jsx("i", { className: "fa fa-exclamation-circle fa-2x" }), "You've completed the required forms. The next time you report to your supervisor be sure to bring all required documents (e.g. Driver's License, passport, etc. ) to complete the hiring process."] }), _jsx("p", { children: "The next step is to review the optional forms and complete if you would like your check to be direct deposited and/or your W2 to be electronically delivered." }), _jsx("p", { children: "Best regards!" }), _jsx("p", { children: "Your PayReel Team" }), _jsx("div", __assign({ className: "seperator" }, { children: _jsx("strong", { children: _jsx(Link, __assign({ to: createEncodedURL("/documentview", {
                                            isEmpPrintDocument: true,
                                            newHireJobID: employeeProfile.newHireJobID,
                                        }) }, { children: "Click here to view/print documents" })) }) }))] }))] })) }));
    };
    return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "admin-panel-wrapper admin-agent-task employee-task-grid" }, { children: [showCompletedPopup && completedFormsBox(), _jsx(FormHeading, { data: formHeadingData, openModal: openDownloadModal, userRoleId: WebUserRoleId }), _jsx(TabsComponent, { tabsMenu: requiredFormGridTabs, selectedIndex: 0 }), _jsx(TabsComponent, { tabsMenu: optionalFormGridTabs, selectedIndex: 0 })] })), modalState && (_jsx(DialogBox, { headingText: modalContent.headingText, bodyText: modalContent.bodyText, acceptText: modalContent.acceptText, acceptHandler: modalContent.acceptHandler, cancelText: modalContent.cancelText, isErrorDialog: modalContent.isErrorDialog, isDialogOpen: modalState, onModalChangeState: hideModal, fixedWidth: modalContent.isFixedWidth, hideDialogBtns: modalContent.hidedialogBtns }))] }));
};
