var _a, _b;
import AppConstant from "./AppConstant";
import { LOCALE } from "./Enums";
import TranslationConstant from "./TranslationConstant";
export var LocalizedMessages = (_a = {},
    _a["".concat(LOCALE.ENGLISH_LANGUAGE)] = (_b = {},
        _b[TranslationConstant.TIME_CLOCK_MANAGEMENT] = "Time Clock Management",
        _b[TranslationConstant.ADD_MISSING_TIME] = "Add Missing Time",
        _b[TranslationConstant.EDIT_MISSING_TIME] = "Edit Missing Time",
        _b[TranslationConstant.SOMETHING_WENT_WRONG] = "Sorry, Something went wrong",
        _b[TranslationConstant.TRY_AGAIN] = "Try again",
        _b[TranslationConstant.FOOTER] = "Footer",
        _b[TranslationConstant.YES] = "Yes",
        _b[TranslationConstant.NO] = "No",
        _b[TranslationConstant.MINUTES_UNTIL_AUTO_LOGOUT] = "minutes until auto",
        _b[TranslationConstant.DASHBOARD] = "Dashboard",
        _b[TranslationConstant.WELCOME] = "Welcome",
        _b[TranslationConstant.GO_TO_TEXT] = "Go To",
        _b[TranslationConstant.GO_TO_IC_ADVISOR] = "IC Advisor",
        _b[TranslationConstant.GO_TO_PAYREEL_ONLINE] = "Payroll",
        _b[TranslationConstant.GO_TO_ONBOARDING] = "Onboarding",
        _b[TranslationConstant.MINUTES_UNTILL_AUTO] = "minutes until auto",
        _b[TranslationConstant.LOGOUT] = "Logout",
        _b[TranslationConstant.LABEL_FIRST_NAME] = "First Name",
        _b[TranslationConstant.LABEL_MIDDLE_NAME] = "Middle Name",
        _b[TranslationConstant.LABEL_LAST_NAME] = "Last Name",
        _b[TranslationConstant.LABEL_PHONE_NUMBER] = "Phone Number",
        _b[TranslationConstant.LABEL_CELL_PHONE] = "Cell Phone",
        _b[TranslationConstant.LABEL_EMPLOYEE_EMAIL] = "Employee's Email",
        _b[TranslationConstant.LABEL_EMAIL] = "Email",
        _b[TranslationConstant.LABEL_START_DATE] = "Start Date",
        _b[TranslationConstant.LABEL_END_DATE] = "End Date",
        _b[TranslationConstant.LABEL_CLIENT] = "Client",
        _b[TranslationConstant.LABEL_SEARCH] = "Search",
        _b[TranslationConstant.LABEL_SAVE_BUTTON] = "Save",
        _b[TranslationConstant.LABEL_JOB_STATUS] = "Job Status",
        _b[TranslationConstant.LABEL_DEFAULT_OPTION_SELECT] = "Select...",
        _b[TranslationConstant.LABEL_DEFAULT_OPTION_CLIENT] = "Select Client",
        _b[TranslationConstant.LABEL_DEFAULT_OPTION_ACCOUNT] = "Select Account",
        _b[TranslationConstant.LABEL_DEFAULT_OPTION_WORK_STATE] = "Select Work State",
        _b[TranslationConstant.LABEL_DEFAULT_OPTION_SUPERVISOR] = "Select Supervisor",
        _b[TranslationConstant.LABEL_DEFAULT_OPTION_JOB_STATUS] = "Select Status",
        _b[TranslationConstant.LABEL_ACCOUNT] = "Account",
        _b[TranslationConstant.LABEL_WORK_STATE] = "Work State",
        _b[TranslationConstant.LABEL_SUBMIT] = "Submit",
        _b[TranslationConstant.LABEL_DEFAULT_OPTION_AGENT] = "Select...",
        _b[TranslationConstant.SEARCH_NEWHIRE_DOCUMENT_HEADING] = "Search NewHire Documents",
        _b[TranslationConstant.SEARCH_NEWHIRE_DOCUMENT_TITLE] = "Please enter your search criteria, or just click Search for all results",
        _b[TranslationConstant.SEARCH_NEW_HIRE_JOBS] = "Search New Hire Jobs",
        _b[TranslationConstant.VIEW_JOB_DOC_FOR_APPROVAL_TITLE] = "View Job Documents For Approval",
        _b[TranslationConstant.BACK_BUTTON] = "Back",
        _b[TranslationConstant.EMPLOYEE_TITLE] = "Employee",
        _b[TranslationConstant.CLIENT_TITLE] = "Client",
        _b[TranslationConstant.NEWHIRE_FORM_HEADING] = "Please enter your search criteria, or just click Search for all results",
        _b[TranslationConstant.LABEL_EFFECTVE_DATE] = "Effective Start Date",
        _b[TranslationConstant.LABEL_EFFECTVE_END_DATE] = "Effective End Date",
        _b[TranslationConstant.LABEL_DOCUMENT_TYPE_TO_UPLOAD] = "Select a document type to upload:",
        _b[TranslationConstant.NEW_CLIENT_BY_EMPLOYEE_INPUT] = "New Client by Employee input",
        _b[TranslationConstant.RECORDS_WITH_EXISTING_CLIENTS] = "Records with existing clients",
        _b[TranslationConstant.CREATED_JOBS_PAST_START_DATE] = "Created Jobs Past Start Date",
        _b[TranslationConstant.CREATED_JOBS_THAT_NEED_REVIEW] = "Created Jobs That Need Review",
        _b[TranslationConstant.INCOMPLETE_JOBS_PAST_START_DATE] = "Required Forms Incomplete Jobs Past Start Date",
        _b[TranslationConstant.INPROGRESS_JOBS_PAST_START_DATE] = "In Progress Jobs Past Start Date",
        _b[TranslationConstant.INPROGRESS_JOBS_THAT_NEED_REVIEW] = "In Progress Jobs That Need Review",
        _b[TranslationConstant.JOBS_AWAITING_APPROVAL] = "Jobs Awaiting Approval",
        _b[TranslationConstant.COUNT_PAYREEL_JOBS_APPROVAL] = "Count of PayReel jobs to approve",
        _b[TranslationConstant.EXPIRED_VISA] = "Expired Visa",
        _b[TranslationConstant.EXPIRED_VISA_COUNT] = "Count of Expired Visa",
        _b[TranslationConstant.PAYREEL_TASK] = "PayReel Tasks",
        _b[TranslationConstant.PAYREEL_TASK_COUNT] = "Count of Available PayReel Tasks",
        _b[TranslationConstant.AGENT_TASK] = "Agents Tasks",
        _b[TranslationConstant.AGENT_TASK_COUNT] = "Count of Available Agent Tasks",
        _b[TranslationConstant.LOCKED_USERS] = "Click here to check Locked users",
        _b[TranslationConstant.REPEAT_I9_LIST] = "I-9 Reverification",
        _b[TranslationConstant.REPEAT_I9_APPROVAL_LIST] = "I-9 Reverification Approvals List",
        _b[TranslationConstant.JOBS_FOR_REVERIFICATION] = "Count of jobs for Reverification",
        _b[TranslationConstant.REVERIFICATION_PENDING_APPROVAL] = "Pending for Reverification Approval",
        _b[TranslationConstant.DASHBOARD] = "DASHBOARD",
        _b[TranslationConstant.LOGOUT_CONFIRM_HEADING] = "Please Confirm",
        _b[TranslationConstant.LOGOUT_SURE_TEXT] = "Are you sure you want to Logout?",
        _b[TranslationConstant.GROUP_NAME] = "Group Name",
        _b[TranslationConstant.DESCRIPTION] = "Description",
        _b[TranslationConstant.ADD_NEW_ATTR_GRP] = "Add New Attribute Group",
        _b[TranslationConstant.ADD_NEW_ATTR] = "Add New Attribute",
        _b[TranslationConstant.ADD_ATTR_HEADING] = "Follow the steps to add/edit new attributes",
        _b[TranslationConstant.ATTR_NAME] = "Attribute Name",
        _b[TranslationConstant.EDIT] = "Edit",
        _b[TranslationConstant.DELETE] = "Delete",
        _b[TranslationConstant.PUBLISH] = "Publish",
        _b[TranslationConstant.PUBLISHED] = "Published",
        _b[TranslationConstant.VIEW] = "View",
        _b[TranslationConstant.ATTRIBUTE_GROUP] = "Attribute Group",
        _b[TranslationConstant.LABEL_DEFAULT_OPTION_GROUP] = "Select Attribute Group",
        _b[TranslationConstant.DATA_TYPE] = "Data Type",
        _b[TranslationConstant.SELECT_DATA_TYPE] = "Select Data Type",
        _b[TranslationConstant.ATTRIBUTE_TYPE] = "Attribute Type",
        _b[TranslationConstant.SELECT_ATTRIBUTE_TYPE] = "Select Attribute Type",
        _b[TranslationConstant.MANAGE_FEATURES] = "Manage Features",
        _b[TranslationConstant.MANAGE_ROLES] = "Manage Roles",
        _b[TranslationConstant.MANAGE_USERS] = "Manage Users",
        _b[TranslationConstant.SECURITY_ROLES_FEATURE] = "Security Roles's Feature",
        _b[TranslationConstant.LABEL_SAVE_MAPPING] = "Save Mapping",
        _b[TranslationConstant.LABEL_CLEAR_MAPPING] = "Clear Mapping",
        _b[TranslationConstant.APPLICATION_TYPE_TEXT] = "Application Type",
        _b[TranslationConstant.LABEL_RESET] = "Reset",
        _b[TranslationConstant.LABEL_CANCEL] = "Cancel",
        _b[TranslationConstant.IS_ACTIVE] = "Is Active",
        _b[TranslationConstant.SELECT_YES] = "Yes",
        _b[TranslationConstant.SELECT_NO] = "No",
        _b[TranslationConstant.VIEW_NEW_HIRE_JOB_DOC_TITLE] = "View Job Documents",
        _b[TranslationConstant.DOCUMENT_VIEW_TITLE] = "Document View",
        _b[TranslationConstant.BUTTON_APPROVE] = "Approve",
        _b[TranslationConstant.BUTTON_NOT_APPROVE] = "Not Approve",
        _b[TranslationConstant.LABEL_EXPIRY_DATE] = "Expiry Date",
        _b[TranslationConstant.CLOSE_BUTTON] = "Close",
        _b[TranslationConstant.FEATURE_ROLE_LABEL] = "Role",
        _b[TranslationConstant.EMPLOYEE_IDENTIFICATION_TITLE] = "Employee Identification #",
        _b[TranslationConstant.FEATURE_MAPPING_SUCCESS_HEADING] = "Successfully Saved",
        _b[TranslationConstant.FEATURE_MAPPING_SUCCESS_MESSAGE] = "Features Mapping Update successfully.",
        _b[TranslationConstant.FEATURE_MAPPING_FAILURE_HEADING] = "Save Failed",
        _b[TranslationConstant.FEATURE_MAPPING_FAILED_MESSAGE] = "Saving the Feature Mapping failed. Please try again.",
        _b[TranslationConstant.MANAGE_FEATURE_UPDATE_SUCCESS_MESSAGE] = "Feature saved successfully.",
        _b[TranslationConstant.MANAGE_FEATURE_UPDATE_FAILURE_MESSAGE] = "Saving the Feature failed. Please try again.",
        _b[TranslationConstant.ATTR_OPTIONS] = "Attribute Options",
        _b[TranslationConstant.ATTR_RADIOBUTTON] = "Radio Options",
        _b[TranslationConstant.APPLIED_FILTERS] = "Applied Filters",
        _b[TranslationConstant.SHOW_FILTERS_TEXT] = "Show Filters",
        _b[TranslationConstant.HIDE_FILTERS_TEXT] = "Hide Filters",
        _b[TranslationConstant.ARE_YOU_SURE_TEXT] = "Are you sure you want to Delete?",
        _b[TranslationConstant.ARE_YOU_SURE_TEXT_PUBLISH] = "Are you sure you want to Publish?",
        _b[TranslationConstant.CUSTOM_FORM_PUBLISHED] = "Custom Form Published Successfully",
        _b[TranslationConstant.PUBLISH_FALIURE] = "Error Publishing Custom Form",
        _b[TranslationConstant.ATTRIBUTE_GROUP_DELETED] = "Attribute Group has been Deleted.",
        _b[TranslationConstant.ATTRIBUTE_NOT_DELETED_MAPPING] = "Attribute can't  be deleted because it is used in mapping.",
        _b[TranslationConstant.ATTRIBUTE_GROUP_UPDATED_SUCCESS] = "Attribute Group has been updated successfully.",
        _b[TranslationConstant.ATTRIBUTE_ADDED_SUCCESS] = "Attribute Group has been added successfully.",
        _b[TranslationConstant.UPDATE_ATTRIBUTE_GROUP_FAIL] = "Attribute name already exists",
        _b[TranslationConstant.APPLIED_FILTERS] = "Applied Filters",
        _b[TranslationConstant.SHOW_FILTERS_TEXT] = "Show Filters",
        _b[TranslationConstant.HIDE_FILTERS_TEXT] = "Hide Filters",
        _b[TranslationConstant.SUCCESSFULLY_CANCEL_TEXT] = "Successfully Canceled",
        _b[TranslationConstant.SUCCESSFULLY_DELETE_TEXT] = "Successfully Deleted",
        _b[TranslationConstant.SUCCESSFULLY_PUBLISHED] = "Successfully Published",
        _b[TranslationConstant.DELETE_FAILURE_TEXT] = "Delete Failed",
        _b[TranslationConstant.EDIT_ATTRIBUTE_GROUP_TITLE] = "Edit Attribute Group",
        _b[TranslationConstant.UPDATED_SUCCESSFULLY] = "Updated Successfully",
        _b[TranslationConstant.UPDATE_FAILED] = "Updating Failed",
        _b[TranslationConstant.SAVED_SUCCESSFULLY] = "Successfully Saved",
        _b[TranslationConstant.SAVE_FAILED] = "Save Failed",
        _b[TranslationConstant.MANAGE_SYSTEM_FIELDS] = "Manage System Fields",
        _b[TranslationConstant.ACRO_FIELDS] = "Acro Fields",
        _b[TranslationConstant.SYSTEM_FIELDS] = "System Fields",
        _b[TranslationConstant.APPROVE_JOBS_TITLE] = "Approve Jobs",
        _b[TranslationConstant.APPROVE_JOB_BUTTON] = "Approve Job",
        _b[TranslationConstant.EDIT_NEW_ATTR] = "Edit Attribute",
        _b[TranslationConstant.ATTRIBUTE_SAVED_SUCCESS] = "Attribute has been saved Successfully",
        _b[TranslationConstant.ATTRIBUTE_SAVED_FAILED] = "Attribute name already exists.",
        _b[TranslationConstant.SYSTEM_FIELDS_SAVED_SUCCESS] = "Form Fields has been Successfully mapped",
        _b[TranslationConstant.SYSTEM_FIELDS_SAVED_FAILED] = "Error in mapping Form Fields. Please try again",
        _b[TranslationConstant.ATTRIBUTE_DELETED] = "Attribute has been Deleted.",
        _b[TranslationConstant.ATTRIBUTE_SYSTEM_CONFIGURED] = "Attribute can't be deleted because it is a system configured attribute.",
        _b[TranslationConstant.DELETE_ATTRIBUTE] = "Delete Attribute",
        _b[TranslationConstant.EXPIRED_VISA_TITLE] = "Expired VISA",
        _b[TranslationConstant.CLICK_TO_SEND_MAIL] = "Send",
        _b[TranslationConstant.UPLOAD_NEW_HR_DOCUMENT] = "Upload New HR Document",
        _b[TranslationConstant.STEPS_TO_UPLOAD_HR_DOCUMENT] = "Follow the steps to upload a new HR document.",
        _b[TranslationConstant.EMPLOYER_POSTER] = "Employer Posters",
        _b[TranslationConstant.HR_DOCUMENT_NAME] = "HR Document Name",
        _b[TranslationConstant.ISACTIVE] = "Is Active:",
        _b[TranslationConstant.LABEL_FOR_UPLOAD_DOCUMENT] = "Please upload your document",
        _b[TranslationConstant.VIEW_HR_DOCUMENTS] = "View HR Documents",
        _b[TranslationConstant.UPLOAD_HR_DOCUMENTS] = "Upload HR Document",
        _b[TranslationConstant.VIEW_FORM_TEMPLATES_TITLE] = "View Form Templates",
        _b[TranslationConstant.MANAGE_NEW_ATTR] = "Manage Attribute Mapping",
        _b[TranslationConstant.MANAGE_ATT_HEADING] = "Follow the steps to manage attributes mapping",
        _b[TranslationConstant.ATTRIBUTE_GROUP] = "Attribute Group",
        _b[TranslationConstant.ATTRIBUTES] = "Attributes",
        _b[TranslationConstant.ADD_NEW_MAPPING] = "Add New Mapping",
        _b[TranslationConstant.MANAGE_JOB_DOCUMENTS_HEADING] = "Manage Job Documents",
        _b[TranslationConstant.MANUALLY_ADD_JOB_HEADING] = "Select a document to manually add to the job.",
        _b[TranslationConstant.LABEL_SCREENING_COMPLETED_SUCCESSFULLY] = "Screening Completed Successfully?",
        _b[TranslationConstant.LABEL_DOCUMENT_SELECT] = "Select a document",
        _b[TranslationConstant.ADD_DOCUMENTS_BUTTON] = "Add Document",
        _b[TranslationConstant.DOCUMENT_NAME] = "Document Name",
        _b[TranslationConstant.FORM_VERSION] = "Document Version",
        _b[TranslationConstant.DOCUMENT_CATEGORY] = "Document Category",
        _b[TranslationConstant.DOCUMENT_WORK_FLOW] = "Document Work Flow",
        _b[TranslationConstant.DOCUMENT_TYPE_TEXT] = "Is Required?",
        _b[TranslationConstant.NEW_HIRE_DOCUMENT_TYPE_TEXT] = "New Hire Document Type",
        _b[TranslationConstant.FOLLOW_STEPLS_UPLOAD_NEW_FORM] = "Follow the steps to upload a new form",
        _b[TranslationConstant.MINOR_ONLY] = "Minor Only",
        _b[TranslationConstant.IS_ACTIVE] = "Is Active",
        _b[TranslationConstant.UPLOAD_NEW_FORM] = "Upload New Form",
        _b[TranslationConstant.UPLOAD_YOUR_DOCUMET] = "Please Upload Your Document",
        _b[TranslationConstant.LABEL_STATE] = "State",
        _b[TranslationConstant.EDIT_UPLOAD_FORM_HEADING] = "Edit Form",
        _b[TranslationConstant.UPLOAD_FORM_SUBMIT_SUCCESS] = "Form has been saved successfully",
        _b[TranslationConstant.UPLOAD_FORM_SUBMIT_FAILED] = "Submitting the form has been failed",
        _b[TranslationConstant.UPLOAD_FORM_PATCH_SAVE_SUCCESS] = "Form has been updated successfully",
        _b[TranslationConstant.UPLOAD_FORM_PATCH_SAVE_FAILED] = "Updating the form has been failed",
        _b[TranslationConstant.USERNAME_SET_INFORMATION] = "Username must be 8 characters long and must not contain spaces or match your password",
        _b[TranslationConstant.PASSWORD_SET_INFORMATION] = "Password must be 10 to 50 characters with no whitespace and must contain 1 uppercase, 1 lowercase, 1 number, and 1 special character from ~!#$%^&-_",
        _b[TranslationConstant.SEARCH_WEB_USERS_TITLE] = "Search Web Users",
        _b[TranslationConstant.LABEL_DEFAULT_OPTION_USER_STATUS] = "Select Status",
        _b[TranslationConstant.LABEL_DEFAULT_OPTION_USER_ROLE] = "Select Role",
        _b[TranslationConstant.LABEL_USER_ROLE] = "User Role",
        _b[TranslationConstant.LABEL_USER_STATUS] = "User Status",
        _b[TranslationConstant.PAYREEL_TASKS_HEADING] = "PayReel Tasks",
        _b[TranslationConstant.EMPLOYEE_BOARD_HEADING] = "Please complete the listed forms.",
        _b[TranslationConstant.FORMS_TO_COMPLETE_HEADING] = "Forms to Complete",
        _b[TranslationConstant.LABEL_SEARCH_CUSTOM_FORMS] = "Search Custom Forms",
        _b[TranslationConstant.LABEL_EFFECTIVE_FROM] = "Effective Start Date",
        _b[TranslationConstant.LABEL_EFFECTIVE_TO] = "Effective End Date",
        _b[TranslationConstant.LABEL_BOTH] = "Both",
        _b[TranslationConstant.IS_BULK_SIGN_IN_TEXT] = "Is Applicable for Bulk Sign and Submit ?",
        _b[TranslationConstant.VIEW_THIS_FORM] = "View This Form",
        _b[TranslationConstant.MANAGE_ATTRIBUTES_MAPPING_TEXT] = "Manage Attribute Mapping",
        _b[TranslationConstant.MANAGE_SYSTEM_FIELDS_TEXT] = "Manage System Fields",
        _b[TranslationConstant.DYNAMIC_CUSTOM_FORM] = "Dynamic Custom Form",
        _b[TranslationConstant.INCORRECT_UPLOADED_FILE] = "Please Upload the correct file",
        _b[TranslationConstant.IC_WORKER_STATUS_TITLE] = "IC Workers Status",
        _b[TranslationConstant.ASSIGN_TEMP_AGREEMENTS_TITLE] = "Assign Temp Agreements",
        _b[TranslationConstant.ASSIGN_TEMP_AGREEMENTS_HEADING] = "Please fill the form below to assign temp agreements to clients.",
        _b[TranslationConstant.AGENTS_TASKS_HEADING] = "Agent Tasks",
        _b[TranslationConstant.FORMS_TO_COMPLETE_HEADING] = "Forms to Complete",
        _b[TranslationConstant.IC_WORKER_STATUS_TITLE] = "IC Workers Status",
        _b[TranslationConstant.DOCUMENT_VIEW_HEADING] = "Document View",
        _b[TranslationConstant.WELCOME_TO_PAYREEL] = "Welcome to PayReel",
        _b[TranslationConstant.REQUIRED_FORMS_HEADING] = "Required Forms",
        _b[TranslationConstant.OPTIONAL_FORMS_HEADING] = "Optional Forms",
        _b[TranslationConstant.UPLOAD_FORM_DOCUMENT_TYPE_HEADING] = "Select a document type to manually upload to the job. (Document must be in PDF format)",
        _b[TranslationConstant.UPLOAD_DOCUMENT_BTN] = "Upload",
        _b[TranslationConstant.MANUALLY_UPLOAD_NEWHIRE_FORM_TITLE] = "Manually Upload NewHire Form",
        _b[TranslationConstant.SELECT_DOCUMENTS_TO_UPLOAD_TITLE] = "Select a document to upload",
        _b[TranslationConstant.RESEND_CREATE_USER_LINK] = "Resend Create User Link",
        _b[TranslationConstant.RESEND_EMAIL_I9_VERIFIER] = "Resend Email I9 Verifier",
        _b[TranslationConstant.DOCUMENT_ADDED_SUCCESSFULLY_HEADING] = "Document Added",
        _b[TranslationConstant.DOCUMENT_ADDED_SUCCESS_MESSAGE] = "Document has been added to the job",
        _b[TranslationConstant.DOCUMENT_ADDED_FAILURE_HEADING] = "Document Adding Failed",
        _b[TranslationConstant.DOCUMENT_ADDED_FAILURE_MESSAGE] = "Document adding failed to the job",
        _b[TranslationConstant.LABEL_WORK_VISA_DATE] = "Work Visa Date",
        _b[TranslationConstant.DOCUMENT_UPLOADED_SUCCESSFULLY] = "Document Uploaded Successfully",
        _b[TranslationConstant.DOCUMENT_UPLOADED_FAILURE] = "Uploading Failed",
        _b[TranslationConstant.DOCUMENT_UPLOADED_SUCCESS_MESSAGE] = "Document has been uploaded successfully",
        _b[TranslationConstant.DOCUMENT_UPLOADED_FAILURE_MESSAGE] = "Uploading document has been failed",
        _b[TranslationConstant.DELETED_SUCCESS_MESSAGE] = "Document has been deleted",
        _b[TranslationConstant.DELETED_FAILED_MESSAGE] = "Deleting document has been failed",
        _b[TranslationConstant.CANCEL_JOB_SURE_MESSAGE] = "Please confirm you want to cancel the job for",
        _b[TranslationConstant.JOB_DELETED_SUCCESS_MESSAGE] = "Job has been canceled",
        _b[TranslationConstant.JOB_DELETED_ERROR_MESSAGE] = "Canceling Job has been failed",
        _b[TranslationConstant.GUARDIAN_TYPE_PARENT] = "Parent",
        _b[TranslationConstant.GUARDIAN_TYPE_LEGAL_GUARDIAN] = "Legal Guardian",
        _b[TranslationConstant.RATE_TYPE_HOURLY] = "Hourly",
        _b[TranslationConstant.RATE_TYPE_HALF_DAY] = "Half Day (guarantee)",
        _b[TranslationConstant.RATE_TYPE_FULL_DAY] = "Daily (guarantee)",
        _b[TranslationConstant.RATE_TYPE_WEEKLY] = "Weekly",
        _b[TranslationConstant.AGENT_TYPE_CLIENT] = "Client",
        _b[TranslationConstant.AGENT_TYPE_PAYREEL] = "PayReel",
        _b[TranslationConstant.AGENT_TYPE_EXTERNAL] = "External",
        _b[TranslationConstant.AGENT_TYPE_EMPLOYEE_AUTHORIZED] = "Employee Authorized",
        _b[TranslationConstant.LABEL_EMPLOYEE_POSITION] = "Position",
        _b[TranslationConstant.LABEL_EMPLOYEE_RATE] = "Rate",
        _b[TranslationConstant.LABEL_EMPLOYEE_RATE_TYPE] = "Rate Type",
        _b[TranslationConstant.LABEL_SUPERVISOR_1] = "Supervisor 1",
        _b[TranslationConstant.LABEL_SUPERVISOR_2] = "Supervisor 2",
        _b[TranslationConstant.LABEL_PRIMARY_AGENT_TYPE] = "Primary I-9 Agent Type",
        _b[TranslationConstant.LABEL_PRIMARY_AGENT_LIST] = "Primary I-9 Agent List",
        _b[TranslationConstant.LABEL_BACKUP_AGENT_TYPE] = "Backup I-9 Agent Type",
        _b[TranslationConstant.LABEL_BACKUP_AGENT_LIST] = "Backup I-9 Agent List",
        _b[TranslationConstant.LABEL_CHECKBOX_EXEMPT] = "Check this box if Exempt",
        _b[TranslationConstant.LABEL_GUARDIAN_TYPE] = "Guardian Type",
        _b[TranslationConstant.LABEL_MANUALLY_UPLOAD_FORM] = "Check this box if PayReel will manually upload the forms",
        _b[TranslationConstant.LABEL_GUARDIAN_FIRST_NAME] = "Parent/Guardian First Name",
        _b[TranslationConstant.LABEL_GUARDIAN_LAST_NAME] = "Parent/Guardian Last Name",
        _b[TranslationConstant.LABEL_EMPLOYEE_MINOR] = "Check this box if the employee is a minor",
        _b[TranslationConstant.LABEL_CHECKBOX_FOR_IC_STATUS] = "Check this box if you want this worker checked for IC status",
        _b[TranslationConstant.LABEL_CHECKBOX_DIRECT_DEPOSIT_NOT_ALLOWED] = "Check this box if employee is talent and direct deposit is not allowed",
        _b[TranslationConstant.LABEL_SCREENING_REQUIRED] = "Check this box if screening is required",
        _b[TranslationConstant.LABEL_START_ONBAORDING_PROCESS] = "Start On Boarding Process?",
        _b[TranslationConstant.ADD_NEW_JOB_FORM_HEADING] = "Please fill the form below to add a new job.",
        _b[TranslationConstant.ADD_NEW_JOB_PAGE_TITLE] = "Add New Job",
        _b[TranslationConstant.LABEL_ADD_EMPLOYEE_JOB] = "Add Employee Job",
        _b[TranslationConstant.JOB_APPROVED_SUCCESSFULLY] = "Approved Succeess",
        _b[TranslationConstant.JOB_APPROVED_FAILED] = "Approve Failed",
        _b[TranslationConstant.JOB_APPROVED_SUCCESS_MESSAGE] = "Job has been Approved successfully",
        _b[TranslationConstant.JOB_APPROVE_FAILED_MESSAGE] = "Approving job has been failed",
        _b[TranslationConstant.ADD_ICWORKER_JOB_SUCCESS_MESSAGE] = "This job saved successfully",
        _b[TranslationConstant.ADD_ICWORKER_JOB_FAILURE_MESSAGE] = "Saving the job for IC failed. Please review and try again",
        _b[TranslationConstant.ATTRIBUTE_MAPPING_DELETED_SUCCESS] = "Form Attribute Mapping Deleted Successfully",
        _b[TranslationConstant.ATTRIBUTE_MAPPING_DELETED_FAILED] = "This Attribute is already mapped with PDF Form Acro field(s). Please remove the Acro field mapping before removing Form Attribute mapping.",
        _b[TranslationConstant.SEARCH_CUSTOM_FORM_HEADING] = "Please fill search criteria",
        _b[TranslationConstant.EDIT_JOB_PAGE_TITLE] = "Edit Job",
        _b[TranslationConstant.EDIT_JOB_FORM_HEADING] = "Please change the fields below to edit the job.",
        _b[TranslationConstant.LABEL_PAYREEL_COORDINATOR_LIST] = "PayReel Coordinator",
        _b[TranslationConstant.LABEL_TOTAL_COMPENSASATION] = "Total Compensation",
        _b[TranslationConstant.LABEL_WEEKLY_RATES] = "Weekly Rate",
        _b[TranslationConstant.ADD_NEW_JOB_CREATED_SUCCESS] = "This job saved successfully",
        _b[TranslationConstant.ADD_NEW_JOB_CREATED_FAILURE] = "Saving the form failed. Please review and try again",
        _b[TranslationConstant.SAVE_JOB] = "Save Job",
        _b[TranslationConstant.LABEL_DAILY_HOURS] = "Daily Hours",
        _b[TranslationConstant.NEW_HIRE_JOB_UPDATED] = "New Hire Job Updated",
        _b[TranslationConstant.RETURN_TO_DASHBOARD] = "Return to Dashboard",
        _b[TranslationConstant.SIGNATURE_PAD_FORM_TITLE] = "Signature pad",
        _b[TranslationConstant.SIGN_AND_SAVE_FORM_TITLE] = "Sign and Save Form",
        _b[TranslationConstant.REQUIRED_FIELD_MARKED_SYMBOL] = "* Required fields are marked with the proceeding symbol.",
        _b[TranslationConstant.SIGNATURE_PAD_ATTENTION_TEXT_DYNAMIC_FORM] = "Attention",
        _b[TranslationConstant.SIGNATURE_PAD_WARNING_TEXT_DYNAMIC_FORM] = " By clicking the button below, I agree that the digital signature produced is an electronic representation of my signature for all purposes associated with executing my employment with ".concat(AppConstant.PAYREEL_TEXT, " and will carry the same force as a pen-and-paper signature."),
        _b[TranslationConstant.SIGNATURE_PAD_ATTENTION_TEXT] = "Attention",
        _b[TranslationConstant.SIGNATURE_PAD_WARNING_TEXT] = "Signature pad Writing text",
        _b[TranslationConstant.SIGNATURE_PAD_GUARDIAN_NOTICE_HEADING] = "Guardian Notice",
        _b[TranslationConstant.SIGNATURE_PAD_GUARDIAN_NOTICE_TEXT] = " A parent/guardian signature is required for a minor completing this form.",
        _b[TranslationConstant.USE_FINGER_OR_MOUSE_TEXT] = "In the space below, use your mouse or finger to sign the document.",
        _b[TranslationConstant.DRAW_YOUR_SIGNATURE] = "Draw your signature",
        _b[TranslationConstant.CLEAR_TEXT] = "Clear Text",
        _b[TranslationConstant.APPROVE_JOBS_SURE_TEXT] = "Please confirm you want to approve the job for",
        _b[TranslationConstant.EMAIL_SEND_SUCCESS] = "Email has been sent. Please check your inbox. ",
        _b[TranslationConstant.EMAIL_SEND_FAILED] = "Email sent failed. Please try again",
        _b[TranslationConstant.EMAIL_SENT_TITLE] = "Email Sent",
        _b[TranslationConstant.EMAIL_FAILURE_TITLE] = "Email Not Sent",
        _b[TranslationConstant.ONBOARDING_REPORT_TITLE] = "Onboarding Report",
        _b[TranslationConstant.CREATE_REPORT_BTN] = "Create Report",
        _b[TranslationConstant.DOCUMENT_CANCEL_SUCCESS_MESSAGE] = "Document has been Canceled",
        _b[TranslationConstant.LABEL_PAYMENT] = "Payment",
        _b[TranslationConstant.TARGET_ATTR] = "Target Attribute",
        _b[TranslationConstant.EVENT_TYPE] = "Event Type",
        _b[TranslationConstant.OPERATOR_TYPE] = "Operator Type",
        _b[TranslationConstant.ACTION] = "Action Type",
        _b[TranslationConstant.STATIC] = "Static",
        _b[TranslationConstant.DYNAMIC] = "Dynamic",
        _b[TranslationConstant.VALUEOF] = "Value Of",
        _b[TranslationConstant.UI_VALIDATION] = "UI Validation",
        _b[TranslationConstant.UI_MANIPULATION] = "UI Manipulation",
        _b[TranslationConstant.SAVE_MANIPULATION] = "Manipulation has been saved successfully.",
        _b[TranslationConstant.MANAGE_VALIDATION] = "Manage Validation",
        _b[TranslationConstant.ADD_NEW_VALIDATION_FORM_HEADING] = "Please fill the form below to add validation for selected attribute.",
        _b[TranslationConstant.LABEL_DEFAULT_OPTION_DOCUMENT_STATUS] = "Select document status",
        _b[TranslationConstant.LABEL_DEFAULT_OPTION_DOCUMENT_TYPE] = "Select document type",
        _b[TranslationConstant.LABEL_DOCUMENT_STATUS] = "Document Status",
        _b[TranslationConstant.LABEL_DOCUMENT_TYPE] = "Document Type",
        _b[TranslationConstant.I9_REVERIFICATION_HEADING] = "I-9 Reverification",
        _b[TranslationConstant.I9_NEW_CLIENT_HEADING] = "New Client by Employee input",
        _b[TranslationConstant.I9_EXISTING_CLIENTS_HEADING] = "Records with existing clients",
        _b[TranslationConstant.I9_REVERIFICATION_MASID_TITLE] = "MASID",
        _b[TranslationConstant.DATE_UPDATE_SUCCESS_MESSAGE] = "Date has been updated successfully",
        _b[TranslationConstant.DATE_UPDATE_FAILURE_MESSAGE] = "Updating date has been failed",
        _b[TranslationConstant.SET_START_DATE_HEADING] = "Set Start Date",
        _b[TranslationConstant.CLIENT_DELETE_SUCCESS_MESSAGE] = "Deleted successfully",
        _b[TranslationConstant.CLIENT_DELETE_FAILURE_MESSAGE] = "Deleting Failed",
        _b[TranslationConstant.UPDATE_TEXT] = "Update",
        _b[TranslationConstant.VIEW_MORE] = "View More",
        _b[TranslationConstant.I9_CLIENT_UPDATE_SUCCESS_MESSAGE] = "Client has been updated successfully",
        _b[TranslationConstant.I9_CLIENT_UPDATE_FAILURE_MESSAGE] = "Updating client has been failed",
        _b[TranslationConstant.MANAGE_VALIDATIONS] = "Manage Validations",
        _b[TranslationConstant.ADD_NEW_VALIDATION_FORM_HEADING] = "Please fill the form below to add validation for selected attribute.",
        _b[TranslationConstant.I9_CLIENT_UPDATE_FAILURE_MESSAGE] = "Updating client has been failed",
        _b[TranslationConstant.MANAGE_ATTR_VAL] = "Manage Attribute Validation",
        _b[TranslationConstant.UI_VAL] = "UI Validations",
        _b[TranslationConstant.UI_MAN] = "UI Manipulations",
        _b[TranslationConstant.SAVE_VALIDATION] = "Validation(s) has been added successfully.",
        _b[TranslationConstant.EMAIL_SEND_CONFIRMATION_MESSAGE] = "Are you sure you want to send reminder email to this agent?",
        _b[TranslationConstant.SUPPORTING_DOCUMENT_VIEWER] = "View Supporting Document",
        _b[TranslationConstant.REFRESH_GRID_BTN_TEXT] = "Refresh",
        _b[TranslationConstant.REFRESH_HEADING] = "Refresh",
        _b[TranslationConstant.REFRESH_GRID_MESSAGE] = "Please refresh the grid to continue",
        _b[TranslationConstant.CHANGE_I9_VERIFIER] = "Change I9 verifier?",
        _b[TranslationConstant.SECTION_NAME] = "Section Name",
        _b[TranslationConstant.SECTION_TYPE] = "Section Type",
        _b[TranslationConstant.SECTION_TITLE] = "Section Title",
        _b[TranslationConstant.SUB_SECTION_TITLE] = "Sub-Section Title",
        _b[TranslationConstant.EDIT_SECTION_TITLE] = "Edit Section",
        _b[TranslationConstant.ADD_NEW_SECTION] = "Add New Section",
        _b[TranslationConstant.SECTION_ADDED_SUCCESS] = "Section Added Successfully",
        _b[TranslationConstant.SECTION_CREATION_FAILED] = "Section Creation Failed",
        _b[TranslationConstant.SECTION_UPDATED_SUCCESS] = "Section Updated Successfully",
        _b[TranslationConstant.UPDATE_SECTION_FAIL] = "Section Update Failed",
        _b[TranslationConstant.SECTION_NOT_DELETED_MAPPING] = "Section can't  be deleted because it is used in mapping.",
        _b[TranslationConstant.SECTION_NOT_DELETED] = "Section is not deleted.",
        _b[TranslationConstant.EMAIL_SEND_SUCCESS] = "Email has been sent. Please check your inbox",
        _b[TranslationConstant.EMAIL_SEND_FAILED] = "Email sent failed. Please try again",
        _b[TranslationConstant.EMAIL_SENT_TITLE] = "Email Sent",
        _b[TranslationConstant.EMAIL_FAILURE_TITLE] = "Email Not Sent",
        _b[TranslationConstant.SECTION_DELETED] = "Section Deleted Successfully.",
        _b[TranslationConstant.EDIT_WEB_USERS_TITLE] = "Edit Web User",
        _b[TranslationConstant.LABEL_USER_NAME] = "User Name",
        _b[TranslationConstant.LABEL_LAST_LOGIN_SUCCESS] = "Last Login Success",
        _b[TranslationConstant.LABEL_LAST_LOGIN_FAILURE] = "Last Login Failure",
        _b[TranslationConstant.EDIT_WEB_USER_FORM_DESCRIPTION] = "Edit the fields below to change the user's information.",
        _b[TranslationConstant.LABEL_UPDATE_BTN] = "Update",
        _b[TranslationConstant.SEND_PASSWORD_RESET_EMAIL_TO_USER] = "Send Password Reset Email To User",
        _b[TranslationConstant.SECTION_DELETED] = "Section Deleted Successfully.",
        _b[TranslationConstant.VIEW_DOCUMENTS] = "View Documents",
        _b[TranslationConstant.RETURN_TO_SEARCH_WEB_USER] = "Return To Search Web Users",
        _b[TranslationConstant.WEB_USER_UPDATE_SUCCESS] = "Web User has been Updated",
        _b[TranslationConstant.WEB_USER_UPDATE_FAIL] = "Web User Updation Failed",
        _b[TranslationConstant.SCREENING_COMPLETED_SUCCESS_MESSAGE] = "Screening completed",
        _b[TranslationConstant.SCREENING_COMPLETED_FAILURE_MESSAGE] = "Screening Failed",
        _b[TranslationConstant.VIEW_JOB_DOCUMENTS_TITLE] = "View Job Documents",
        _b[TranslationConstant.I9_REVERIFICATION_APPROVED] = "I-9 Reverification Approvals List",
        _b[TranslationConstant.PENDING_APPROVALS] = "Pending Approvals",
        _b[TranslationConstant.APPROVED_LISTS] = "Approved List",
        _b[TranslationConstant.RETURN_TO_HR_DOCUMENT] = "Return",
        _b[TranslationConstant.DYNAMIC_FORM_SUBMIT_FAILURE] = "Saving the form failed. Please review and try again",
        _b[TranslationConstant.UPLOAD_DOCUMENT_SUBMIT_SUCCESS] = "This document has been saved successfully.",
        _b[TranslationConstant.DIRECT_DEPOSIT_AUTH] = "Direct Deposit Authorization",
        _b[TranslationConstant.FINANCIAL_INS] = "Financial Institution",
        _b[TranslationConstant.ACC_Type] = "Account Type",
        _b[TranslationConstant.BRANCH_STREET_ADD] = "Branch Street Address",
        _b[TranslationConstant.BRANCH_CITY] = "Branch City",
        _b[TranslationConstant.BRANCH_STATE] = "Branch State",
        _b[TranslationConstant.TRANSIT_ROUTING_NUMBER] = "Transit/Routing Number",
        _b[TranslationConstant.CNF_TRANSIT_ROUTING_NUMBER] = "Confirm Transit/Routing Number",
        _b[TranslationConstant.ACC_NUM] = "Account Number",
        _b[TranslationConstant.CNF_ACC_NUM] = "Confirm Account Number",
        _b[TranslationConstant.CHECKING] = "Checking",
        _b[TranslationConstant.SAVING] = "Savings",
        _b[TranslationConstant.IMG_REF_MSG] = "Attention: Use the check image below as a reference for help finding routing and account number.",
        _b[TranslationConstant.SUCCESSFULLY_MANIPULATION_DELETE_TEXT] = "Manipulation deleted successfully.",
        _b[TranslationConstant.UPLOAD_DOCUMENT_SUBMIT_SUCCESS] = "This document has been saved successfully.",
        _b[TranslationConstant.VOID_CHECK_MSG] = "Attention: Direct Deposit will not be processed without voided check attached. Deposit slips will not be accepted. If you do not have checks, please ask your bank to provide a form with your account information and include it with this form.",
        _b[TranslationConstant.LABEL_CONFIRM_PASSWORD] = "Confirm Password",
        _b[TranslationConstant.CREATE_USER_ACKNOWLEDGMENT_TEXT] = "I acknowledge that I have read and agree to PayReel's HR Documents.",
        _b[TranslationConstant.CREATE_USER_PRIVACY_POLICY_TEXT] = "By using this site, you agree to the PayReel Privacy Policy and Terms & Conditions.",
        _b[TranslationConstant.CREATE_USER_BUTTON] = "Create User",
        _b[TranslationConstant.CREATE_USER_HEADING] = "Create User",
        _b[TranslationConstant.CREATE_USER_DESCRIPTION] = "Please enter your user information below.",
        _b[TranslationConstant.LABEL_USERNAME] = "Username",
        _b[TranslationConstant.LABEL_PASSWORD] = "Password",
        _b[TranslationConstant.RETURN_TO_LOGIN_PAGE] = "Go To Login Page",
        _b[TranslationConstant.RETURN_TO_LOGIN_PAGE] = "Click to Login",
        _b[TranslationConstant.LABEL_USER_CREATED] = "User Created",
        _b[TranslationConstant.LABEL_USER_CREATE_FAILED] = "User Creation Failed",
        _b[TranslationConstant.WEB_USER_CREATED] = "Web User has been Created",
        _b[TranslationConstant.SUCCESSFULLY_MANIPULATION_DELETE_TEXT] = "Manipulation deleted successfully.",
        _b[TranslationConstant.UPLOAD_DOCUMENT_SUBMIT_SUCCESS] = "This document has been saved successfully.",
        _b[TranslationConstant.SUPPORT_PAGE_TITLE] = "Support",
        _b[TranslationConstant.CONFIRM_DECLINING_FORM] = "Please confirm declining form",
        _b[TranslationConstant.BATCH_JOBS_TITLE] = "Batch Jobs",
        _b[TranslationConstant.BATCH_JOB_MAS_EXPORT_DESCRIPTION] = "Click the button below to batch the listed jobs for MAS export.",
        _b[TranslationConstant.CREATE_BATCH_BTN] = "Create Batch",
        _b[TranslationConstant.CANCEL_BTN] = "Cancel",
        _b[TranslationConstant.BATCH_CREATED] = "Batch Created",
        _b[TranslationConstant.BATCH_CREATED_SUCCESS] = "Batch Successfully Created",
        _b[TranslationConstant.BATCH_CREATED_FAIL_WITH_NO_RECORDS] = "No records are available to create a batch.",
        _b[TranslationConstant.BATCH_CREATION_FAILURE_MESSAGE] = "Batch creation failed. Please review and try again",
        _b[TranslationConstant.BATCH_JOBS] = "Batch Jobs",
        _b[TranslationConstant.ONE_BATCH_JOB_REQUIRED] = "At least one job is required to Batch!",
        _b[TranslationConstant.BATCHES_TITLE] = "Batches",
        _b[TranslationConstant.VIEW_BATCHES_TITLE] = "View Batches",
        _b[TranslationConstant.MY_PROFILE] = "My Profile",
        _b[TranslationConstant.USERNAME] = "Username",
        _b[TranslationConstant.PASSWORD] = "Password",
        _b[TranslationConstant.CONFIRM_PASSWORD] = "Confirm Password",
        _b[TranslationConstant.EMAIL] = "Email",
        _b[TranslationConstant.PROFILE_DETAILS_UPDATED_SUCCESS] = "Profile Details have been updated successfully",
        _b[TranslationConstant.UPDATE_PROFILE_DETAILS_FAIL] = "Username already exists",
        _b[TranslationConstant.PROFILE_PAGE_TITLE] = "Change fields below to update your information.",
        _b[TranslationConstant.CHANGE_I9_VERIFIER_FORM_HEADING] = "Please provide your i-9 verifier's details",
        _b[TranslationConstant.LABEL_EMAIL_ADDRESS] = "Email Address",
        _b[TranslationConstant.INTERNAL_API_SERVER_ERROR_500] = "Invalid Token.",
        _b[TranslationConstant.LABEL_BAD_REQUEST] = "Bad Request",
        _b[TranslationConstant.LABEL_HR_DOCUMENTS] = "HR Documents",
        _b[TranslationConstant.SIGN_UP_SUCCESSFULL] = "Sign Up is Successful.",
        _b[TranslationConstant.SUCCESSFULLY_SIGN_UP] = "Successfully Sign Up",
        _b[TranslationConstant.I9_FEDRAL_FORM] = "Federal I-9 Form",
        _b[TranslationConstant.CLICK_TO_EXPAND_TITLE] = "",
        _b[TranslationConstant.EMPLOYEE_INFORMATION_TITLE] = "Employee Information",
        _b[TranslationConstant.ATTESTATION_TITLE] = "Attestation",
        _b[TranslationConstant.SING_AND_SAVE_FORM] = "Sign and Save Form",
        _b[TranslationConstant.LABEL_MIDDLE_INITIAL] = "Middle Initial",
        _b[TranslationConstant.LABEL_OTHER_NAME] = "Other Name Used (if any)",
        _b[TranslationConstant.LABEL_SSN] = "Social Security Number",
        _b[TranslationConstant.LABEL_DOB] = "Date of Birth",
        _b[TranslationConstant.LABEL_STREET_ADDRESS] = "Street Address",
        _b[TranslationConstant.LABEL_APARTMENT_NUMBER] = "Apartment Number",
        _b[TranslationConstant.LABEL_CITY] = "City",
        _b[TranslationConstant.LABEL_ZIP] = "Zip Code",
        _b[TranslationConstant.STEP] = "Step",
        _b[TranslationConstant.PROVIDE_I9_VERIFIER_DETAIL] = "Please provide your I-9 verifier's details",
        _b[TranslationConstant.HELP] = "Help",
        _b[TranslationConstant.ATTENTION] = "Attention",
        _b[TranslationConstant.VERIFY_FIELDS] = "Verify pre-populated fields are correct before signing this form.",
        _b[TranslationConstant.EMPLOYEE_INFORMATION_ATTENTION_TEXT] = "This form must be completed and signed no later than the first day of employment, but not before accepting a job offer.",
        _b[TranslationConstant.EMPLOYEE_INFORMATION_FORM_ATTENTION_TEXT] = "Your first and last name, email, and phone number have been auto-filled, please verify they are entered correctly.",
        _b[TranslationConstant.LABEL_SELECT_AN_IMAGE_TO_UPLOAD] = "Please select an image of the document to upload?",
        _b[TranslationConstant.LABEL_DOCUMENT_LIST_TYPE] = "Which list contains the documents the employee is presenting? (see the list items on page 3 of the form above)",
        _b[TranslationConstant.LABEL_DOCUMENT_TITLE] = "Document Title",
        _b[TranslationConstant.LABEL_ASSISTANCE_CHECKBOX] = "Check this box if you needed the assistance of a Preparer and/or Translator to complete this form",
        _b[TranslationConstant.LABEL_FORM_I9_ADMISSION_NUMBER] = "Form I-94 Admission Number",
        _b[TranslationConstant.LABEL_ALIEN_REGISTERATION_NUMBER] = "Alien Registration Number / USCIS Number",
        _b[TranslationConstant.LABEL_ALIEN_AUTHORIZED_DATE] = "Alien authorized until expiration date (leave blank for N/A)",
        _b[TranslationConstant.LABEL_ATTESTATION_RADIO] = "I attest, under penalty of perjury, that I am (select one of the following):",
        _b[TranslationConstant.MINOR_TRUST_ACCOUNT] = "Minor Trust Account",
        _b[TranslationConstant.BANK_FINANCIAL_INS] = "Bank/Financial Institution",
        _b[TranslationConstant.BANK_STREET_ADD] = "Bank Street Address",
        _b[TranslationConstant.BANK_CITY] = "Bank City",
        _b[TranslationConstant.BANK_STATE] = "Bank State",
        _b[TranslationConstant.BANK_ZIP_CODE] = "Bank Zip Code",
        _b[TranslationConstant.ENTER_VALIDATION_MESSAGE] = "Enter Validation Message",
        _b[TranslationConstant.LABEL_USER_NAME_EXISTS] = "User with same name already exists.",
        _b[TranslationConstant.RECREATE_BATCH_EXPORT_FILE] = "Recreate Batch Export Files",
        _b[TranslationConstant.RESEND_THINKWARE_FILES] = "Resend Thinkware Files",
        _b[TranslationConstant.THINKWARE_EMPLOYEES] = "Thinkware Employees",
        _b[TranslationConstant.THINKWARE_LOCAL_TAX] = "Thinkware Local Tax",
        _b[TranslationConstant.THINKWARE_DIRECT_DEPOSIT] = "Thinkware Direct Deposit",
        _b[TranslationConstant.DOWNLOAD_MAS_EXPORT_FILE] = "Download MAS Export File",
        _b[TranslationConstant.DOWNLOAD_SLAM_EXPORTING] = "Download SLAM Exporting File",
        _b[TranslationConstant.BATCH_DETAILS_ONE_LINER] = "Recreate files by using the buttons below.",
        _b[TranslationConstant.DOWNLOAD_DOCUMENTS_TITLE] = "All Completed Forms",
        _b[TranslationConstant.DOWNLOAD] = "Download",
        _b[TranslationConstant.SELECT_ALL_DOCUMENTS] = "Select All Documents",
        _b[TranslationConstant.DOCUMENTS_NOT_AVAILABLE] = "Documents are not available.",
        _b[TranslationConstant.DOWNLOAD_DOCUMENTS] = "Download Documents",
        _b[TranslationConstant.BATCH_DETAILS_TITLE] = "Batch Detail",
        _b[TranslationConstant.BATCH_NAME_TITLE] = "Batch Name",
        _b[TranslationConstant.INCOMPLETE_EMPLOYEE_AGREEMENT_HEADING] = " Incomplete Employment Agreement",
        _b[TranslationConstant.INCOMPLETE_ONBOARDING_PAPERWORK_HEADING] = "Incomplete OnBoarding Paperwork",
        _b[TranslationConstant.TIMESHEETS] = "Timesheets",
        _b[TranslationConstant.TIMESHEET] = "Timesheet",
        _b[TranslationConstant.ADD_NEW_TIMESHEET] = "Add New Timesheet",
        _b[TranslationConstant.CHANGE_HOME_ADDRESS] = "Change Home Address",
        _b[TranslationConstant.UPDATE_DIRECT_DEPOSITE_OR_PAYCARD] = "Update Direct Deposit or PayCard",
        _b[TranslationConstant.UPDATE_FEDRAL_OR_STATE_W4] = "Update Federal and/or State W4",
        _b[TranslationConstant.LABEL_WEEK_END_DATE] = "Week Ending Date",
        _b[TranslationConstant.CREATE] = "Create",
        _b[TranslationConstant.SIGN_AND_SUBMIT] = "Sign and Submit",
        _b[TranslationConstant.ADD_TIME] = "Add Time",
        _b[TranslationConstant.HIDE_PAY_TEXT] = "Hide Pay",
        _b[TranslationConstant.SHOW_PAY_TEXT] = "Show Pay",
        _b[TranslationConstant.RECALL] = "Recall",
        _b[TranslationConstant.ARE_YOU_SURE_RECALL_TEXT] = "Are you sure you would like to recall this item?",
        _b[TranslationConstant.ADD] = "Add",
        _b[TranslationConstant.DUPLICATE] = "Duplicate",
        _b[TranslationConstant.MINOR_CHECK_MSG] = "Attention: Attach a copy of proof of Minor Trust Account from Financial institution. ",
        _b[TranslationConstant.UPLOAD_YOUR_DOCUMET_MINOR] = "Please Select an image of proof of Minor Trust Account",
        _b[TranslationConstant.LABEL_BAN_PHONE_NUMBER] = "Bank Phone Number",
        _b[TranslationConstant.TRUST_ACK_MSG] = "By checking here,my signature below acknowledges that a Trust Account has not been set up for the above named minor and the applicable Minor Trust Set-Aside amount will be deposited in the minors name to the CA or NY state-mandated fund.",
        _b[TranslationConstant.NAME_ACCOUNT] = "Name of Account",
        _b[TranslationConstant.NAME_TRUSTEE] = "Name of Trustee",
        _b[TranslationConstant.TYPE_TRUST_ACC] = "Type Of Trust Account",
        _b[TranslationConstant.COOGAN] = "Coogan/Blocked Trust Account (California)",
        _b[TranslationConstant.UGMA] = "UGMA Account (NY Only)",
        _b[TranslationConstant.UTMA] = "UTMA Account (NY Only)",
        _b[TranslationConstant.LNG_MSG] = "I have been given this pay notice in English because it is my primary language.",
        _b[TranslationConstant.My_PRI_LNG] = "My Primary Language is",
        _b[TranslationConstant.PRI_LANG_MSG] = "I have been given this pay notice in English only, because the Department of Labor does not offer a pay notice form in my primary language.",
        _b[TranslationConstant.FINANCIAL_INFO_FORM] = "Financial Information",
        _b[TranslationConstant.MINOR_FORM_SIGNATURE] = " By checking here,I do hereby direct the appicable minor trust set-aside funds be deposited to the account indicated above.",
        _b[TranslationConstant.VERIFY_YOUR_FORM_TEXT] = "Verify Your Form",
        _b[TranslationConstant.SIGN_SAVE_BTN] = "Sign and Save",
        _b[TranslationConstant.VERIFY_FORM_INFO_DESC] = "Please verify that the information in the form below is correct. This will populate all legal forms.",
        _b[TranslationConstant.PREPARER] = "Preparer",
        _b[TranslationConstant.EMPLOYEE_INFORMATION_HEADING] = "Employee Information",
        _b[TranslationConstant.EMPLOYEE_INFORMATION_VERIFICATION] = "Employee Information Verification",
        _b[TranslationConstant.MAILING_ADDRESS_SAME_TEXT] = "Please check this box if your legal address is the same as your mailing address",
        _b[TranslationConstant.MAILING_STREET_ADDRESS] = "Mailing Address Street Address",
        _b[TranslationConstant.MAILING_APARTMENT] = "Mailing Address Apartment Number",
        _b[TranslationConstant.MAILING_CITY] = "Mailing Address City",
        _b[TranslationConstant.MAILING_STATE] = "Mailing Address State",
        _b[TranslationConstant.MAILING_ZIP] = "Mailing Address Zip Code",
        _b[TranslationConstant.WORK_VISA_PERMIT_TEXT] = "Are you working in the U.S. on a VISA work permit (ex. M1, F1)?",
        _b[TranslationConstant.VISA_TYPES_TEXT] = "Please indicate the VISA type you will upload",
        _b[TranslationConstant.EMERGENCY_CONTACT_NAME] = "Emergency Contact Name",
        _b[TranslationConstant.EMERGENCY_CONTACT_PHONE] = "Phone Number",
        _b[TranslationConstant.EMPLOYEE_PHYSICIAN] = "Employee Physician",
        _b[TranslationConstant.EMPLOYEE_PHYSICIAN_PHONE] = "Physician Phone Number",
        _b[TranslationConstant.EMPLOYEE_HEALTH_INSURANCE] = "Health Insurance Provider",
        _b[TranslationConstant.EMPLOYEE_POLICY_NUMBER] = "Policy Number",
        _b[TranslationConstant.EMPLOYEE_MED_ALERGIES_CONDITION] = "Any medications, allergies, or conditions?",
        _b[TranslationConstant.CONTINUE_BTN] = "Continue",
        _b[TranslationConstant.EMERGENCY_INFORMATION] = "Emergency Information",
        _b[TranslationConstant.LABEL_PHONE_NUMBER_2] = "Phone Number 2",
        _b[TranslationConstant.FORM_SAVED_SIGNED_SUCCESS_MESSAGE] = "This form has been signed and saved",
        _b[TranslationConstant.UPDATE_PERSONAL_INFO] = "Update Personal Info",
        _b[TranslationConstant.UPDATE_USER_NAME] = "Update User Name",
        _b[TranslationConstant.UPDATE_PASSWORD] = "Update Password",
        _b[TranslationConstant.HOME_ADDRESS_CHANGE] = "Home Address Change",
        _b[TranslationConstant.PAID_SICK_LEAVE] = "Paid Sick Leave",
        _b[TranslationConstant.ADDRESS1] = "Address1",
        _b[TranslationConstant.ADDRESS2] = "Address2",
        _b[TranslationConstant.USER_NAME_PASSWORD_RULES] = "Username/Password must follow the following rules",
        _b[TranslationConstant.CHARACTERS_LENGTH] = "10-50 characters in length",
        _b[TranslationConstant.NO_SPECS] = "No spaces",
        _b[TranslationConstant.INCLUDE_ONE_ALPHA] = "Include at least one alpha character(a-z)",
        _b[TranslationConstant.INCLUDE_ONE_NUMERIC] = "Include at least one numeric character(0-9)",
        _b[TranslationConstant.PASSWORD_RULES] = "Password must follow the following rules.",
        _b[TranslationConstant.NOTIFY_ADDRESS_CHANGE] = "Use this form to notify us if your address is changing",
        _b[TranslationConstant.EFFECTVE_DATE] = "Effective Date",
        _b[TranslationConstant.PERSONAL_INFORMATION] = "Personal Information",
        _b[TranslationConstant.ADDRESS_CHANGE] = "Address Changes",
        _b[TranslationConstant.MORE_INFORMATION] = "More Information",
        _b[TranslationConstant.COMP_VAL_TYPE] = "Compare Value Type",
        _b[TranslationConstant.PROPERTY_NAME] = "Property Name",
        _b[TranslationConstant.COMP_VALUE] = "Compare Value",
        _b[TranslationConstant.APPLICABLE_ATTRIBUTES] = "Applicable Attribute(s)",
        _b[TranslationConstant.PROPERTY_VALUE] = "Property Value",
        _b[TranslationConstant.NEW_YORK_MINOR_WORK_PERMIT] = "New York Minor Work Permit",
        _b[TranslationConstant.REQ_NEW_YORK_MINOR_WORK_DOC] = "*Uplaod an image of New York Minor Work Permit",
        _b[TranslationConstant.EMP_INFO_LEGAL_ATTENTION] = "Legal Name Required. This data will be used to populate other forms.",
        _b[TranslationConstant.DECLINED_SUCCESS] = "Declined Successfully",
        _b[TranslationConstant.DECLINE_FAILED] = "Declined Failed",
        _b[TranslationConstant.FINANCIAL_INFORMATION] = "Financial Information",
        _b[TranslationConstant.DIRECT_DEPOSIT_FORM_ERR] = "Form Validation Failed. Please fix the highlighted form errors and try again.",
        _b[TranslationConstant.IMG_UPLOAD_SUCCESS_HEADING] = "Image Successfully uploaded.",
        _b[TranslationConstant.IMG_UPLOAD_SUCCESS] = "Image has been uploaded.",
        _b[TranslationConstant.DIRECT_DEPOSIT_UPLOAD_TEXT] = "Please select an image of a voided check or bank form with account information to upload.",
        _b[TranslationConstant.MINOR_TRUST_SIGN_ATTN_MSG] = "By signing this form, I do hereby direct the appicable minor trust set-aside funds be deposited to the account indicated above",
        _b[TranslationConstant.SUCCESSFULLY_CHANGED] = "Successfully Changed!",
        _b[TranslationConstant.I9_VERIFIER_SUCCESS_MESSAGE] = "I9 Verifier Changed Successfully",
        _b[TranslationConstant.CHANGE_I9_VERIFIER_HEADING] = "Change I-9 verifier",
        _b[TranslationConstant.REVIEW_AND_SIGN] = "Review Form and sign below",
        _b[TranslationConstant.CLICK_ONE] = "Click one",
        _b[TranslationConstant.NEW_YORK_EXEMPT_EMP_NOTICE] = "New York Exempt Employee Notice",
        _b[TranslationConstant.GEORGIA_W4] = "Georgia-W4",
        _b[TranslationConstant.VIEW_FORM_WITH_INS] = "View Form With Instructions",
        _b[TranslationConstant.STATE_OF_RESIDENCE] = "Your' State Of Residence",
        _b[TranslationConstant.SPOUSE_STATE_OF_RESIDENCE] = "Your Spouse' State Of Residence",
        _b[TranslationConstant.SINGLE] = "A. Single: Enter 0 or 1",
        _b[TranslationConstant.MARRIED_JOINT_WOKRING] = "B. Married Filling joint, both spouses working: Enter 0 or 1",
        _b[TranslationConstant.MARRIED_ONE_WORKING] = "C. Married Filling joint, one spouses working: Enter 0 or 1 or 2",
        _b[TranslationConstant.MARRIED_SEPARATION] = "D. Married Filling separation: Enter 0 or 1",
        _b[TranslationConstant.HEAD_OF_HOUSEHOLD] = "E. Head of Household: Enter 0 or 1",
        _b[TranslationConstant.MARITAL_STATUS_LABEL] = "3. Marital Status (If you do not wish to claim an allowance, enter “0” in the brackets beside your marital status.)",
        _b[TranslationConstant.DEPENDENCE_ALLOW] = "4. DEPENDANT ALLOWANCES:",
        _b[TranslationConstant.ADDITIONL_ALLOWANCES] = "ADDITIONAL ALLOWANCES?",
        _b[TranslationConstant.ADDITIONL_ALLOWANCES_INP] = "5. ADDITIONAL ALLOWANCES:",
        _b[TranslationConstant.ADDITIONAL_WITHHOLDINGS] = "6. ADDITIONAL WITHHOLDINGS:",
        _b[TranslationConstant.WOKSHEET_TEXT] = "Worksheet For Calculating Additional Allowances",
        _b[TranslationConstant.STANDARD_DEDUCTION_TEXT] = "1. Complete this line if using standard deduction:",
        _b[TranslationConstant.AGE_65_OR_OVER] = "Age 65 or Over",
        _b[TranslationConstant.BLIND] = "Blind",
        _b[TranslationConstant.NUMBER_OF_CHECKBOXES_CHKD] = "Number of checkboxes checked",
        _b[TranslationConstant.NUM] = "*1300",
        _b[TranslationConstant.ADDITIONAL_ALLOWANCES_FOR_DEDUCTION] = "2. ADDITIONAL ALLOWANCES FOR DEDUCTIONS:",
        _b[TranslationConstant.FEDERAL_ITEMIZING_TXT] = "A. Federal Estimated Itemized Deductions (If Itemizing Deductions):",
        _b[TranslationConstant.GEORGIA_STANDARD_TXT] = "B. Georgia Standard Deduction :(enter one) Single/HOH $4,600 Each Spouse $3,000",
        _b[TranslationConstant.SUBTRACT_LINE_B_FROM_A] = "C. Subtract Line B from Line A (If zero or less, enter zero):",
        _b[TranslationConstant.ALLOWABLE_DEDUCTIONS] = "D. Allowable Deductions to Federal Adjusted Gross Income:",
        _b[TranslationConstant.ADD_THE_AMOUNT] = "E. Add the Amounts on Lines 1, 2C, and 2D:",
        _b[TranslationConstant.ESTIMATE_OF_TAXABLE] = "F. Estimate of Taxable Income not Subject to Withholding:",
        _b[TranslationConstant.SUBTRACT_LINE_F_FROM_E] = "G. Subtract Line F from Line E (if zero or less, stop here):",
        _b[TranslationConstant.DIVIDE_AMOUNT] = "H. Divide the Amount on Line G by $3,000. Enter total here and on Line 5 above:",
        _b[TranslationConstant.LETTER_USED] = "7. LETTER USED:",
        _b[TranslationConstant.TOTAL_ALLOWANCE] = "TOTAL ALLOWANCES:",
        _b[TranslationConstant.EXEMPT] = "8. Exempt: ",
        _b[TranslationConstant.EXEMPT_OPTION_1] = "I claim exemption from withholding because I incurred no Georgia income tax liability last year and I do not expect to have a Georgia income tax liability this year. Check here",
        _b[TranslationConstant.EXEMPT_OPTION_2] = "I certify that I am not subject to Georgia withholding because I meet the conditions set forth under the Servicemembers Civil Relief Act as provided on page 2.",
        _b[TranslationConstant.EMPLOYEE_DASHBOARD_HEADING] = "",
        _b[TranslationConstant.CHANGE_FAILED] = "Change Failed!",
        _b[TranslationConstant.I9_VERIFIER_FAILED_MESSAGE] = "I9 Verifier Change Failed",
        _b[TranslationConstant.BULK_IMPORT_JOBS_TITLE] = "Bulk Import Jobs",
        _b[TranslationConstant.BULK_IMPORT_UPLOAD_FILE_FAILED] = "Unable to Validate File",
        _b[TranslationConstant.BULK_IMPORT_UPLOAD_FILE_FAILED_MESSAGE] = "There was a problem with the file validation.",
        _b[TranslationConstant.BULK_IMPORT_UPLOAD_FILE_SUCCESS] = "File Successfully Uploaded",
        _b[TranslationConstant.BULK_IMPORT_UPLOAD_FILE_SUCCESS_MESSAGE] = "File has been uploaded.",
        _b[TranslationConstant.EMPLOYEE_REPRESENTATIVE_REVIEW_TITLE] = "Employer or Authorized Representative Review and Verification",
        _b[TranslationConstant.ISSUEING_AUTHORITY_LABEL] = "Issuing Authority",
        _b[TranslationConstant.DOCUMENT_NUMBER] = "Document Number",
        _b[TranslationConstant.CAL_MINOR_WORK_PERMIT] = "California Minor Work Permit",
        _b[TranslationConstant.UPLOAD_DOC_CAL_WORK_PEMIT] = "Please select an image of the California Minor Work Permit",
        _b[TranslationConstant.REQ_CALIFORNIA_MINOR_WORK_DOC] = "Uplaod an image of California Minor Work Permit",
        _b[TranslationConstant.UPLOAD_FILE] = "Upload File",
        _b[TranslationConstant.LABEL_DOCUMENT] = "Documents",
        _b[TranslationConstant.LABEL_FORM_DATA] = "Form Data",
        _b[TranslationConstant.THINKWARE_EMPLOYEES_TITLE] = "Thinkware Employees",
        _b[TranslationConstant.FILL_FORM_FIELDS] = "Fill Form Fields",
        _b[TranslationConstant.EXPENSE_REPORTS] = "Expense Reports",
        _b[TranslationConstant.EXPENSE_REPORT] = "Expense Report",
        _b[TranslationConstant.ADD_EXPENSE_REPORT] = "Add Expense Report",
        _b[TranslationConstant.ADD_EXPENSE] = "Add Expense",
        _b[TranslationConstant.ATTACH_RECEIPT] = "Attach Receipt",
        _b[TranslationConstant.EXPENSE_REPORT_RECEIPT] = "Expense Report Receipt",
        _b[TranslationConstant.ADD_FILE] = "Add File",
        _b[TranslationConstant.TAX_STATEMENTS] = "Tax Statements",
        _b[TranslationConstant.TAX_YEAR] = "Tax Year",
        _b[TranslationConstant.DOWNLOAD_STATEMENT] = "Download Statement",
        _b[TranslationConstant.BULK_IMPORT_FORM_HEADING] = "Please upload the csv file below to import the bulk jobs",
        _b[TranslationConstant.SELECT_DATE_TYPE_PAYSTUB] = "Date Type",
        _b[TranslationConstant.PAYDATE] = "Pay Date",
        _b[TranslationConstant.WEEK_ENDING_DATE] = "Week Ending Date",
        _b[TranslationConstant.PAY_STUBS] = "Pay Stubs",
        _b[TranslationConstant.DOWNLOAD_PAY_STATEMENT] = "Download Pay Statement",
        _b[TranslationConstant.EXPORT_FILES_SUCCESS] = "Resend Thinkware Files",
        _b[TranslationConstant.EXPORT_FILES_SUCCESS_MESSAGE] = "Thinkware Files have been sent to email",
        _b[TranslationConstant.EXPORT_FILE_RECREATED] = "Export Files Recreated",
        _b[TranslationConstant.EXPORT_FILE_RECREATED_MESSAGE] = "Files Saved",
        _b[TranslationConstant.EXPORT_FILE_RESEND_FAIL_MESSAGE] = "Unsuccessfull while resend Thinkware files!",
        _b[TranslationConstant.CREDIT_CARD_TRANSACTION_TITLE] = "Credit Card Transaction Details",
        _b[TranslationConstant.SAME_STATE_TXT] = "Please enter states of residence for you and your spouse. Both has to be same.",
        _b[TranslationConstant.SELECT_ITEM_TO_UPDATE] = "Select the item to Update:",
        _b[TranslationConstant.DIRECT_DEPOSIT] = "Direct Deposit",
        _b[TranslationConstant.PAYCARD] = "PayCard",
        _b[TranslationConstant.W4_CHANGE_TITLE_HEADING] = "W4 Change",
        _b[TranslationConstant.FEDERAL_DIALOG] = "Federal",
        _b[TranslationConstant.STATE_DIALOG] = "State",
        _b[TranslationConstant.FEDERAL_OR_STATE_DIALOG_BODY] = "Is this for the Federal or State W4?",
        _b[TranslationConstant.VIEW_STATUS_HISTORY] = "View Status History",
        _b[TranslationConstant.VIEW_PDF] = "View PDF",
        _b[TranslationConstant.PRINT_TIMESHEET] = "Print Timesheet",
        _b[TranslationConstant.TIMESHEET_STATUS_HISTORY_TITLE] = "Timesheet Status History",
        _b[TranslationConstant.LABEL_COMMENT] = "Comment",
        _b[TranslationConstant.SIGN_AND_SUBMIT_TIMESHEET_HEADING] = 'By clicking "Sign Timesheet" you verify your time is correct',
        _b[TranslationConstant.CREDIT_CARD_TRANSACTION_RECEIPTS_TITLE] = "Credit Cards Transaction Receipts",
        _b[TranslationConstant.UI_CAL] = "UI Calculations",
        _b[TranslationConstant.TARGET_OUTPUT] = "Target Output",
        _b[TranslationConstant.UI_CALCULATION_SAVED_SUCCESS_MSG] = "Calculation Saved Successfully!",
        _b[TranslationConstant.UI_CALCULATION_DELETE_SUCCESS_MSG] = "Calculation Deleted Successfully!",
        _b[TranslationConstant.SIGN_AND_SUBMIT_EXPENSE_REPORT_HEADING] = 'By clicking "Sign Expense Report" you verify this report is correct',
        _b[TranslationConstant.LABEL_APPROVE_BTN] = "Approve",
        _b[TranslationConstant.LABEL_NOT_APPROVE_BTN] = "Not Approve",
        _b[TranslationConstant.SUCCESSFULLY_DECLINED] = "Successfully Declined",
        _b[TranslationConstant.VISA_DECLINED_SUCCESSFULLY] = "Visa Declined Successfully.",
        _b[TranslationConstant.RETURN_TO_TASKS] = "Return to Tasks",
        _b[TranslationConstant.SUCCESSFULLY_APPROVED] = "Successfully Approved",
        _b[TranslationConstant.VISA_APPROVED_SUCCESSFULLY] = "Visa Approved Successfully.",
        _b[TranslationConstant.APPROVAL_FAILED] = "Approval Failed",
        _b[TranslationConstant.VISA_APPROVAL_FAILED] = "Visa Approval Failed.",
        _b[TranslationConstant.ERROR_IN_DECLINE] = "Error in Decline",
        _b[TranslationConstant.DECLINE_ERROR_MESSAGE] = "Visa Declination Failed.",
        _b[TranslationConstant.DAY_WORKED] = "Day Worked",
        _b[TranslationConstant.PROJECT_CODE] = "Project Day",
        _b[TranslationConstant.WORK_LOCATION] = "Work Location",
        _b[TranslationConstant.LABEL_POSITION] = "Position",
        _b[TranslationConstant.ADD_SICK_LEAVE] = "Use Sick Leave",
        _b[TranslationConstant.AVAILABLE_HOURS] = "Available Hours",
        _b[TranslationConstant.TIME_IN] = "Time In",
        _b[TranslationConstant.TIME_OUT] = "Time Out",
        _b[TranslationConstant.LABEL_MEALS] = "Meals (in hours)",
        _b[TranslationConstant.EXPENSE_DATE] = "Expense Date",
        _b[TranslationConstant.EXPENSE_CODE] = "Expense Code",
        _b[TranslationConstant.LABEL_AMOUNT] = "Amount",
        _b[TranslationConstant.LABEL_TITLE] = "Title",
        _b[TranslationConstant.RETURN_TO_TASK] = "Return to Task",
        _b[TranslationConstant.VENDOR_PAYMENTS_TITLE] = "Vendor Payment",
        _b[TranslationConstant.VENDOR_PAYMENTS] = "Vendor Payments",
        _b[TranslationConstant.ADD_VENDOR_PAYMENT] = "Add Vendor Payment",
        _b[TranslationConstant.LABEL_INVOICE_DATE] = "Invoice Date",
        _b[TranslationConstant.LABEL_INVOICE_NUMBER] = "Invoice #",
        _b[TranslationConstant.RESEND_EMPLOYEE_AGREEMENT_EMAIL] = "Resend Employee Agreement",
        _b[TranslationConstant.RESEND_EMPLOYEE_ONBOARDING_LINK_EMAIL] = "Resend employee Onboarding link",
        _b[TranslationConstant.ADD_PAYMENT] = "Add Payment",
        _b[TranslationConstant.ADD_BACKUP] = "Add Backup",
        _b[TranslationConstant.PROJECT_PURCHASE_ORDER] = "Project Purchase Order",
        _b[TranslationConstant.PROJECT_BEG_DATE] = "Project Begin Date",
        _b[TranslationConstant.PROJECT_END_DATE] = "Project End Date",
        _b[TranslationConstant.VENDOR_PAYMENT_BACKUP] = "Vendor Payment Backup",
        _b[TranslationConstant.ADD_BACKUP_PAYMENT_FILE] = "Add Backup Payment File",
        _b[TranslationConstant.NEW_YORK_DAY_RATE_EMP_NOTICE] = "New York Day Rate Employee Notice",
        _b[TranslationConstant.REVIEW_AND_SIGN] = "Review Form and sign below",
        _b[TranslationConstant.CLICK_ONE] = "Click one",
        _b[TranslationConstant.CAL_YORK_NOTICE_TO_EMP] = "California Notice To Employee",
        _b[TranslationConstant.VIEW_NOTICE_TO_EMP] = "View Notice To Employee",
        _b[TranslationConstant.NOTIFY_PERSONAL_INFO_CHANGE] = "Use this form to update your username, password, and personal information",
        _b[TranslationConstant.BACKUP] = "Backup",
        _b[TranslationConstant.VENDOR_DOCUMENT_TITLE] = "Vendor Documents",
        _b[TranslationConstant.VENDOR_DOCUMENT_HEADING] = "Please fill the fields.",
        _b[TranslationConstant.WORKERS_COMPENSATION] = "Worker's Compensation",
        _b[TranslationConstant.GENERAL_LIABILITY] = "General Liability",
        _b[TranslationConstant.W9_DOCUMENT] = "W9 Document",
        _b[TranslationConstant.CONTRACT] = "Contract",
        _b[TranslationConstant.Endeavor_OnLocation_PayReel_PerDiem_Agreement_Title] = "Endeavor OnLocation PayReel PerDiem Agreement",
        _b[TranslationConstant.PAY_STUBS_DOWNLOAD_FAILURE] = "Pay Stubs download fail",
        _b[TranslationConstant.END_NONEXEMPT_DAILY_EMP_AGREEMENT] = "Endeavor Non-Exempt Daily Employee Agreement",
        _b[TranslationConstant.VIEW_WITH_INSTRUCTIONS] = "View Form With Instructions",
        _b[TranslationConstant.CONTENTS_TV_RELEASE] = "Content/s TV Release",
        _b[TranslationConstant.DOWNLOAD_FAILURE] = "Document download failed",
        _b[TranslationConstant.ARE_YOU_SURE_DELETE_ITEM] = "Are you sure you would like to Delete this item?",
        _b[TranslationConstant.ENDEAVOR_NON_EXCEMPT_EMPLOYEE_AGREEMENT_TITLE] = "Endeavor Hourly Non-Exempt Employment Agreement",
        _b[TranslationConstant.LABEL_REQUIRED_FIELDS] = "Please Sign...",
        _b[TranslationConstant.TIMESHEET_DELETED_SUCEESS] = "Time entry has been Deleted",
        _b[TranslationConstant.TIMESHEET_DELETED_FAILURE] = "Deleting time entry has been failed",
        _b[TranslationConstant.SEXUAL_HARASSMENT_CERTIFICATE] = "Sexual Harassment Certificate",
        _b[TranslationConstant.UPLOAD_SEXUAL_HARASSMENT_CERTIFICATE] = "Upload Sexual Harassment Certificate",
        _b[TranslationConstant.VOID_CERTIFICATE_CHECK_MSG] = "Attention: Please upload an image of the document and make sure the document number and expiration date are readable.",
        _b[TranslationConstant.CERIFICATE_UPLOAD_TEXT] = "Please select an image of the document to upload.",
        _b[TranslationConstant.DATE] = "Date",
        _b[TranslationConstant.LABEL_STATUS] = "Status",
        _b[TranslationConstant.RADIO_ALL_STATUS] = "All Statuses",
        _b[TranslationConstant.RADIO_ACTIVE_STATUS] = "Active",
        _b[TranslationConstant.RADIO_INACTIVE_STATUS] = "Inactive",
        _b[TranslationConstant.LABEL_SSN_EID] = "SSN/EID",
        _b[TranslationConstant.LABEL_COMPANY_NAME] = "Company Name",
        _b[TranslationConstant.LABEL_VENDOR_ID] = "Vendor ID",
        _b[TranslationConstant.LABEL_VENDORS_HEADING] = "Vendors",
        _b[TranslationConstant.NEW_YORK_HOURLY_EMPLOYEE_NOTICE_AGR] = "By clicking the button below, I agree that the digital signature produced is an electronic representation of my signature for all purposes associated with executing my employment with ".concat(AppConstant.PAYREEL_TEXT, " and will carry the same force as a pen-and-paper signature."),
        _b[TranslationConstant.NEW_YORK_HOURLY_EMPLOYEE_NOTICE_TITLE] = "New York Hourly Employee Notice",
        _b[TranslationConstant.CHECK_ONE] = "Check One",
        _b[TranslationConstant.NEW_YORK_EMPLOYEE_NOTICE_SALARY_TITLE] = "New York Employee Notice Salary",
        _b[TranslationConstant.LABEL_COST_CENTER] = "Cost Center",
        _b[TranslationConstant.LABEL_SEARCH_VENDOR_PAYMENTS_HEADING] = "Search Vendor Payments",
        _b[TranslationConstant.CERT_UPLOAD_SUCCESS_HEADING] = "Certificate Successfully Uploaded",
        _b[TranslationConstant.CERT_UPLOAD_SUCCESS] = "Certificate has been Uploaded",
        _b[TranslationConstant.EXPENSE_REPORT_DELETED_SUCCESS] = "Expense report has been deleted",
        _b[TranslationConstant.EXPENSE_REPORT_DELETED_FAILED] = "Deleting report has been failed",
        _b[TranslationConstant.VERIFY_FIRST_BG_CHECK] = "Verify First Background Check",
        _b[TranslationConstant.FILL_FORM_FIELDS_TITLE] = "Fill Form Fields",
        _b[TranslationConstant.LABEL_REQUEST_COPY_CHECKBOX] = "California, Minnesota and Oklahoma Applicants only: Check box if you request a copy of any consumer report ordered on you.",
        _b[TranslationConstant.LABEL_SUMMARY_RIGHTS_CHECKBOX] = "I understand that I have rights under the Fair Credit Reporting Act and I acknowledge receipt of the Summary of Rights.",
        _b[TranslationConstant.ADD_INITIALS] = "Please add your initials to acknowledge that you understand the information on Page 1 of this document.",
        _b[TranslationConstant.ARTICLE_23A_NY_CORRECTION_LAW] = "For consumers applying for work in New York: I acknowledge receiving a copy of Article 23-A of the New York Correction Law. Please add initials to the textbox to acknowledge.",
        _b[TranslationConstant.PREVIOUS] = "Previous",
        _b[TranslationConstant.DRIVERS_LICENSE_NUMBER] = "Drivers License Number",
        _b[TranslationConstant.STATE_ISSUING_DRIVERS_LICENSE] = "State Issuing Drivers License",
        _b[TranslationConstant.LABEL_ALIAS_NAME_USED] = "Alias Name Used",
        _b[TranslationConstant.MINNESOTA_WAGE_THEFT] = "Minnesota Wage Theft",
        _b[TranslationConstant.END_EXEMPT_EMP_AGREEMENT] = "Endeavor Exempt Employee Agreement",
        _b[TranslationConstant.TAX_STATEMENT_NOT_AVAILABLE] = "Tax Statement pdf not available",
        _b[TranslationConstant.PAY_STUBS_NOT_AVAILABLE] = "Pay Stubs pdf not available",
        _b[TranslationConstant.FAILED] = "Failed",
        _b[TranslationConstant.END_ONLOC_PAYREEL_EXEMPT] = "Endeavor OnLocation PayReel Exempt Temp",
        _b[TranslationConstant.CLIENT_POSITIONS] = "Client Positions",
        _b[TranslationConstant.LABEL_NAME] = "Name",
        _b[TranslationConstant.LABEL_CLOSED] = "Closed",
        _b[TranslationConstant.COST_CENTERS_TITLE] = "Cost Centers",
        _b[TranslationConstant.ADD_COST_CENTER_BTN] = "Add Cost Center",
        _b[TranslationConstant.LABEL_ALL] = "All",
        _b[TranslationConstant.SEARCH_MANUAL_PAYMENT_TITLE] = "Search Manual Payments",
        _b[TranslationConstant.LABEL_PAYMENT_TYPE] = "Payment type",
        _b[TranslationConstant.HOME_STATE_CHANGE_POPUP_HEADING] = "Please confirm",
        _b[TranslationConstant.HOME_STATE_CHANGE_POPUP_TEXT] = "Is it a State change?",
        _b[TranslationConstant.MY_VENDOR_PAYMENTS] = "My Vendor Payments",
        _b[TranslationConstant.ALL_VENDOR_PAYMENTS] = "All Vendor Payments",
        _b[TranslationConstant.LABEL_APPROVE_VENDOR_PAYMENT_BUTTON] = "Approve Selected Vendor Payments",
        _b[TranslationConstant.LABEL_APPROVE_VENDOR_PAYMENTS_TITLE] = "Approve Vendor Payments",
        _b[TranslationConstant.LABEL_VENDOR_PAYMENTS] = "Vendor Payments",
        _b[TranslationConstant.APPROVE_VENDOR_HEADING] = "Select an Option",
        _b[TranslationConstant.VOID_INVOICE] = "Void Invoice",
        _b[TranslationConstant.LABEL_CLIENT_NAME] = "Client Name",
        _b[TranslationConstant.LABEL_AR_EXPORT] = "AR Export",
        _b[TranslationConstant.INVOICE_NUMBER] = "Invoice Number",
        _b[TranslationConstant.TIMESHEET_RECALLED_SUCEESS] = "Time entry has been Recalled",
        _b[TranslationConstant.TIMESHEET_RECALLED_FAILURE] = "Your timesheet has been approved. Please contact your supervisor",
        _b[TranslationConstant.SUCCESS] = "Success",
        _b[TranslationConstant.PDF_REPORT_NOT_AVAILABLE] = "PDF Report not available",
        _b[TranslationConstant.LABEL_EMPLOYEE] = "Employees",
        _b[TranslationConstant.LABEL_EIN] = "Employee Identification Number",
        _b[TranslationConstant.VENDOR_PAYMENT_HISTORY_TITLE] = "Vendor Payment Status History",
        _b[TranslationConstant.ADD_USER_BTN] = "Add User",
        _b[TranslationConstant.CLIENT_USERS_TITLE] = "Client Users",
        _b[TranslationConstant.LABEL_SEARCH_TIME_SHEETS] = "Search Timesheets",
        _b[TranslationConstant.TIME_ADDED_SUCCESSFULLY] = "Added Successfully",
        _b[TranslationConstant.LABEL_VENUE] = "Venue",
        _b[TranslationConstant.LABEL_ADDRESS_1] = "Address 1",
        _b[TranslationConstant.LABEL_ADDRESS_2] = "Address 2",
        _b[TranslationConstant.LABEL_SEARCH_EXPENSE_REPORT_HEADING] = "Search Expense Reports",
        _b[TranslationConstant.LABEL_COST_CENTER_TESTING] = "Cost Center Testing",
        _b[TranslationConstant.EXPENSE_REPORT_TITLE] = "Expense Report",
        _b[TranslationConstant.EXPENSE_REPORT_HISTORY_TITLE] = "Expense Report Status History",
        _b[TranslationConstant.EXPENSE_REPORT_BACKUP] = "Expense Report Receipt",
        _b[TranslationConstant.ADDRESS_UPDATED_SUCCESSFULLY] = "Your address has been successfully changed",
        _b[TranslationConstant.EDIT_HR_DOCUMENT] = "Edit HR Document",
        _b[TranslationConstant.STEPS_TO_EDIT_HR_DOCUMENT] = "Follow the steps to edit HR document.",
        _b[TranslationConstant.EXPENSE_RECALLED_SUCEESS] = "Expense report has been Recalled",
        _b[TranslationConstant.EXPENSE_RECALLED_FAILURE] = "Your expense report has been approved. Please contact your supervisor.",
        _b[TranslationConstant.MASTER_CLIENTS_TITLE] = "Master Clients",
        _b[TranslationConstant.ADD_MASTER_CLNT_BTN] = "Add Master Client",
        _b[TranslationConstant.EDIT_MASTER_CLNT_BTN] = "Edit Master Client",
        _b[TranslationConstant.LABEL_MASTER_CLIENT] = "Master Client",
        _b[TranslationConstant.LABEL_DARWINN_CLIENT_ID] = "Darwin Client ID",
        _b[TranslationConstant.EXPENSE_BACKUP_REPORT] = "Expense Report Receipt",
        _b[TranslationConstant.LABEL_REQUISITION_NUMBER] = "Requisition Number",
        _b[TranslationConstant.LABEL_ADDITIONAL_HOURS] = "Addl Hrs",
        _b[TranslationConstant.LABEL_GUARANTEE_HOURS] = "Guaranteed Hours",
        _b[TranslationConstant.LABEL_PAY_CODE] = "Pay Code",
        _b[TranslationConstant.LABEL_PAY_CODE_HOURS] = "Hours",
        _b[TranslationConstant.LABEL_UDF1_ALIAS] = "UDF 1",
        _b[TranslationConstant.LABEL_UDF2_ALIAS] = "UDF 2",
        _b[TranslationConstant.LABEL_UDF3_ALIAS] = "UDF 3",
        _b[TranslationConstant.LABEL_SUPERVISOR_ADD_TIME] = "Supervisor",
        _b[TranslationConstant.TIMESHEET_SUBMIT_FALIURE] = "Saving the Timehsheet failed. Please review and try again",
        _b[TranslationConstant.LABEL_APPROVE_EXPENSE_REPORT_TITLE] = "Approve Expense Reports",
        _b[TranslationConstant.MY_EXPENSE_REPORT] = "My Expense Reports",
        _b[TranslationConstant.ALL_EXPENSE_REPORT] = "All Expense Reports",
        _b[TranslationConstant.LABEL_EXPENSE_REPORT] = "Expense Reports",
        _b[TranslationConstant.LABEL_APPROVE_EXPENSE_REPORT_BUTTON] = "Approve Selected Expense Reports",
        _b[TranslationConstant.CAN_NOT_ASSIGN_YOURSELF_I9_VERIFIER] = "You cannot assign yourself as i-9 verifier!",
        _b[TranslationConstant.ACCESS_CLIENT] = "Access Client",
        _b[TranslationConstant.CLIENTS_TITLE] = "Clients",
        _b[TranslationConstant.ADD_CLIENT_BTN] = "Add Client",
        _b[TranslationConstant.SLAM_LOCATIONS] = "Slam Locations",
        _b[TranslationConstant.EDIT_SLAM_LOCATION] = "Edit Slam Location",
        _b[TranslationConstant.ADD_SLAM_LOCATION] = "Add Slam Location",
        _b[TranslationConstant.TIME_UPDATED_SUCCESSFULLY] = "Updated Sucessfully",
        _b[TranslationConstant.ACK_AND_SAVE] = "Acknowledge and Save",
        _b[TranslationConstant.ACK_AND_SAVE_MSG] = "By clicking the button below, I agree that I have read the above document.",
        _b[TranslationConstant.RECEIPT_UPLOADED_SUCCESSFULLY] = "Receipt uploaded successfully",
        _b[TranslationConstant.SLAM_LOCATION_SAVE_SUCCESS] = "Slam location has been saved successfullly.",
        _b[TranslationConstant.SLAM_LOCATION_SAVE_FAILED] = "Saving Slam Location failed.Please try again.",
        _b[TranslationConstant.RECEIPT_SUBMITTED_SUCCESSFULLY] = "Receipt submitted successfully",
        _b[TranslationConstant.RECEIPT_DELETED_SUCCESSFULLY] = "Receipt deleted successfully",
        _b[TranslationConstant.ONE_RECEIPT_REQUIRED] = "At least one receipt is required to Submit!",
        _b[TranslationConstant.MISSISSIPPI_EMP_WITH_HOLDING] = "Mississippi Employee's Withholding Exemption",
        _b[TranslationConstant.LABEL_MARITAL_STATUS] = "Marital Status",
        _b[TranslationConstant.LABEL_EXEMPTION_SINGLE] = "1. Single - Enter $6,000 as exemption",
        _b[TranslationConstant.LABEL_UNEMPLOYED_SPOUSE] = "2. Married - Spouse NOT employed: Enter $12,000",
        _b[TranslationConstant.LABEL_EMPLOYED_SPOUSE] = "2. Married - Spouse IS employed: Enter that part of $12,000 claimed by you, in multiples of $500. See instructions 2(b)",
        _b[TranslationConstant.LABEL_HEAD_OF_FAMILY] = "3. Head of Family - Enter $9,500 as exemption. To qualify as head of family, you must be single and have a dependent living in the home with you. See instructions 2(c) & (d)",
        _b[TranslationConstant.LABEL_NUMBER_OF_DEPENDENTS] = "Number of Dependent claimed for Box 4",
        _b[TranslationConstant.LABEL_CLAIMED_DEPENDENTS_AMOUNT] = "4. Dependents - You may claim $1,500 for each dependent,* other than for taxpayer and spouse, who receives chief support from you and who qualifies as a dependent for Federal income tax purposes. *A head of family may claim $1,500 for each dependent excluding the one which qualifies you as head of family. Multiply number of dependents claimed by you by $1,500. Enter amount claimed",
        _b[TranslationConstant.LABEL_AGE_BLINDNESS_EXEMPTION] = "5. Age and Blindness Exemption - Multiply number of blocks checked by $1,500. Enter amount claimed. Note: No exemption allowed for age or blindness for dependents.",
        _b[TranslationConstant.LABEL_ADDITIONAL_DOLLAR_AMOUNT] = "7. Additional dollar amount of withholding per pay period if agreed to by your employer",
        _b[TranslationConstant.LABEL_EXEMPTION_CLAIM] = '8. If you meet the conditions set forth under the Service Member Civil Relief, as amended by the Military Spouses Residency Relief Act, and have no Mississippi tax liability, write "Exempt" on line 8. You must attach a copy of the Federal Form DD-2058 and a copy of your Military Spouse ID Card to this form so your employer can validate the exemption claim',
        _b[TranslationConstant.HSB_AGE_65_OR_OVER] = "Husband Age 65 or Older",
        _b[TranslationConstant.WIFE_AGE_65_OR_OVER] = "Wife Age 65 or Older",
        _b[TranslationConstant.SINGLE_AGE_65_OR_OVER] = "Single Age 65 or Older",
        _b[TranslationConstant.HSB_IS_BLIND] = "Husband is Blind",
        _b[TranslationConstant.WIFE_IS_BLIND] = "Wife is Blind",
        _b[TranslationConstant.SINGLE_AND_BLIND] = "Single and Blind",
        _b[TranslationConstant.LABEL_FEDERAL_FORM_DD] = "Federal Form DD-2058",
        _b[TranslationConstant.LABEL_MILITARY_SPOUSE_ID_CARD] = "Military Spouse ID Card",
        _b[TranslationConstant.OPTION_SINGLE] = "Single",
        _b[TranslationConstant.OPTION_MARRIED_UNEMPLOYED] = "Married, Spouse NOT employed",
        _b[TranslationConstant.OPTION_MARRIED_EMPLOYED] = "Married, Spouse IS employed",
        _b[TranslationConstant.OPTION_HOD] = "Head of Family",
        _b[TranslationConstant.NOT_APPLICABLE] = "N/A",
        _b[TranslationConstant.NOT_APPLICABLE_VALUE] = "na",
        _b[TranslationConstant.LABEL_EMPLOYEE_FORMS] = "Employee Forms",
        _b[TranslationConstant.LABEL_CODE] = "Code",
        _b[TranslationConstant.LABEL_NCCI] = "NCCI",
        _b[TranslationConstant.LABEL_STATE_NOT_NCCI] = "State (if not NCCI)",
        _b[TranslationConstant.ADD_WORKERS_COMP_CODES_TITLE] = "Workers Comp Codes",
        _b[TranslationConstant.EDIT_WORKERS_COMP_CODES_TITLE] = "Edit Workers Comp Codes",
        _b[TranslationConstant.EDIT_WORKERS_COMP_HEADING] = "Please change the fields below to edit.",
        _b[TranslationConstant.ADD_TIMECARD_ALERT_HEADING] = "TimeCard Alert",
        _b[TranslationConstant.HOURS] = "Hours",
        _b[TranslationConstant.FINAL_SUBMISSION_CONFIRMATION] = "Once submitted, you will not be able to make any changes.  Are you sure you would like to submit?",
        _b[TranslationConstant.ERROR_OCCURED] = "An Error Occured.",
        _b[TranslationConstant.MASTER_CLIENT_USERS_TITLE] = "Master Client Users",
        _b[TranslationConstant.LABEL_USER_INFO] = "User Info",
        _b[TranslationConstant.LABEL_WEB_SETTINGS] = "Web Settings",
        _b[TranslationConstant.LABEL_EDIT_USER] = "Edit User",
        _b[TranslationConstant.HIGH_LOW_PER_DIEM_LOC_TITLE] = "High Low per diem Locations",
        _b[TranslationConstant.EDIT_HIGH_LOW_PER_DIEM_LOC_TITLE] = "Edit High Low per diem Locations",
        _b[TranslationConstant.HIGH_LOW_PER_DIEM_FORM_HEADING] = "Select locations and dates for when per-diem is high.",
        _b[TranslationConstant.LABEL_COUNTRY] = "Country",
        _b[TranslationConstant.LABEL_LOW_PER_DIEM] = "Low per Diem",
        _b[TranslationConstant.LABEL_HIGH_PER_DIEM] = "High per Diem",
        _b[TranslationConstant.HIGH_LOW_PER_DIEM_TITLE] = "High Low per diem",
        _b[TranslationConstant.HEALTH_INSURANCE_RATE_TITLE] = "ACA Health Insurance Rate",
        _b[TranslationConstant.EDIT_HEALTH_INSURANCE_RATE_TITLE] = "Edit ACA Health Insurance Rate",
        _b[TranslationConstant.HEALTH_INSURANCE_AMOUNT] = "Health Insurance Amount",
        _b[TranslationConstant.LABEL_START_CITY] = "Start City",
        _b[TranslationConstant.LABEL_END_CITY] = "End City",
        _b[TranslationConstant.LABEL_TOTAL_MILES] = "Total Miles",
        _b[TranslationConstant.FORM_VALIDATION_FAILED] = "Form validation failed. Please fix the highlighted form errors and try again.",
        _b[TranslationConstant.EXPENSE_REPORT_FAILIURE] = "Saving the Expense Report failed. Please review and try again",
        _b[TranslationConstant.VENDOR_SUBMIT_FAILIURE] = "Adding the Vendor Payment failed. Please review and try again",
        _b[TranslationConstant.VENDOR_DELETED_SUCEESS] = "Deleted the Vendor Payment successfully.",
        _b[TranslationConstant.VENDOR_DELETED_FAILIURE] = "Deleting Vendor Payment failed.Please review and try again",
        _b[TranslationConstant.EMPLOYEE_ADDRESS_CHANGE_HEADING] = "Employee Address Change",
        _b[TranslationConstant.ARE_YOU_SURE_ADDRESS_CHANGE] = "Do you want to change the address?",
        _b[TranslationConstant.SAVE_EXPENSE] = "Save Expense",
        _b[TranslationConstant.SIGN_AND_SUBMIT_SUCCESSFULLY] = "Sign and Submit successfully.",
        _b[TranslationConstant.SELECT_ACCOUNT] = "Account",
        _b[TranslationConstant.SELECT_EMPLOYEE] = "Employee",
        _b[TranslationConstant.SELECT_ACCOUNT_AND_EMPLOYEE] = "Account and Employee",
        _b[TranslationConstant.SELECT_HIGH_LOW] = "High/Low",
        _b[TranslationConstant.SELECT_GSA] = "GSA",
        _b[TranslationConstant.SELECT_NONE] = "None",
        _b[TranslationConstant.SELECT_SUB_ACCOUNT] = "Sub Account",
        _b[TranslationConstant.SELECT_WEEKLY] = "Weekly",
        _b[TranslationConstant.SELECT_BIWEEKLY] = "Bi-Weekly",
        _b[TranslationConstant.SELECT_CAX] = "CA-X",
        _b[TranslationConstant.SELECT_CA_MPI] = "CA-MPI",
        _b[TranslationConstant.SELECT_CLIENT] = "Client",
        _b[TranslationConstant.SELECT_PASS_THROUGH] = "Pass Through",
        _b[TranslationConstant.SELECT_FLAT_RATE] = "Flat Rate",
        _b[TranslationConstant.SELECT_SIMPLE_ADMIN] = "Simple Admin Rate",
        _b[TranslationConstant.SELECT_BEST_BUY] = "Best-Buy Custom",
        _b[TranslationConstant.SELECT_FEDERAL_STATE] = "Federal/State",
        _b[TranslationConstant.SELECT_I9_VERIFIER] = "I9 Verifier",
        _b[TranslationConstant.ADD_CLIENT_HEADING] = "Please fill the form below to add a new client.",
        _b[TranslationConstant.EDIT_CLIENT_HEADING] = "Please fill the form below to edit the client.",
        _b[TranslationConstant.LABEL_MAS_ID] = "MAS ID",
        _b[TranslationConstant.LABEL_MEAL_PENALTIES] = "Meal Penalties Allowed",
        _b[TranslationConstant.LABEL_THINKWARE_ID] = "ThinkWare ID",
        _b[TranslationConstant.LABEL_THINKWARE_DIVISION_ID] = "ThinkWare Divison ID",
        _b[TranslationConstant.LABEL_CLIENT_IDENTIFIER] = "Client Identifier",
        _b[TranslationConstant.LABEL_MEAL_PENALTY_TYPE] = "Meal Penalty Type",
        _b[TranslationConstant.LABEL_TS_APPROVAL_METHOD] = "TS Approval Method",
        _b[TranslationConstant.LABEL_NON_WORKED_HOURS] = "Non Worked Hours Level",
        _b[TranslationConstant.LABEL_ALLOCATION_METHOD] = "Allocation Method",
        _b[TranslationConstant.LABEL_SHOW_RATE_WITH_POSITION] = "Show Rate with Position",
        _b[TranslationConstant.LABEL_ALLOW_VENDOR_PAYMENTS] = "Allow Vendor Payments",
        _b[TranslationConstant.LABEL_GL_CODE_FOR_POSAITION] = "GL Code for Position Allowed",
        _b[TranslationConstant.LABEL_DISPLAY_VENDOR_PAYMENT_DATE] = "Display Vendor Payment date",
        _b[TranslationConstant.LABEL_PAYROLL_BILLING_TYPE] = "Payroll Billing Type",
        _b[TranslationConstant.LABEL_1099_WORKER_TO_DARWIN] = "1099 Worker to Darwin",
        _b[TranslationConstant.LABEL_APPROVE_DETAILS] = "Approve Details",
        _b[TranslationConstant.LABEL_TAXABLE_BLIND_WAGES_CAPPED] = "Taxable Base Wages Capped",
        _b[TranslationConstant.LABEL_EMPLOYEE_TIMECARD_NOTIF] = "Employee timecard Notifications",
        _b[TranslationConstant.LABEL_CREDIT_CARD_NOTIF] = "Credit Card Notifications",
        _b[TranslationConstant.LABEL_ACA_HEALTH_INSURANCE] = "ACA Health Insurance",
        _b[TranslationConstant.LABEL_TIMECARD_PUNCH] = "Timecard punch data",
        _b[TranslationConstant.LABEL_DEDICATED_SUPERVISOR] = "Dedicated Supervisors",
        _b[TranslationConstant.LABEL_SUPERVISOR_NOTIFICATION] = "Supervisor Notifications",
        _b[TranslationConstant.LABEL_SUPERVISOR_ISOLATION] = "Supervisor Isolation",
        _b[TranslationConstant.LABEL_CLIENT_CONTRACTORS] = "Client Contractors",
        _b[TranslationConstant.LABEL_PAYREEL_CORDINATOR1] = "PayReel Coordinator 1",
        _b[TranslationConstant.LABEL_MISSING_BULK_ENTRY] = "Missing Bulk Entry Sign",
        _b[TranslationConstant.LABEL_BULK_ENTRY_EMPLOYEE_EDIT] = "Bulk Entry Employee Edit Timecards",
        _b[TranslationConstant.LABEL_BULK_ENTRY_EMPLOYEE_ADD_EDIT_EXPENSE_REPORT] = "Bulk Entry Employee Edit/Add Expense Report",
        _b[TranslationConstant.LABEL_PAYREEL_CORDINATOR2] = "PayReel Coordinator 2",
        _b[TranslationConstant.LABEL_GAURANTEED_HOURS_OT] = "Guaranteed Hours OT Eligible for CA",
        _b[TranslationConstant.LABEL_ACCOUNT_ALIAS] = "Account Alias",
        _b[TranslationConstant.LABEL_I9_REQUIRED] = "I-9 required",
        _b[TranslationConstant.LABEL_ACCOUNT_DESC_ALLOWED] = "Account Desc Allowed",
        _b[TranslationConstant.LABEL_IOS_ENABLED] = "iOS Enabled",
        _b[TranslationConstant.LABEL_ANDROID_ENABLED] = "Android Enabled",
        _b[TranslationConstant.LABEL_SUB_ACCOUNT_USED] = "Sub Account Used",
        _b[TranslationConstant.LABEL_SUB_ACCOUNT_ALIAS] = "Sub Account Alias",
        _b[TranslationConstant.LABEL_OVERTIME_CALCULATION] = "Overtime Calculations",
        _b[TranslationConstant.LABEL_SUB_ACCOUNT_DSC_ALLOWED] = "Sub Account Desc Allowed",
        _b[TranslationConstant.LABEL_INVOICE_AUTO_DELIVERY] = "Invoice Number Allowed",
        _b[TranslationConstant.LABEL_INVOICE_NUMBER_ALLOWED] = "Invoice Number Allowed",
        _b[TranslationConstant.LABEL_UDF1_USED] = "UDF 1 Used",
        _b[TranslationConstant.UDF1_ALIAS] = "UDF 1 Alias",
        _b[TranslationConstant.UDF2_ALIAS] = "UDF 2 Alias",
        _b[TranslationConstant.LABEL_UDF2_USED] = "UDF 2 Used",
        _b[TranslationConstant.UDF3_ALIAS] = "UDF 3 Alias",
        _b[TranslationConstant.LABEL_UDF3_USED] = "UDF 3 Used",
        _b[TranslationConstant.LABEL_VZ_SETUP] = "VZ Setup",
        _b[TranslationConstant.LABEL_ORACLE_SETUP] = "Oracle Setup",
        _b[TranslationConstant.LABEL_IMG_SETUP] = "IMG Setup",
        _b[TranslationConstant.LABEL_EMPLOYEMENT_AGREEMENT] = "Employment Agreements",
        _b[TranslationConstant.LABEL_MANUAL_PAYMENTS_ALLOWED] = "Manual Payments Allowed",
        _b[TranslationConstant.LABEL_MULTI_STATE_TIMESHEET] = "Multi State Timesheets",
        _b[TranslationConstant.LABEL_WORK_LOCATION_LEVEL] = "Work Location Level",
        _b[TranslationConstant.LABEL_PER_DEIM_CALCULATION] = "Per Diem Calculation Method",
        _b[TranslationConstant.LABEL_POSITION_CLASSIFICATION_REQUIRED] = "Position Classification Required",
        _b[TranslationConstant.LABEL_CLIENT_STATUS] = "Client Status",
        _b[TranslationConstant.LABEL_SCREENING_REQUIRED] = "Screening Required?",
        _b[TranslationConstant.LABEL_EVENT_TRACKING_LEVEL] = "Event Tracking Level",
        _b[TranslationConstant.LABEL_UPLOAD_I9_SECTION2] = "Upload I9 Section 2 documents",
        _b[TranslationConstant.LABEL_INVOICE_AUTO_DELIVERY] = "Invoice auto delivery",
        _b[TranslationConstant.LABEL_EMPLOYEE_TAKE_PICTURE] = "Employee able to take picture",
        _b[TranslationConstant.LABEL_PAYREEL_TEMPORARY_EMPLOYEE] = "PayReel Temporary Employee agreement",
        _b[TranslationConstant.LABEL_ALLOW_EMPLOYEE_AUTHORIZE_I9] = "Allow employee authorized I9 verifier?",
        _b[TranslationConstant.LABEL_OVERRIDE_PROJECT_APPROVERS] = "Override Project Approvers",
        _b[TranslationConstant.LABEL_ONBOARDING_REMINDERS] = "OnBoarding Reminders",
        _b[TranslationConstant.LABEL_EMPLOYEE_PAY_FREQUENCY] = "Employee Pay Frequency",
        _b[TranslationConstant.LABEL_CUSTOMIZE_CLIENT_USERS_UI] = "Customize Client Users UI Pages",
        _b[TranslationConstant.LABEL_ENABLE_MULTI_FACTOR_AUTHENTICATION] = "Enable Multi-Factor Authentication",
        _b[TranslationConstant.LABEL_USES_REQUISITION_NUMBER] = "Uses Requisition Numbers",
        _b[TranslationConstant.LABEL_SUB_ACCOUNT_MASK] = "Sub Account Mask NEP",
        _b[TranslationConstant.LABEL_PROJECT_DETAILS_ON_SEARCH_SCREEN] = "Show Project Details on Search Screen",
        _b[TranslationConstant.ADD_CLIENT_TITLE] = "Add Clients",
        _b[TranslationConstant.EDIT_CLIENT_TITLE] = "Edit Clients",
        _b[TranslationConstant.DID_YOU_MOVE] = "Did you move?",
        _b[TranslationConstant.VENDOR_UPADTE_SUCCESS] = "Updated vendor payment details successfully.",
        _b[TranslationConstant.CHANGE_EXPENSE_TYPE] = "Change Expense Type",
        _b[TranslationConstant.ACCEPT] = "Accept",
        _b[TranslationConstant.WORKERS_COMP_JOB_TITLE] = "Workers Comp Job Types",
        _b[TranslationConstant.WORKERS_COMP_JOB_HEADING] = "Please complete the field to create a Job Type",
        _b[TranslationConstant.LABEL_JOB_TYPE] = "Job Type",
        _b[TranslationConstant.MANAGE] = "Manage",
        _b[TranslationConstant.ADD_WORKERS_COMP_CODE_HEADING] = "Please select a value to add a Workers Comp Code",
        _b[TranslationConstant.REMOVE] = "Remove",
        _b[TranslationConstant.SIGN_AND_SUBMIT_SUCCESSFULLY] = "Sign and Submit successfully.",
        _b[TranslationConstant.GENERAL_LIABILITY_TITLE] = "General Liability Rate",
        _b[TranslationConstant.GENERAL_LIABILTY_PERCENT] = "General Liability Percent",
        _b[TranslationConstant.EDIT_GENERAL_LIABILITY_TITLE] = "Edit General Liability Rate",
        _b[TranslationConstant.DELETE_EXPENSE_FAILED] = "Deleting expense has been failed",
        _b[TranslationConstant.DELETE_EXPENSE_SUCCESS] = "Expense deleted successfully",
        _b[TranslationConstant.EMPLOYEE_STATE_CHANGE_HEADING] = "Employee State Change",
        _b[TranslationConstant.DID_YOU_MOVE_NEW_STATE] = "Did you move to a new state?",
        _b[TranslationConstant.ADD_UPDATE_DIRECT_DEPOSIT_HEADING] = "Add/Update direct deposit",
        _b[TranslationConstant.WELCOME_PAID_VIA_DIRECT_DEPOSIT] = "Welcome back! If you're paid via direct deposit, please let us know if your banking information has changed. We'd hate to see your payment delayed.",
        _b[TranslationConstant.PAYREEL_HANDBOOK_UPDATE_HEADING] = "PayReel Handbook Update",
        _b[TranslationConstant.SIGN_SAVE_PAYREEL_HANDBOOK] = "Please sign and save the latest PayReel Handbook.",
        _b[TranslationConstant.SIGN_NOW] = "Sign Now",
        _b[TranslationConstant.UPLOAD_NOW] = "Upload Now",
        _b[TranslationConstant.REMIND_ME_LATER] = "Remind Me Later",
        _b[TranslationConstant.SIGN_TEMP_HEADING] = "Sign Temp Agreement",
        _b[TranslationConstant.ENDEAVOR_TEMP_AGREEMENT_HEADING] = "Endeavor Temporary Agreement",
        _b[TranslationConstant.OK] = "OK",
        _b[TranslationConstant.PAYMENT_REMINDER_HEADING] = "Payment Reminder",
        _b[TranslationConstant.PAYREEL_PAYMENT_STATEMENT_HEADING] = "PayReel Payment Statement Update",
        _b[TranslationConstant.SIGN_SAVE_PAYMENT_STATEMENT] = "Please sign and save the latest PayReel Payment Statement",
        _b[TranslationConstant.SHTC_UPDATE_HEADING] = "SHTC Update",
        _b[TranslationConstant.ADD_DIVISIONS_BTN] = "Add Division",
        _b[TranslationConstant.DIVISION_DELETED] = "Division Deleted Successfully.",
        _b[TranslationConstant.DIVISION_NOT_DELETED] = "Division is not deleted. Please retry",
        _b[TranslationConstant.DIVISION_NOT_DELETED] = "Division is not deleted.",
        _b[TranslationConstant.AGENCY_FEE] = "Agency Fees",
        _b[TranslationConstant.AGENCY_PERCENTAGE] = "Agency %",
        _b[TranslationConstant.LABEL_SUB_ACCOUNT] = "Sub Account",
        _b[TranslationConstant.REVIEW_EMPLOYEE_I9_FORM_HEADING] = "Review Employee I-9 Form",
        _b[TranslationConstant.ACCEPTABLE_DOCUMENTS_TEXT] = "The list of acceptable documents for identification are on the last page of the form below.",
        _b[TranslationConstant.INSERT_ATTRIBUTE] = "Insert Attribute",
        _b[TranslationConstant.EXPRESSION] = "Expression",
        _b[TranslationConstant.ADD_NEW] = "Add New",
        _b[TranslationConstant.LABEL_SHORT_SSN] = "SSN",
        _b[TranslationConstant.PAYREEL_USERS_TITLE] = "PayReel Users",
        _b[TranslationConstant.ADD_PAYREEL_USER] = "Add PayReel User",
        _b[TranslationConstant.EDIT_PAYREEL_USER_TITLE] = "Edit PayReel Users",
        _b[TranslationConstant.ADD_PAYREEL_USER_HEADING] = "Please fill the form below to add a new PayReel User.",
        _b[TranslationConstant.EDIT_PAYREEL_USER_HEADING] = "Please fill the form below to edit a new PayReel User.",
        _b[TranslationConstant.ADMINISTRATIVE_ACCESS] = "Administrative Access",
        _b[TranslationConstant.COORDINATOR] = "Coordinator",
        _b[TranslationConstant.WORKER_DASHBOARD] = "1099 Worker Dashboard",
        _b[TranslationConstant.WORKER] = "Worker",
        _b[TranslationConstant.INVOICING_MISC_CHARGES_TITLE] = "Invoicing Misc. Charges",
        _b[TranslationConstant.LABEL_CHARGE_TYPE] = "Charge Type",
        _b[TranslationConstant.LABEL_DARWIN_CODE] = "Darwin Code",
        _b[TranslationConstant.MANAGE_INVOICE_LAYOUT_TITLE] = "Manage Invoice Layout",
        _b[TranslationConstant.LABEL_LAYOUT_NAME] = "Layout Name",
        _b[TranslationConstant.LABEL_INVOICE_TYPE] = "Invoice Type",
        _b[TranslationConstant.LABEL_ACTIVE] = "Active",
        _b[TranslationConstant.EMP_ELIGIBILITY_TITLE] = "Employee Eligibility",
        _b[TranslationConstant.EMP_NUMBER] = "Employee Number",
        _b[TranslationConstant.EMP_DATE_OF_HIRE] = "Employee Date of Hire",
        _b[TranslationConstant.EMP_DATE_OF_PSL_AVL] = "Employee Date PSL Available",
        _b[TranslationConstant.LOCATION_ST_ELIGIBLE] = "Location ST Eligible",
        _b[TranslationConstant.LOCATION_CITY_ELIGIBLE] = "Location City Eligible",
        _b[TranslationConstant.ADD_NEW_EMP_BTN] = "Add New Employee",
        _b[TranslationConstant.PAYROLL_ENTRY_CHANGE_HEADING] = "Payroll Entry Change",
        _b[TranslationConstant.SIGN_OFF] = "Sign Off",
        _b[TranslationConstant.PROCEED_TIMESHEET_ENTRY] = "Proceed to Timesheet Entry",
        _b[TranslationConstant.ADD_DAY] = "Add Day",
        _b[TranslationConstant.REMINDER_EMAIL_WORKER] = "Are you sure you want to send reminder email to this Worker",
        _b[TranslationConstant.ADD_RECEIPTS] = "Add Receipts",
        _b[TranslationConstant.EMPLOYEE_ACCRUAL_BALANCE_TITLE] = "Employee Accrual Balances",
        _b[TranslationConstant.LABEL_EMPLOYEE_NUMBER] = "Employee Number",
        _b[TranslationConstant.RUN_PROCEDURE_BTN] = "Run Procedure",
        _b[TranslationConstant.GOC] = "GOC",
        _b[TranslationConstant.APPROVING_SUPERVISOR] = "Approving Supervisor",
        _b[TranslationConstant.SECONDARY_SUPERVISOR] = "Secondary Supervisor",
        _b[TranslationConstant.ITEM_ID] = "Item Id",
        _b[TranslationConstant.INVOICE_CONTACT_NAME] = "Invoice Contact Name",
        _b[TranslationConstant.IMVOICE_CONTACT_EMAIL] = "Invoice Contact Email",
        _b[TranslationConstant.SAVE_DAY] = "Save Day",
        _b[TranslationConstant.LABEL_CONTACT] = "Contact",
        _b[TranslationConstant.LABEL_PHONE] = "Phone",
        _b[TranslationConstant.LABEL_ADDRESS] = "Address",
        _b[TranslationConstant.LABEL_ZIP1] = "Zip",
        _b[TranslationConstant.LABEL_BUDGET] = "Budget",
        _b[TranslationConstant.LABEL_PROJECT] = "Project",
        _b[TranslationConstant.LABEL_DIVISION] = "Division",
        _b[TranslationConstant.LABEL_NUMBER] = "Number",
        _b[TranslationConstant.PROJECT_TRACKING_UPLOAD_HEADING] = "Project Tracking Upload",
        _b[TranslationConstant.LABEL_CREATE_UPDATE_PROJECTS] = "Create/Update Projects",
        _b[TranslationConstant.REPORTS_TITLE] = "Reports",
        _b[TranslationConstant.LABEL_PAYROLL] = "PayReel",
        _b[TranslationConstant.LABEL_EXPENSE] = "Expense",
        _b[TranslationConstant.LABEL_VENDOR_PAYMENT] = "Vendor Payment",
        _b[TranslationConstant.CLIENT_REPORT_FORM_HEADING] = "Please click on a button below to generate a report",
        _b[TranslationConstant.PLEASE_ADD_AN_EXPENSE] = "Please add an Expense.",
        _b[TranslationConstant.VALIDATION_MESSAGE] = "Validation Message",
        _b[TranslationConstant.REGULAR_EXPRESSION] = "Regular Expression",
        _b[TranslationConstant.LABEL_APPROVE_TIMESHEET_TITLE] = "Approve Timesheets",
        _b[TranslationConstant.APPROVE_SELECTED_TIMESHEETS_BTN] = "Approve Selected Timesheets",
        _b[TranslationConstant.SELECT_TIMESHEET_TYPE] = "Please select Timesheet Status type below.",
        _b[TranslationConstant.LABEL_MY_TIMESHEETS] = "My Timesheets",
        _b[TranslationConstant.LABEL_ALL_TIMESHEETS] = "All Timesheets",
        _b[TranslationConstant.VENDOR_PAYMENTS_VZ_TITLE] = "Vendor Payment - Verizon",
        _b[TranslationConstant.PLEASE_ADD_AN_EXPENSE_RECEIPT] = "Please add a receipt.",
        _b[TranslationConstant.RELEASE_PAYROLL] = "Release Payroll",
        _b[TranslationConstant.GET_TIMESHEETS] = "Get Timesheets",
        _b[TranslationConstant.GET_EXPENSES] = "Get Expenses",
        _b[TranslationConstant.GET_VENDOR_PAYMENTS] = "Get Vendor Payments",
        _b[TranslationConstant.GET_MANUAL_PAYMENTS] = "Get Manual Payments",
        _b[TranslationConstant.INSURANCE_CERTIFICATE_NOT_FOUND] = "Certificate of insurance is not found !",
        _b[TranslationConstant.INSURANCE_CERTIFICATE_PENDING] = "Certificate of insurance pending for verification",
        _b[TranslationConstant.INSURANCE_CERTIFICATE_EXPIRED] = "Your certificate of insurance has expired. Please obtain a new certificate from your insurance company and upload by clicking link.",
        _b[TranslationConstant.CLIENT_NAME] = "Client Name",
        _b[TranslationConstant.CONTACT_NAME] = "Contact Name",
        _b[TranslationConstant.CONTACT_CITY] = "Contact City",
        _b[TranslationConstant.CONTACT_PHONE] = "Contact Phone",
        _b[TranslationConstant.CONTACT_EMAIL] = "Contact Email",
        _b[TranslationConstant.LABEL_MESSAGES] = "Messages",
        _b[TranslationConstant.EDIT_CLIENT] = "Edit Client",
        _b[TranslationConstant.WORK_ORDER] = "Work Order",
        _b[TranslationConstant.LABEL_DATE] = "Date",
        _b[TranslationConstant.LABEL_CLASSIFICATION] = "Classification",
        _b[TranslationConstant.LABEL_BUSINESS_NAME] = "Business Name",
        _b[TranslationConstant.LABEL_TIN] = "TIN",
        _b[TranslationConstant.PLEASE_FILL_THE_DETAILS_BELOW] = "Please fill in the details below",
        _b[TranslationConstant.UPLOAD_DOCUMENT] = "Upload Document",
        _b[TranslationConstant.LABEL_CREATED_DATE] = "Created Date",
        _b[TranslationConstant.LABEL_FORM] = "Form",
        _b[TranslationConstant.PENDING_DOCUMENT] = "Pending Required Documents",
        _b[TranslationConstant.NO_PENDING_DOCUMENT] = "There are no pending required documents.",
        _b[TranslationConstant.LABEL_UPLOAD] = "Upload",
        _b[TranslationConstant.PRECISION] = "Precision",
        _b[TranslationConstant.GET_CREDIT_CARD_TRANSACTIONS] = "Get Credit Card Transactions",
        _b[TranslationConstant.EMPLOYEE_ARBITRATION_AGGREMENT] = "Employee Arbitration Agreement",
        _b[TranslationConstant.INVOICE_SAVED] = "Invoice Layout saved successfully.",
        _b[TranslationConstant.INVOICE_UPDATED] = "Invoice Layout updated successfully",
        _b[TranslationConstant.SELECT_EXPORT] = "Select Export",
        _b[TranslationConstant.LABEL_MANUAL_PAYMENT_TITLE] = "View Manual Payment",
        _b[TranslationConstant.MANUAL_PAYMENT_STATUS_HISTORY_TITLE] = "Manual Payment Status History",
        _b[TranslationConstant.PAYROLL_RECORD_NOT_FOUND] = "No records found to Release the Payroll.",
        _b[TranslationConstant.READY_TO_RELEASE_PAYROLL] = "Please confirm that you are ready to Release your Payroll.",
        _b[TranslationConstant.CONFIRM] = "Confirm",
        _b[TranslationConstant.RELEASE_PAYROLL_TRANSMITTED_SUCCESSFULLY] = "Your payroll has been successfully transmitted to PayReel.",
        _b[TranslationConstant.DIVISION_ERROR] = "Name Must be Unique",
        _b[TranslationConstant.LABEL_APPROVE_MANUAL_PAYMENTS_TITLE] = "Approve Manual Payments",
        _b[TranslationConstant.MY_MANUAL_PAYMENTS] = "My Manual Payments",
        _b[TranslationConstant.ALL_MANUAL_PAYMENTS] = "All Manual Payments",
        _b[TranslationConstant.LABEL_APPROVE_MANUAL_PAYMENTS] = "Manual Payments",
        _b[TranslationConstant.LABEL_APPROVE_MANUAL_PAYMENT_BUTTON] = "Approve Selected Manual Payments",
        _b[TranslationConstant.RETURN_TO_PAYREEL] = "Return To PayReel",
        _b[TranslationConstant.CORP_CREATE_REPORTS_FORM_HEADING] = "Please use the below form to create reports",
        _b[TranslationConstant.EDIT_1099_WORKER_FORM_HEADING] = "Request for Taxpayer Information and Certification",
        _b[TranslationConstant.DOWNLOAD_DOCUMENT_SUCCESS] = "Document Downloaded Successfully",
        _b[TranslationConstant.UPLOAD_PROJECT_ERROR] = "The following list of errors were reported in the file validation. Please fix the file and upload again for validation.",
        _b[TranslationConstant.LABEL_APPROVE] = "Approve",
        _b[TranslationConstant.RETURN_TO_EMPLOYEE] = "Return To Employee",
        _b[TranslationConstant.DELETE_ALLOCATION] = "Delete Allocation",
        _b[TranslationConstant.COMPLETE_ALLOCATION] = "Complete Allocation",
        _b[TranslationConstant.ALLOCATION_DETAILS] = "Allocation Details",
        _b[TranslationConstant.HOURS_ALLOCATION] = "Hours Allocation",
        _b[TranslationConstant.ALLOCATION_COMPLETED] = "Allocation Complete.",
        _b[TranslationConstant.LABEL_MARK_AS_COMPLETE] = "Mark as Complete",
        _b[TranslationConstant.ARE_YOU_SURE_MARK_AS_COMPLETE] = "Are you sure you would like to mark this as complete?",
        _b[TranslationConstant.SUCCESSFULLY_MARKED_AS_COMPLETE] = "Successfully Marked as Complete.",
        _b[TranslationConstant.FAILED_MARK_AS_COMPLETE] = "Failed to Mark as Complete. Please retry!",
        _b[TranslationConstant.ONE_TIMESHEET_FOR_APPROVE_MSG] = "Please select atleast one timesheet to approve",
        _b[TranslationConstant.ONE_MANUAL_PAYMENT_FOR_APPROVE_MSG] = "Please select atleast one Manual payemnt entry to approve",
        _b[TranslationConstant.APPROVE_TIMESHEET_SUCCESS] = "Timesheet approved",
        _b[TranslationConstant.APPROVE_TIMESHEET_FAILURE] = "Timesheet approval failed",
        _b[TranslationConstant.APPROVE_MANUAL_PAYMENT_SUCCESS] = "Manual Payment approved",
        _b[TranslationConstant.APPROVE_MANUAL_PAYMENt_FAILURE] = "Manual Paymment approval failed",
        _b[TranslationConstant.INVOICING_HISTORY_TITLE] = "Invoicing History",
        _b[TranslationConstant.AR_EXPORT] = "AR Export",
        _b[TranslationConstant.INVOICE_STATUS] = "Invoice Status",
        _b[TranslationConstant.INVOICE_CREATION_DATE] = "Invoice Creation Date",
        _b[TranslationConstant.ADD_BACKUP_TXT] = "Please Add Backup",
        _b[TranslationConstant.VOID_INVOICE] = "Void Invoice",
        _b[TranslationConstant.LABEL_CLIENT_NAME] = "Client Name",
        _b[TranslationConstant.LABEL_AR_EXPORT] = "AR Export",
        _b[TranslationConstant.INVOICE_NUMBER] = "Invoice Number",
        _b[TranslationConstant.LABEL_APPROVE] = "Approve",
        _b[TranslationConstant.RETURN_TO_EMPLOYEE] = "Return To Employee",
        _b[TranslationConstant.DOCUMENT_CHANGES] = "Document Changes",
        _b[TranslationConstant.RETURN_TO_PAYREEL_SUCCESS_COMMENT] = "Successfully Returned to PayReel.",
        _b[TranslationConstant.RETURN_TO_PAYREEL_FAILURE_COMMENT] = "Returned to PayReel Failed. Please retry",
        _b[TranslationConstant.NEW_W4_COMPLETED] = "New W4 completed",
        _b[TranslationConstant.BUDGET_ISSUES] = "Budget Issues",
        _b[TranslationConstant.INVOICE_MISC_CHANGE_UPDATE_SUCCESS] = "Invoicing Misc. Charges updated successfully.",
        _b[TranslationConstant.INVOICE_MISC_CHANGE_SAVE_SUCCESS] = "Invoicing Misc. Charges saved successfully.",
        _b[TranslationConstant.LESS_AVAILABLE_ALLOCATION_HOURS] = "Hours should be less than or equal to available hours.",
        _b[TranslationConstant.HOURS_ALLOCATED_SUCCESSFULLY] = "Hours allocated successfully.",
        _b[TranslationConstant.ALLOCATION_DELETED_SUCCESSFULLY] = "Allocation deleted successfully.",
        _b[TranslationConstant.AGREEMENT_CHANGES] = "Agreement Changes",
        _b[TranslationConstant.RETURN_TIMESHEET] = "Return Timesheet",
        _b[TranslationConstant.RETURN_EXPENSE_REPORT] = "Return Expense Report",
        _b[TranslationConstant.RETURN_TIMESHEET_SUCCESS] = "Timesheet Comments added successfully",
        _b[TranslationConstant.RETURN_TIMESHEET_FAILURE] = "Comments adding failed. Please try again",
        _b[TranslationConstant.VIEW_EMPLOYEE_INFO_TITLE] = "View Employee",
        _b[TranslationConstant.LABEL_EMPLOYEE_INFO] = "Employee info",
        _b[TranslationConstant.LABEL_EMPLOYEE_POSITIONS] = "Positions",
        _b[TranslationConstant.LABEL_HOME_STATE] = "Home State",
        _b[TranslationConstant.LABEL_PRIMARY_WORK_STATE] = "Primary Work State",
        _b[TranslationConstant.LABEL_EXEMPT] = "Exempt",
        _b[TranslationConstant.LABEL_EXEMPT_JUSTIFICATION] = "Exempt Justification",
        _b[TranslationConstant.LABEL_EXEMPT_MAX_PER_WEEK] = "Exempt Max Days Per Week",
        _b[TranslationConstant.LABEL_EXEMPT_MAX_HOURS_PER_DAY] = "Exempt Max Hours Per Day",
        _b[TranslationConstant.LABEL_PROCESS_HOURS_AS_CALIFORNIA] = "Process Hours as California",
        _b[TranslationConstant.LABEL_NON_WORKED_HRS_ALLOWED] = "Non Worked Hours Allowed",
        _b[TranslationConstant.LABEL_WEB_USER_STATUS] = "Web User Status",
        _b[TranslationConstant.VOIDED_INVOICES] = "Voided Invoices",
        _b[TranslationConstant.RETURN_TO_VENDOR] = "Return to Vendor",
        _b[TranslationConstant.ACCOUNT_UPDATED_SUCCESS] = "Account Updated Successfully",
        _b[TranslationConstant.ACCOUNT_UPDATE_FAILED] = "Account Updation Failed",
        _b[TranslationConstant.ALLOCATION_COMPLETED_SUCCESSFULLY] = "Allocation completed successfully.",
        _b[TranslationConstant.LABEL_SHORT_SSN_LAST_FOUR] = "SSN (Last 4)",
        _b[TranslationConstant.LABEL_SUPERVISOR] = "Supervisor",
        _b[TranslationConstant.LABEL_BACKUP_SUPERVISOR] = "Backup Supervisor",
        _b[TranslationConstant.LABEL_REQUIRED_WORK_DESCRIPTION] = "Require Work Description",
        _b[TranslationConstant.LABEL_REQUIRED_WORK_LOCATION] = "Work Location Required",
        _b[TranslationConstant.LABEL_DEFAULT_WORK_LOCATION] = "Default Work Location",
        _b[TranslationConstant.EMPLOYEE_COMMENTS_TITLE] = "Employee Comments",
        _b[TranslationConstant.ACCOUNT_CHANGE_HAEDING] = "Account Change",
        _b[TranslationConstant.APPROVE_VENDOR_PAYMENT_FAILURE] = "Vendor Payment approval failed",
        _b[TranslationConstant.APPROVE_VENDOR_PAYMENT_SUCCESS] = "Vendor Payment approved",
        _b[TranslationConstant.ONE_VENDOR_PAYMENT_FOR_APPROVE_MSG] = "Please select atleast one Vendor Payment to approve",
        _b[TranslationConstant.LABEL_STATE_CITY_ZIP] = "State/City/Zip",
        _b[TranslationConstant.SEXUAL_HARASSMENT_CERTIFICATES] = "Sexual Harassment Certificates",
        _b[TranslationConstant.EXPORT_GRID] = "Export Grid",
        _b[TranslationConstant.RETURN_TO_VENDOR_SUCCESS_COMMENT] = "Successfully Returned to Vendor.",
        _b[TranslationConstant.RETURN_TO_VENDOR_FAILURE_COMMENT] = "Returned to Vendor Failed. Please retry",
        _b[TranslationConstant.VENDOR_PAYMENT_STATUS_HISTORY] = "Vendor Payment Status History",
        _b[TranslationConstant.RETURN_TO_NEXT_FORM] = "Next Form",
        _b[TranslationConstant.CORP_BTN_CREATE_REPORTS_FORM_HEADING] = "Please use the below button to create report",
        _b[TranslationConstant.SEARCH_EMPLOYEE_TITLE] = "Search Employees",
        _b[TranslationConstant.LABEL_EMPLOYEE_ID] = "Employee Id #",
        _b[TranslationConstant.RECHECK_RECORDS] = "Recheck Records",
        _b[TranslationConstant.SIGN_AND_SUBMIT_PRE_COMMENT_TXT] = 'By clicking "Sign and Submit" you verify this ',
        _b[TranslationConstant.SIGN_AND_SUBMIT_POST_COMMENT_TXT] = " is correct.",
        _b[TranslationConstant.EXPENSE_REPORT_TITTLE] = "Expense Report",
        _b[TranslationConstant.ALLOCATION] = "Allocation",
        _b[TranslationConstant.ADD_ALLOCATION] = "Add Allocation",
        _b[TranslationConstant.RETURN_TO_EMPLOYEE_SUCCESS_COMMENT] = "Successfully Returned to Employee.",
        _b[TranslationConstant.RETURN_TO_EMPLOYEE_FAILURE_COMMENT] = "Returned to Employee Failed. Please retry",
        _b[TranslationConstant.VENDOR_PAYMENT_RECALLED_SUCEESS] = "Vendor Payment has been Recalled",
        _b[TranslationConstant.VENDOR_PAYMENT_RECALLED_FAILURE] = "Vendor Payment Recall has been failed",
        _b[TranslationConstant.ALLOCATION_ADDED_SUCCESSFULLY] = "Allocation added successfully.",
        _b[TranslationConstant.INVALID_LOGIN_HEADING] = "Invalid Login",
        _b[TranslationConstant.SSN_OR_FEIN] = "FEIN or Social Security Number",
        _b[TranslationConstant.EDIT_DDN] = "Direct Deposit Authorization",
        _b[TranslationConstant.UPLOAD_VOIDED_CHECK] = "Uploaded Voided Check",
        _b[TranslationConstant.MANAGE_UN_SIGNED_TIMESHEETS_TITLE] = "Employee Timesheets",
        _b[TranslationConstant.LABEL_ALL_PROJECTS] = "All Projects",
        _b[TranslationConstant.LABEL_ALL_DATES] = "All Dates",
        _b[TranslationConstant.DATE_WORKED] = "Date Worked",
        _b[TranslationConstant.LABEL_TIMECARD_PUNCH_DATA] = "Timecard Punch Data",
        _b[TranslationConstant.ADD_NEW_RECORD] = "Add New Record",
        _b[TranslationConstant.LABEL_CHECK] = "Check",
        _b[TranslationConstant.LABEL_FULLTIME] = "Full Time",
        _b[TranslationConstant.LABEL_VARIABLE_TO_FULLTIME] = "Variable to Full Time",
        _b[TranslationConstant.LABEL_VARIABLE_HOURS] = "Variable Hour",
        _b[TranslationConstant.LABEL_PART_TIME] = "Part Time",
        _b[TranslationConstant.LABEL_NOT_INVITED_TO_ENROLL] = "Not Invited to enroll",
        _b[TranslationConstant.LABEL_INVITED_TO_ENROLL] = "Invited to enroll",
        _b[TranslationConstant.GRACE_PERIOD_EMPLOYMENT_AGREEMENT] = "Allow Grace Period for submit Employment Agreement",
        _b[TranslationConstant.LABEL_EMPLOYEE_ACA_STATUS] = "Employee ACA Status",
        _b[TranslationConstant.LABEL_VISA_TAX_EXEMPT] = "VISA tax exempt",
        _b[TranslationConstant.LABEL_EMPLOYEE_PAYMENT_SELECTION] = "Employee Payment Selection",
        _b[TranslationConstant.LABEL_BENEFITS_ELIGIBILITY_DATE] = "Benefits Eligibility Date",
        _b[TranslationConstant.LABEL_ADMINISTRATIVE_PERIOD] = "Administrative Period",
        _b[TranslationConstant.LABEL_ACA_HEALTH_INSURANCE_ENROLLED] = "ACA health Insurance - Enrolled",
        _b[TranslationConstant.LABEL_RETIREMENT_BENEFIT_ELIGIBILITY] = "Retirement Benefit - Eligibility",
        _b[TranslationConstant.LABEL_EDIT_TIMESHEETS] = "Edit Timesheets",
        _b[TranslationConstant.LABEL_EMPLOYEE_TYPE] = "Employee Type",
        _b[TranslationConstant.RATE_TYPE_WEEKLY_GUARANTEE] = "Weekly (guarantee)",
        _b[TranslationConstant.RATE_PER_HOUR] = "Rate per Hour",
        _b[TranslationConstant.RATE_PER_DAY] = "Rate per Day",
        _b[TranslationConstant.RATE_PER_WEEK] = "Rate per Week",
        _b[TranslationConstant.LABEL_TREAT_POSITION_AS_EXEMPT] = "Treat position as Exempt",
        _b[TranslationConstant.LABEL_DAILY_OVERTIME] = "Daily Overtime",
        _b[TranslationConstant.LABEL_DAILY_LENGTH] = "Day Length",
        _b[TranslationConstant.LABEL_DAILY_DOUBLE_TIME] = "Daily Double Time",
        _b[TranslationConstant.TEMP_PART_TIME] = "Temp/Part Time",
        _b[TranslationConstant.WEB_SETTINGS_RULE_TITLE] = "Username and Password must follow the following rules :",
        _b[TranslationConstant.EDIT_EMPLOYEE] = "Edit Employee",
        _b[TranslationConstant.EMPLOYEE_INFO_UPDATED_SUCCESSFULLY] = "Successfully updated Employee",
        _b[TranslationConstant.EMPLOYEE_INFO_UPDATE_FAILED] = "Employee update Failed. Please retry",
        _b[TranslationConstant.EDIT_DDA_FORM_FAILURE] = "DDA Details are not saved",
        _b[TranslationConstant.EDIT_DDA_FORM_SUCCESS] = "DDA Details has been saved successfully",
        _b[TranslationConstant.LABEL_WEEK_END] = "Week Ending",
        _b[TranslationConstant.APPROVE_EXPANSE_REPORT_SUCCESS] = "Approve Expense Report Successful",
        _b[TranslationConstant.APPROVE_EXPANSE_REPORT_FAILURE] = "Approve Expense Report Failure",
        _b[TranslationConstant.ONE_EXPENSE_REPORT_FOR_APPROVAL_MSG] = "Please select atleast one Expense report to approve",
        _b[TranslationConstant.INV_HISTORY_SUCCESS_MSG] = "Invoice Saved Successfully!",
        _b[TranslationConstant.INV_HISTORY_FAILED_MSG] = "Invoice Save Failed!",
        _b[TranslationConstant.INV_LAYOUT_SAVE_SUCESS] = "Invoice Layout saved successfully.",
        _b[TranslationConstant.INV_LAYOUT_UPDATE_SUCESS] = "Invoice Layout updated successfully.",
        _b[TranslationConstant.INV_HISTORY_PAID_MSG] = "Invoice paid successfully",
        _b[TranslationConstant.INV_HISTORY_UNPAID_MSG] = "Invoice unpaid successfully",
        _b[TranslationConstant.LABEL_SELECT_EXPORT] = "Select Export",
        _b[TranslationConstant.LABEL_COMPANY_CODE] = "Company Code",
        _b[TranslationConstant.LABEL_BUSINESS_PHONE_NUMBER] = "Business Phone Number",
        _b[TranslationConstant.LABEL_CELL_PHONE_NUMBER] = "Cell Phone Number",
        _b[TranslationConstant.LABEL_MANAGER] = "Manager",
        _b[TranslationConstant.LABEL_CLIENT_WORKER] = "Client Worker",
        _b[TranslationConstant.LABEL_SUPERVISOR_REPORT_ACCESS] = "Supervisor Report Access",
        _b[TranslationConstant.APPROVE_CREDIT_CARD_TRANSACTIONS] = "Approve Credit Card Transaction",
        _b[TranslationConstant.MY_CREDIT_CARD_TRANSACTIONS] = "My Credit Card Transactions",
        _b[TranslationConstant.ALL_CREDIT_CARD_TRANSACTIONS] = "All Credit Card Transactions",
        _b[TranslationConstant.INVOICE_LEVEL_GROUPING] = "Invoice Level Grouping",
        _b[TranslationConstant.MAPPING_FIELDS] = "Mapping Fields",
        _b[TranslationConstant.LABEL_TREAT_POSITION_EXEMPT] = "Treat position as Exempt",
        _b[TranslationConstant.LABEL_DAILY_OVERTIME] = "Daily Overtime",
        _b[TranslationConstant.LABEL_DAILY_DOUBLETIME] = "Daily Double Time",
        _b[TranslationConstant.LABEL_RATE_PER_HOUR] = "Rate per Hour",
        _b[TranslationConstant.LABEL_RATE_PER_DAY] = "Rate per Day",
        _b[TranslationConstant.LABEL_RATE_PER_WEEK] = "Rate per Week",
        _b[TranslationConstant.LABEL_DAY_WORKED_FROM] = "Day Worked(From):",
        _b[TranslationConstant.LABEL_DAY_WORKED_TO] = "Day Worked(To):",
        _b[TranslationConstant.READ_TRAVEL_POLICY] = "I have read/received travel policy",
        _b[TranslationConstant.PROCEES_TO_TIMESHEET_ENTRY] = "Proceed to Timesheet Entry",
        _b[TranslationConstant.DIRECT_DEPOSIT_UPLOAD_FAILURE_TITLE] = "Employee Direct deposit file didn't upload on thinkware because of following reasons",
        _b[TranslationConstant.DIRECT_DEPOSIT_UPLOAD_INFO_TITLE] = "Employee Direct Deposit",
        _b[TranslationConstant.LABEL_MONTH] = "Month",
        _b[TranslationConstant.LABEL_YEAR] = "Year",
        _b[TranslationConstant.SUCCESS_DELETE_TIMECARD] = "Timecard deleted successfully.",
        _b[TranslationConstant.ARE_YOU_SURE_DELETE_RECORD] = "Are you sure you want to delete the record?",
        _b[TranslationConstant.SUCCESS_INSERT_TIMECARD] = "Timecard inserted successfully.",
        _b[TranslationConstant.PREVIOUS_WEEKEND_ERROR] = "Previous weekending timecard can not be inserted.",
        _b[TranslationConstant.CREDIT_CARD_TRANSACTIONS_APPROVAL_SUCCESSFUL] = "Successfully Approved",
        _b[TranslationConstant.CREDIT_CARD_TRANSACTIONS_APPROVAL_FAILED] = "Approval Failed",
        _b[TranslationConstant.LABEL_EMPLOYEE_USER_NAME_EXISTS] = "Employee with same username already exist.",
        _b[TranslationConstant.EMPLOYEE_REPEAT_I9_HEADING] = "Employee Repeat I9",
        _b[TranslationConstant.EMPLOYEE_REPEAT_FORM_HEADING] = "Use this form to update the client name",
        _b[TranslationConstant.PREVIOUS_CLIENT_EMPLOYEE_REPEAT] = "Please check client name for which you are filing up timesheet",
        _b[TranslationConstant.PREVIOUS_EMPLOYEE_CLIENT_NAME] = "Please provide the name of the client for which you are going to work",
        _b[TranslationConstant.VALIDATE_UPLOADED_FILE] = "Validate Uploaded File",
        _b[TranslationConstant.INVALID_EMPLOYEE_ID] = "Invalid Employee ID.",
        _b[TranslationConstant.SUCCESS_UPDATE_TIMECARD] = "Timecard updated successfully.",
        _b[TranslationConstant.SYSTME_FIELD_SUBMITTED_SUCCESSFULLY] = "System Fields Submitted Successfully.",
        _b[TranslationConstant.LABEL_WORKER_INFORMATION] = "Worker Information",
        _b[TranslationConstant.VOID_INV_SUCCESS] = "Invoice Voided Successfully!",
        _b[TranslationConstant.VOID_INV_FAILED] = "Invoice Voided Failed!",
        _b[TranslationConstant.VOID_CONFIRM_DAILOG] = "To Void an Invoice",
        _b[TranslationConstant.LABEL_NON_WORKED_HOURS_CODES_TITLE] = "Non Worked Hours Codes",
        _b[TranslationConstant.LABEL_EARNINGS_CODE] = "Earnings Code",
        _b[TranslationConstant.RADIO_OPTION_VA] = "VA",
        _b[TranslationConstant.RADIO_OPTION_HO] = "HO",
        _b[TranslationConstant.RADIO_OPTION_TD] = "TD",
        _b[TranslationConstant.RADIO_OPTION_JD] = "JD",
        _b[TranslationConstant.RADIO_OPTION_AP] = "AP",
        _b[TranslationConstant.RADIO_OPTION_MP] = "MP",
        _b[TranslationConstant.RADIO_OPTION_HR] = "HR",
        _b[TranslationConstant.RADIO_OPTION_SL] = "SL",
        _b[TranslationConstant.LABEL_WORK_LOCATIONS] = "Work Locations",
        _b[TranslationConstant.ADD_WORK_LOCATION_BTN] = "Add Work Location",
        _b[TranslationConstant.LABEL_AGREE] = "Agree",
        _b[TranslationConstant.ALL_EVENTS_PROJECTS] = "All Events/Projects",
        _b[TranslationConstant.LABEL_EVENT_PROJECT] = "Event/Project",
        _b[TranslationConstant.ALL_POSITIONS] = "All Positions",
        _b[TranslationConstant.ALL_EMPLOYEES] = "All Employees",
        _b[TranslationConstant.ALL_VENDORS] = "All Vendors",
        _b[TranslationConstant.LABEL_MONTH_BILLED] = "Month Billed",
        _b[TranslationConstant.I9_REVERICATION_POPUP_TEXT] = "Please fill I-9 reverification Form.",
        _b[TranslationConstant.PAYREEL_USER_INFO_TAB] = "PayReel User Info",
        _b[TranslationConstant.EXPENSE_CODES_TITILE] = "Expense Codes",
        _b[TranslationConstant.LABEL_MISC_ITEM_CODE] = "Misc Item Code",
        _b[TranslationConstant.LABEL_RECEIPTS_NEEDED] = "Receipts Needed",
        _b[TranslationConstant.LABEL_TAXABLE] = "Taxable",
        _b[TranslationConstant.LABEL_GL_ACCOUNT_NUMBER] = "GL Account Number",
        _b[TranslationConstant.RADIO_EXPREIMB_C] = "EXPREIMB-C",
        _b[TranslationConstant.RADIO_EQUIP_C] = "EQUIP-C",
        _b[TranslationConstant.LABEL_ADD_EMPLOYEE] = "Add Employee",
        _b[TranslationConstant.LABEL_SELECT] = "Select",
        _b[TranslationConstant.GENERAL_HEADING_FOR_W9_FORM] = "Documents (Please click W9 to below to begin the process)",
        _b[TranslationConstant.LABEL_DOCUMENTS] = "Documents",
        _b[TranslationConstant.INFORMATION_SAVED_SUCCESSFULLY] = "Information saved successfully",
        _b[TranslationConstant.INFORMATION_SAVE_FAILED] = "Information save failed",
        _b[TranslationConstant.DOWNLOAD_RECIEPTS] = "Download Receipts",
        _b[TranslationConstant.FAILED_INSERT_TIMECARD] = "Timecard insert failed!",
        _b[TranslationConstant.FAILED_UPDATE_TIMECARD] = "Timecard update failed!",
        _b[TranslationConstant.ADD_POSITION] = "Add Position",
        _b[TranslationConstant.LABEL_GL_CODE] = "GL Code",
        _b[TranslationConstant.LABEL_SUPERVISOR_POSITION] = "Supervisor Position",
        _b[TranslationConstant.LABEL_POSITION_CLASSIFICATION] = "Position Classification",
        _b[TranslationConstant.EDIT_POSITION] = "Edit Position",
        _b[TranslationConstant.RECIEPT_COMMENTS_SUCCESS] = "Returned To Employee successfully",
        _b[TranslationConstant.RECIEPTS_COMENTS_FAILURE] = "Comments adding failed. Please try again",
        _b[TranslationConstant.MIN_SEARCH_CRITERIA_ERROR] = "Minimum Search Criteria required",
        _b[TranslationConstant.LABEL_WORKED_DAY_START] = "Worked Day Start",
        _b[TranslationConstant.LABEL_WORKED_DAY_END] = "Worked Day End",
        _b[TranslationConstant.INACTIVATE_ALL_EMPLOYEES_PAGE_TITLE] = "Inactivate All Employees",
        _b[TranslationConstant.INACTIVATE_ALL_EMPLOYEES_FORM_HEADING_WARNING] = "Please read warning message, before you proceed.",
        _b[TranslationConstant.WARNING] = "Warning!",
        _b[TranslationConstant.INACTIVATE_ALL_EMPLOYEES_WARNING] = "Clicking 'Inactivate All Employees' will inactivate all employees for this client.",
        _b[TranslationConstant.EMAIL_REQUEST_FOR_1099_WORKER_DOCUMENTS] = "Email Request for 1099 Worker Documents",
        _b[TranslationConstant.EMAIL_REQUEST_FOR_1099_WORKER_DOCUMENTS_FORM_HEADING] = "Please enter the information below to launch email to 1099 Worker.",
        _b[TranslationConstant.EDIT_WORK_LOCATION_TITLE] = "Edit Work Location",
        _b[TranslationConstant.ADD_WORK_LOCATION_TITLE] = "Add Work Location",
        _b[TranslationConstant.EDIT_WORK_LOCATION_HEADING] = "Please change the fields below to edit the Work Location.",
        _b[TranslationConstant.ADD_WORK_LOCATION_HEADING] = "Please fill the form below to add a new Work Location.",
        _b[TranslationConstant.PAID_SICK_LEAVE_RULES_TITLE] = "Paid Sick Leave Rules",
        _b[TranslationConstant.ADD_NEW_RULE_BTN] = "Add New Rule",
        _b[TranslationConstant.IMPORT_RULES_BTN] = "Import Rules",
        _b[TranslationConstant.LABEL_EMPLOYEE_ELIGIBLE_DAYS] = "Employee Eligible Days",
        _b[TranslationConstant.LABEL_EMPLOYEE_ELIGIBLE_HOURS] = "Employee Eligible Hours",
        _b[TranslationConstant.LABEL_ACCRUAL_RATE_HOURS] = "Accrual Rate Hours",
        _b[TranslationConstant.LABEL_ACCRUAL_RATE_HOURS_WORKED] = "Accrual Hours Worked",
        _b[TranslationConstant.LABEL_ACCRUAL_BEGIN_DAYS] = "Accrual Begin Days",
        _b[TranslationConstant.LABEL_ACCRUAL_CAPS_HOURS] = "Accrual Caps Hours",
        _b[TranslationConstant.LABEL_PSL_ACCESSIBLE_DAYS] = "PSL Accessible Days of Employee",
        _b[TranslationConstant.LABEL_PSL_USAGE_CAPS_HOURS] = "PSL Usage Caps Hours",
        _b[TranslationConstant.LABEL_PSL_CARRY_OVER_HOURS] = "PSL Carryover Hours",
        _b[TranslationConstant.EMPLOYEE_ADDED_SUCCESSFULLY] = "Employee added successfully.",
        _b[TranslationConstant.EMPLOYEE_ADD_FAILED] = "Add Employee Failed. Please retry",
        _b[TranslationConstant.LABEL_BATCH] = "Batch",
        _b[TranslationConstant.ALL_BATCHES] = "All Batches",
        _b[TranslationConstant.MANAGE_DASHBOARD_PAGE_HEADING] = "1099 Worker Dashboard Status",
        _b[TranslationConstant.REQUIRED_DOCUMENTS_CHANGES_NOTES] = "Required document change notes",
        _b[TranslationConstant.STATE_AGREEMENT_DATE_TEXT] = "Since you have selected a work location of California, Minnesota, or New York, a new wage form will be updated automatically by the system on your behalf.",
        _b[TranslationConstant.EDIT_PAID_SICK_LEAVE_RULES] = "Edit Paid Sick Leave Rules",
        _b[TranslationConstant.ADD_PAID_SICK_LEAVE_RULES] = "Add Paid Sick Leave Rules",
        _b[TranslationConstant.EDIT_PAID_SICK_LEAVE_RULES_HEADING] = "Please change the fields below to edit Paid Sick Leave Rules",
        _b[TranslationConstant.ADD_PAID_SICK_LEAVE_RULES_HEADING] = "Please fill the form below to add a new Paid Sick Leave Rule",
        _b[TranslationConstant.LABEL_ACCRUAL_RATE_FOR_EMPLOYEE_HOURS_WORKED] = "Accrual Rate For Employee Hours Worked",
        _b[TranslationConstant.LABEL_ACCRUAL_BEGIN_DOE] = "Accrual Begin DOE",
        _b[TranslationConstant.EXPIRATION_DATE] = "Expiration Date",
        _b[TranslationConstant.LABEL_STATE_AGGREGATE] = "State Aggregate",
        _b[TranslationConstant.SICK_RULE_SAVED_SUCCESSFULY] = "Sick leave rule saved successfully.",
        _b[TranslationConstant.DUPLICATE_PAID_SICK_LEAVE_SAVE] = "This rule is already present.",
        _b[TranslationConstant.INVALID_PAID_SICK_LEAVE_SAVE] = "This state, city, zip location is not present in Slam location.",
        _b[TranslationConstant.CUSTOM_LABELS.FOR_COMMENT_REASON_IN_CHANGE_SELECTION] = "Please enter the reason for the change",
        _b[TranslationConstant.CUSTOM_LABELS.MANAGE_REQUIRED_DOCUMENTS] = "Manage Required Documents",
        _b[TranslationConstant.CUSTOM_LABELS.MANAGE_OPTIONAL_DOCUMENTS] = "Manage Optional Documents",
        _b[TranslationConstant.BATCH_RECORDS_TITLE] = "Batch Records",
        _b[TranslationConstant.BATCH_SELECTED_RECORDS] = "Batch Selected Records",
        _b[TranslationConstant.SEARCH_BATCH_SELECT] = "Please select batch.",
        _b[TranslationConstant.MANAGE_AR_RECORDS_TITLE] = "Manage AR Records",
        _b[TranslationConstant.ADD_SELECTED_RECORDS] = "Add Selected Records",
        _b[TranslationConstant.MANAGE_AP_RECORDS_TITLE] = "Manage AP Records",
        _b[TranslationConstant.MANAGE_PAYROLL_RECORDS_TITLE] = "Manage Payroll Records",
        _b[TranslationConstant.PAYROLL_BATCH_EXPORTS] = "Payroll Exports",
        _b[TranslationConstant.BATCH_EXPORTS_HEADING] = "Please click on below buttons to start exports.",
        _b[TranslationConstant.BATCH_EXPORTS] = "Batch Exports",
        _b[TranslationConstant.LABEL_WORK_DESCRIPTION] = "Work Description",
        _b[TranslationConstant.APPROVE_CREDIT_CARD_REPORT_SUCCESS] = "Approve Credit Card Successful",
        _b[TranslationConstant.APPROVE_CREDIT_CARD_REPORT_FAILURE] = "Approve Credit Card Failure",
        _b[TranslationConstant.AP_BATCH_EXPORTS] = "AP Exports",
        _b[TranslationConstant.AR_BATCH_EXPORTS] = "AR Exports",
        _b[TranslationConstant.UPLOAD_VERIZON_INGEST_FILE] = "Upload Verizon Ingest File(.xls file)",
        _b[TranslationConstant.EXPORT_ADDED_SUCCESSFULLY] = "Export added successfully.",
        _b[TranslationConstant.LABEL_NOTES] = "Notes",
        _b[TranslationConstant.LABEL_ARCHIVE] = "Archive",
        _b[TranslationConstant.ARE_YOU_SURE_DELETE_ITEM_CONFIRMATION] = "Are you sure you would like to Delete this Item?",
        _b[TranslationConstant.ARCHIVED_SUCCESSFULLY] = "Archived Successfully.",
        _b[TranslationConstant.VIEW_ARCHIVED_NOTES] = "View Archive",
        _b[TranslationConstant.ARCHIVED_NOTES_TITLE] = "Archived Notes",
        _b[TranslationConstant.EDIT_EMPLOYEE_NOTES_FORM_HEADING_TITLE] = "Please fill text area to add note.",
        _b[TranslationConstant.PERFOMANCE_COMPLIANCE_PAGE_TITLE] = "Performance Compliance",
        _b[TranslationConstant.ADD_ITEM] = "Add Item",
        _b[TranslationConstant.ENTER_PERFORMANCE_COMPLIANCE_ITEM] = "Enter Performance Compliance Item",
        _b[TranslationConstant.REPORT_BUTTON] = "Report",
        _b[TranslationConstant.SECONDARY_PROJECT] = "Secondary Project",
        _b[TranslationConstant.LABEL_MONTH_WORKED] = "Month Worked",
        _b[TranslationConstant.MSG_SELECT_EVENT_SECONDARY_PROJECT] = "Please select at least one Event/Project or Secondary Project!",
        _b[TranslationConstant.MSG_SELECT_EMPLOYEE_VENDOR] = "Please select atleast one Employee or Vendor!",
        _b[TranslationConstant.MSG_SELECT_WEEK_MONTH_STARTEND_DATE] = "Please select atleast one Week Ending Date or Month Worked or (Worked Day Start and Worked Day End)!",
        _b[TranslationConstant.ACCOUNT_DEFAULT_LABEL] = "Account",
        _b[TranslationConstant.SUB_ACCOUNT_DEFAULT_LABEL] = "Sub Account",
        _b[TranslationConstant.LABEL_COUNTY] = "County",
        _b[TranslationConstant.EXPORT_CLOSED_SUCCESSFULLY] = "Export closed successfully.",
        _b[TranslationConstant.EXPORT_REOPENED_SUCCESSFULLY] = "Export opened successfully.",
        _b[TranslationConstant.LEBEL_DATE_OF_HIRE] = "Date of Hire",
        _b[TranslationConstant.WORKERS_COMP_CODE_DELETED_SUCEESS] = "Workers Comp Code Deleted",
        _b[TranslationConstant.WORKERS_COMP_CODE_DELETED_FAILURE] = "Deleting Workers Comp Code has been failed",
        _b[TranslationConstant.JOB_TYPE_ADD_FAILURE] = "Job Type not added",
        _b[TranslationConstant.JOB_TYPE_UNIQUE_MESSAGE] = "Job Type must be unique",
        _b[TranslationConstant.SURE_TO_REMOVE_RECORD_FROM_EXPORT] = "Are you sure you would like to remove this record from Export?",
        _b[TranslationConstant.DARWIN_INFO_TITLE] = "Darwin Information",
        _b[TranslationConstant.GRID_LABEL_ADDRESSES] = "Addresses",
        _b[TranslationConstant.GRID_LABEL_PAYABLES] = "Payables",
        _b[TranslationConstant.GRID_LABEL_PAYSTUBS] = "Paystubs",
        _b[TranslationConstant.ADD_VENDOR] = "Add Vendor",
        _b[TranslationConstant.VENDOR] = "VENDOR",
        _b[TranslationConstant.LABEL_VENDOR_TYPE] = "Vendor Type",
        _b[TranslationConstant.BUNDLE_PAY_OR_INDEPENDENT_CONTRACTOR] = "Bundle Pay or Independent Contractor",
        _b[TranslationConstant.INDEPENDENT_CONTRACTOR_PAYING_FOR_WC] = "Independent Contractor Paying for WC",
        _b[TranslationConstant.SSN_OR_EIN] = "SSN/EIN",
        _b[TranslationConstant.LABEL_DEBIT_EXTENDS] = "Debits/Extends",
        _b[TranslationConstant.LABEL_MANUAL_PAYMENTS] = "Manual Payments",
        _b[TranslationConstant.DOWNLOAD_PDF] = "Download PDF",
        _b[TranslationConstant.SUCCESSFULLY_APPROVED_BULK_REPORT] = "Your request has been successfully queued for processing. You will receive an email notification with the processing results.",
        _b[TranslationConstant.ALL_WORK_ORDERS] = "All Work Orders",
        _b[TranslationConstant.ORDER_NUMBER] = "Order #",
        _b[TranslationConstant.RETRIEVE_JOB] = "Retrieve Job",
        _b[TranslationConstant.ADD_TIME_SHEET] = "Add Timesheet",
        _b[TranslationConstant.ROUTING_NUMBER] = "Routing Number",
        _b[TranslationConstant.PUNCH_TYPE_ERROR] = "Punch Type is Required",
        _b[TranslationConstant.NO_DATE_ERROR] = "Enter a valid Date",
        _b[TranslationConstant.USERNAME_LABEL] = "Username should be between 8 to 50 characters long and without whitespace",
        _b[TranslationConstant.DOWNLOAD_REPORT_SUCCESS] = "Report Downloaded Successfully",
        _b[TranslationConstant.AGENCY_FEE_VENDOR] = "If it is an agency fee Vendor?",
        _b[TranslationConstant.ADMIN_NO_FEE_LABEL] = "No agency/admin fee",
        _b[TranslationConstant.AGENCY_FEE_PERCENT_LABEL] = "Enter agency fee percentage",
        _b[TranslationConstant.INACTIVATE_CONFIRM_MESSAGE] = "Are you sure you would like to InActive this Item?",
        _b[TranslationConstant.ACTIVATE_CONFIRM_MESSAGE] = "Are you sure you would like to make this Item active?",
        _b[TranslationConstant.BULK_IMPORT_DOWNLOAD] = "Bulk Import Download",
        _b[TranslationConstant.RADIO_LABEL_INPROCESS] = "In Process",
        _b[TranslationConstant.ADD_MANUAL_PAYMENTS_BTN] = "Add Manual Payment",
        _b[TranslationConstant.SELECT_MANUAL_PAYMENTS_TYPE] = "Please select Manual Payment Status type.",
        _b[TranslationConstant.RECALCULATE_TIMESHEET_BTN] = "Recalculate Timesheet",
        _b[TranslationConstant.CONFIRM_REMOVE_EXPENSE] = "Are you sure you would like to remove this expense report?",
        _b[TranslationConstant.CONFIRM_REMOVE_TIMESHEET] = "Are you sure you would like to remove this timesheet?",
        _b[TranslationConstant.CONFIRM_REMOVE_VENDOR_PAYMENT] = "Are you sure you would like to remove this vendor payment?",
        _b[TranslationConstant.CONFIRM_REMOVE_MANUAL_PAYMENT] = "Are you sure you would like to remove this manual payment?",
        _b[TranslationConstant.LABEL_FILE] = "File",
        _b[TranslationConstant.HEADING_STATES_SETUP] = "States Setup",
        _b[TranslationConstant.ADD_NEW_LOCATION_HEADING] = "Please add Country, State, City and Zip you want to add to the work location below:",
        _b[TranslationConstant.LABEL_SHORT_STATE] = "State Short",
        _b[TranslationConstant.NEW_LOCATION_SUCCESS_HEADING] = "Combination of Country, State, City and Zip saved successfully.",
        _b[TranslationConstant.ADD_NEW_COUNTRY_STATE_CITY_BTN] = "Add New Country/State/City",
        _b[TranslationConstant.RECALCULATE_TIMESHEET_SUCCESS_MSG] = "Successfully Recalculated.",
        _b[TranslationConstant.PUNCH_TYPE_ERROR] = "Punch Type is Required",
        _b[TranslationConstant.NO_DATE_ERROR] = "Enter a valid Date",
        _b[TranslationConstant.ADD_TIME_SHEET] = "Add Time Sheet",
        _b[TranslationConstant.USERNAME_LABEL] = "Username should be between 8 to 50 characters long and without whitespace",
        _b[TranslationConstant.PAYSTUB_FORM_HEADING] = "Please select a date type and a date to download a pay stub",
        _b[TranslationConstant.LABEL_RECALCULATE_EXPENSE_REPORT] = "Recalculate Expense Report",
        _b[TranslationConstant.LABEL_UPDATE_DOCUMENT] = "Update Document",
        _b[TranslationConstant.LABEL_SEARCH_CREDIT_CARD_TRANSACTION] = "Search Credit Card Items",
        _b[TranslationConstant.PAYREEL_REPORTS] = "PayReel Reports",
        _b[TranslationConstant.CLIENT_REPORTS] = "Client Reports",
        _b[TranslationConstant.MINIMUM_WAGE_RATE_TYPE_FORM_HEADING] = "Select the State, county and/or city to add minimum wage rate",
        _b[TranslationConstant.LABEL_MINIMUM_WAGE_RATE] = "Minimum wage rate",
        _b[TranslationConstant.MINIMUM_WAGE_RATE_TITLE] = "Minimum Wage Rate",
        _b[TranslationConstant.EDIT_MINIMUM_WAGE_RATE_TITLE] = "Edit Minimum Wage Rate",
        _b[TranslationConstant.LABEL_LOCATION_OF_SERVICE] = "Location of Service",
        _b[TranslationConstant.LABEL_PRODUCTION_START_DATE] = "Production Start Date",
        _b[TranslationConstant.LABEL_PRODUCTION_END_DATE] = "Production End Date",
        _b[TranslationConstant.LABEL_AUTO_FILL_INOUT_TIMES] = "Auto Fill In/Out Times?",
        _b[TranslationConstant.LABEL_AUTO_FILL_MEAL_TIME] = "Auto Fill Meal Time?",
        _b[TranslationConstant.CREATE_WORK_ORDER] = "Create Work Order",
        _b[TranslationConstant.MARK_COMPLETE] = "Mark Complete",
        _b[TranslationConstant.EMAIL_PDF] = "Email PDF",
        _b[TranslationConstant.ADDENDUM] = "Addendum",
        _b[TranslationConstant.RETURN_TO_WORK_ORDER] = "Return To Work Order",
        _b[TranslationConstant.LABEL_DATES_OF_PRODUCTION] = "Dates of Production",
        _b[TranslationConstant.LABEL_EMAIL_TO] = "Email To",
        _b[TranslationConstant.LABEL_EMAIL_CC] = "Email Cc",
        _b[TranslationConstant.LABEL_EMAIL_FROM] = "Email From",
        _b[TranslationConstant.LABEL_SUBJECT] = "Subject",
        _b[TranslationConstant.LEBEL_BODY] = "Body",
        _b[TranslationConstant.SEND_EMAIL_BTN] = "Send Email",
        _b[TranslationConstant.GENERATE_TIME_SHEETS] = "Generate Time Sheets",
        _b[TranslationConstant.GENERATE_TIME_SHEETS_SUCCESS] = "Timesheets have been created",
        _b[TranslationConstant.GENERATE_TIME_SHEETS_FAILURE] = "Generate Timesheets has been failed",
        _b[TranslationConstant.ADDENDUM_TYPE] = "Addendum Type",
        _b[TranslationConstant.VIEW_WORK_ORDER_REPORT] = "View Work Orders Report",
        _b[TranslationConstant.VIEW_WORK_ORDER_ADDENDUM] = "Work Order Addendum",
        _b[TranslationConstant.PAYROLL_ALLOCATION] = "Payroll Allocation",
        _b[TranslationConstant.LABEL_TIN_VALIDATED] = "TIN Validated",
        _b[TranslationConstant.ADD_TIME_VALIDATION] = "Please add time",
        _b[TranslationConstant.LABEL_GROSS_PAYMENT] = "Gross Payment",
        _b[TranslationConstant.LABEL_SUBMIT_PAYMENT] = "Submit Payment",
        _b[TranslationConstant.LABEL_SAVE_PAYMENT] = "Save Payment",
        _b[TranslationConstant.SELECT_VENDOR] = "Vendor",
        _b[TranslationConstant.LABEL_EVENT_START_DATE] = "Event Start Date",
        _b[TranslationConstant.LABEL_EVENT_END_DATE] = "Event End Date",
        _b[TranslationConstant.MARKET] = "Market",
        _b[TranslationConstant.FUTA_RATES] = "FUTA Rates",
        _b[TranslationConstant.SUTA_RATES] = "SUTA Rates",
        _b[TranslationConstant.WC_RATES] = "WC Rates",
        _b[TranslationConstant.LABEL_NCCI_FOR_WC] = "NCCI for WC",
        _b[TranslationConstant.LABEL_MANDATORY_DIRECT_DEPOSIT] = "Mandatory Direct Deposit",
        _b[TranslationConstant.TAXABLE_BASE_WAGES_CAPS] = "Taxable Base Wages Caps",
        _b[TranslationConstant.EXPENSE_RATE_TITLE] = "Expense Rate",
        _b[TranslationConstant.EXPENSE_RATE_PERCENT] = "Expense Percent",
        _b[TranslationConstant.EQUIPMENT_RATE_TITLE] = "Equipment Rate",
        _b[TranslationConstant.EQUIPMENT_RATE_PERCENT] = "Equipment Percent",
        _b[TranslationConstant.WORKER_COMP_CODE_UPDATED] = "Workers Comp Codes updated successfully.",
        _b[TranslationConstant.WORKER_COMP_CODE_ADDED] = "Workers Comp Codes added successfully.",
        _b[TranslationConstant.IMPORT_NEW_RULE_HEADING] = "Please Choose a Bulk Timesheet File to Upload",
        _b[TranslationConstant.IMPORT_NEW_RULE_TITLE] = "Paid Sick Leave Rules Bulk Upload",
        _b[TranslationConstant.VALIDATION_FAILED] = "Validation Failed",
        _b[TranslationConstant.DHS_EXAMINE_DOCUMENTS] = "Check here if you used an alternative procedure authorized by DHS to examine documents.",
        _b[TranslationConstant.REVIEWED_DOCUMENT_TEXT] = "Have you reviewed the document in person?",
        _b[TranslationConstant.ADDITIONAL_INFOMATION_LABEL] = "Additional Information",
        _b[TranslationConstant.EARNING_CODE_PAYMENT_TITLE] = "Manual Payment Earning Code",
        _b[TranslationConstant.SELECTED_RECORD_ADDED_SUCCESSFULLY] = "Selected records added successfully",
        _b[TranslationConstant.LABEL_ADMIN_PERCENT] = "Admin Percent",
        _b[TranslationConstant.ADMIN_RATE_TITLE] = "Admin Rate",
        _b[TranslationConstant.VENDOR_PAYMENT_RATE_TITLE] = "Vendor Payment Rate",
        _b[TranslationConstant.VENDOR_PAYMENT_BILLING_RATE] = "Vendor Billing Rate",
        _b[TranslationConstant.POSTPONE_BTN] = "Postpone",
        _b[TranslationConstant.CONTINUE_FOR_APPROVAL] = "Continue For Approval",
        _b[TranslationConstant.NO_FURTHER_ACTION_BTN] = "No Further Action",
        _b[TranslationConstant.TOTAL] = "Total",
        _b[TranslationConstant.EXPORT_SENT_TO_DARWIN] = "AP export has been sent to Darwin.",
        _b[TranslationConstant.SUTA_MARKUP_RATE_TITLE] = "SUTA Markup",
        _b[TranslationConstant.GENERAL_LIABILITY_MARKUP] = "General Liability Markup",
        _b[TranslationConstant.LABEL_MARKUP_PERCENT] = "Markup Percent",
        _b[TranslationConstant.TITLE_FICA_RATE] = "FICA Rate",
        _b[TranslationConstant.LABEL_FICA_RATE_PERCENT] = "FICA Percent",
        _b[TranslationConstant.CHOOSE_SUITABLE_ACTION] = "More Actions!",
        _b[TranslationConstant.PROJECT_CHANGE] = "Project Change",
        _b[TranslationConstant.RETURN_VENDOR_PAYMENT] = "Return Vendor Payment ",
        _b[TranslationConstant.UPLOAD_TIMECARD_BACKUP_TITLE] = "Upload Timecard Backup",
        _b[TranslationConstant.UPLOAD_FAILED] = "Document upload failed",
        _b[TranslationConstant.ERROR_PASSWORD] = "Password must be 10 to 50 characters with no whitespace and must contain 1 uppercase, 1 lowercase, 1 number, and 1 special character from ~!#$%^&-_",
        _b[TranslationConstant.LABEL_EMPLOYER_IDENTIFICATION_NUMBER] = "Employer identification number",
        _b[TranslationConstant.MODAL_TEXT_FOR_1099_WORKER] = "Has any information on your W9 changed?",
        _b[TranslationConstant.LABEL_LEDGER] = "Ledger",
        _b[TranslationConstant.TITLE_FUTA_RATES] = "FUTA Rates",
        _b[TranslationConstant.LABEL_FUTA_RATES_PERCENT] = "FUTA Percent",
        _b[TranslationConstant.TITLE_SUTA_RATES] = "SUTA Rates",
        _b[TranslationConstant.LABEL_SUTA_RATES_PERCENT] = "SUTA Percent",
        _b[TranslationConstant.CREATE_TIME_SHEET] = "Create Timesheet",
        _b[TranslationConstant.RETURNED_COMMENTS] = "Returned Comments",
        _b[TranslationConstant.REALLOCATE_ACCOUNT] = "AR export closed and Invoice has been generated. Please void the invoice in order to reallocate the Account.",
        _b[TranslationConstant.LABEL_OWNER] = "Owner",
        _b[TranslationConstant.LABEL_PRE_BILL] = "Pre-Bill",
        _b[TranslationConstant.RETURN_TO_PAYREEL_TEXT] = "Return To PayReel",
        _b[TranslationConstant.ADMIN_REPORTS_TITLE] = "Admin Reports",
        _b[TranslationConstant.LABEL_ENTRY_TYPE] = "Entry Type",
        _b[TranslationConstant.LABEL_DEBIT] = "Debit",
        _b[TranslationConstant.LABEL_EXTENDS] = "Extend",
        _b[TranslationConstant.LABEL_AMOUNT_AVAILABLE_FOR_VENDOR_INVOICES] = "Amount available for vendor invoices",
        _b[TranslationConstant.LABEL_AVAILABLE_BALANCE] = "Available Balance",
        _b[TranslationConstant.LABEL_PENDING_TRANSACTIONS] = "Pending Transactions",
        _b[TranslationConstant.LABEL_CURRENT_BALANCE] = "Current Balance",
        _b[TranslationConstant.LABEL_OPENING_BUDGET] = "Opening Budget",
        _b[TranslationConstant.LABEL_ACCOUNT_VALIDATION_FAILED] = "Account Validation Failed.",
        _b[TranslationConstant.RETURN_TO_CLIENT] = "Return To Client",
        _b[TranslationConstant.RETURN_TO_CLIENT_COMMENTS] = "Return To Client Comments:",
        _b[TranslationConstant.RETURN_TO_PAYREEL_CLIENT_SUCCESS] = "Successfully Returned to PayReel Client.",
        _b[TranslationConstant.RETURN_TO_PAYREEL_CLIENT_FAILURE] = "Returned to PayReel Client Failed. Please retry",
        _b[TranslationConstant.LABEL_FROM_DATE] = "From Date",
        _b[TranslationConstant.LABEL_TO_DATE] = "To Date",
        _b[TranslationConstant.FILE_UPLOAD_1099_TITLE] = "1099 Statements",
        _b[TranslationConstant.LABEL_FILE_UPLOAD_1099_TYPE] = "1099 File Type",
        _b[TranslationConstant.LABEL_1099_WORKER] = "1099 Worker",
        _b[TranslationConstant.LABEL_W2_VENDOR] = "W2 / Vendor",
        _b[TranslationConstant.SELECT_USER] = "User",
        _b[TranslationConstant.TITLE_WC_RATES] = "WC Rates",
        _b[TranslationConstant.LABEL_WC_RATES_PERCENT] = "Percent",
        _b[TranslationConstant.LABEL_WORKERS_COMP_CODE] = "Workers Comp Code",
        _b[TranslationConstant.LABEL_ACA_REPORT_END_DATE] = "Report End Date(Will pull prior 1 year)",
        _b[TranslationConstant.SYMMETRY_PAYROLL_POINT_BATCH_EXPORT] = "Symmetry Payroll Point Batch Export",
        _b[TranslationConstant.GET_EXPORT] = "Get Export",
        _b[TranslationConstant.BTN_EXPORT_FILE_FORM_HEADING] = "Please use the below button to export file",
        _b[TranslationConstant.USER_DETAILS_SAVED_SUCCESSFULLY] = "User details has been saved successfully",
        _b[TranslationConstant.LABEL_VENDOR_BILLING_BY_PO] = "Vendor Billing by PO",
        _b[TranslationConstant.VENDOR_PAYMENT_OFFSET_VALUE] = "Vendor Payment Date Offset Value",
        _b[TranslationConstant.SAVE_TIME] = "Save Time",
        _b[TranslationConstant.PAY_STUB_DETAILS] = "Pay Stub Details",
        _b[TranslationConstant.LABEL_CHECK_NUMBER] = "Check Number",
        _b[TranslationConstant.CLIENT_USER_MAPPING] = "Client Users Page Mapping",
        _b[TranslationConstant.SELECT_EITHER_OPTION] = "Please select either the Date to and from or select the Month Billed",
        _b[TranslationConstant.LABEL_CREATED_DATE_FROM] = "Created Date From",
        _b[TranslationConstant.LABEL_CREATED_DATE_TO] = "Created Date To",
        _b[TranslationConstant.LABEL_SELECT_CLIENT] = "Please select Clients",
        _b[TranslationConstant.EMPLOYER_POSTING_FORM_TITLE] = "Select the State, county, city to upload employer posting",
        _b[TranslationConstant.EMPLOYER_POSTINGS] = "Employer Postings",
        _b[TranslationConstant.EMPLOYER_POSTING_ADDED] = "Employer Posting have been saved successfully",
        _b[TranslationConstant.EMPLOYER_POSTING_UPDATED] = "Employer Posting have been updated successfully",
        _b[TranslationConstant.EMPLOYER_POSTING_FORM_TITLE] = "Select the State, county, city to upload employer posting",
        _b[TranslationConstant.LABEL_VIEW_MANUAL_PAYMENT_TITLE] = "View Manual Payment",
        _b[TranslationConstant.APPROVE_TIMESHEET_SUCCESS_MSG] = "Your request has been successfully queued for processing. You will receive an email notification with the processing results.",
        _b[TranslationConstant.PAID_SICK_LEAVE_RULES_FILE_UPLOAD] = "Please Choose Paid Sick Leave Rules File to Upload.",
        _b[TranslationConstant.LABEL_CONFIRMATION] = "Confirmation",
        _b[TranslationConstant.LABEL_PLEASE_SELECT_YEAR] = "Please Select Year",
        _b[TranslationConstant.LABEL_RESULT] = "Result",
        _b[TranslationConstant.LABEL_ROLLOVER_SUCCESSFULLY] = "Rollover successfully",
        _b[TranslationConstant.CLIENT_INFO_SAVED_MESSAGE] = "Client info saved successfully!",
        _b[TranslationConstant.CLIENT_INFO_SAVED_DESC] = "Please make sure to enter all eligible rates for client like admin rate, expense rate, equipment rate etc. to complete client setup.",
        _b[TranslationConstant.INVOICE_VOIDED] = "Invoice Voided",
        _b[TranslationConstant.RETURN_TO_PAYROLL] = "Return To Payroll",
        _b[TranslationConstant.LABEL_EARNING_CODE] = "Earning Code",
        _b[TranslationConstant.LABEL_DEFAULT_OPTION_ALL_CLIENT] = "All Client",
        _b[TranslationConstant.LABEL_WORKERS_COMPENSATION] = "Worker's Compensation",
        _b[TranslationConstant.LABEL_GENERAL_LIABILITY] = "General Liability",
        _b[TranslationConstant.LABEL_ALL_CLIENTS] = "All Clients",
        _b[TranslationConstant.LABEL_WORK_START_DATE] = "Work Start Date",
        _b[TranslationConstant.LABEL_WORK_END_DATE] = "Work End Date",
        _b[TranslationConstant.REPORT_TYPE] = "Report Type",
        _b[TranslationConstant.INVOICED_START_DATE] = "Invoice Start Date",
        _b[TranslationConstant.INVOICED_END_DATE] = "Invoice End Date",
        _b[TranslationConstant.STATE_SETUP_FORM_HEADING] = "Please select status",
        _b[TranslationConstant.EDIT_STATE_SETUP_FORM_HEADING] = "Please change the fields below.",
        _b[TranslationConstant.LABEL_BATCHED_DATE] = "Batched Date",
        _b[TranslationConstant.LABEL_BATCHED_START_DATE] = "Batched Start Date",
        _b[TranslationConstant.LABEL_BATCHED_END_DATE] = "Batched End Date",
        _b[TranslationConstant.SELECT_EITHER_OPTIONS] = "Please select either one option from or select Batched Start and End Date",
        _b[TranslationConstant.EMAIL_RESEND_EMPLOYEE_AGREEMENT] = "Employee email has been resent.",
        _b[TranslationConstant.GEOLOCATION_FENCING] = "Enable Geofencing",
        _b[TranslationConstant.INVOICING_INFO] = "Invoicing Info",
        _b[TranslationConstant.WC_MARKUP_TITLE] = "WC Markup",
        _b[TranslationConstant.LABEL_MARKUP_PERCENT] = "Markup Percent",
        _b[TranslationConstant.HOURS_MORE_THAN_ZERO] = "Entered hours should be more than 0",
        _b[TranslationConstant.REPORT_NOT_EXIST] = "Report does not exist",
        _b[TranslationConstant.ISSUE_SETTING_ALLOCATED] = "Allocation is not yet completed. Please complete the allocation or contact the system administrator for assistance.",
        _b[TranslationConstant.ERROR_IN_SAVE_LOCATION_MAPPING] = "Error in Save Location Mapping.",
        _b[TranslationConstant.PERFORMANCE_COMPLIANCE_ITEMS] = "Performance Compliance Items",
        _b[TranslationConstant.LABEL_PHONE_ASH] = "Phone #",
        _b[TranslationConstant.NO_POSITION_ERROR] = "Position is Required",
        _b[TranslationConstant.EDIT_BELOW_FIELDS_FORM_HEADING] = "Please change the below fields to edit.",
        _b[TranslationConstant.LABEL_DATA] = "Data",
        _b[TranslationConstant.LABEL_BEGIN_DATE] = "Begin Date",
        _b[TranslationConstant.FORMTITLE_EMPLOYEE_POSITION_REPORT] = "Employee Position Report",
        _b[TranslationConstant.LABEL_WORKED_START_DATE] = "Worked Start Date",
        _b[TranslationConstant.LABEL_WORKED_END_DATE] = "Worked End Date",
        _b[TranslationConstant.RECORD_DELETED_SUCCESSFULLY] = "Record deleted successfully",
        _b[TranslationConstant.UNABLE_TO_LOAD_FILE] = "Unable to load file",
        _b[TranslationConstant.LABEL_LOCATION] = "Location",
        _b[TranslationConstant.LABEL_MANAGEMENT_ADMIN_FEE_TERM_CODE] = "Management/Admin Fee-Term Code",
        _b[TranslationConstant.LABEL_SUPPLIER_NUMBER] = "Supplier Number",
        _b[TranslationConstant.LABEL_ADDRESS_LINE_1] = "Address Line 1",
        _b[TranslationConstant.LABEL_ADDRESS_LINE_2] = "Address Line 2",
        _b[TranslationConstant.LABEL_PAYREEL_LOGO] = "PayReel Logo",
        _b[TranslationConstant.LABEL_TERMS] = "Terms",
        _b[TranslationConstant.LABEL_PROJECT_NAME] = "Project Name",
        _b[TranslationConstant.LABEL_INVOICE_LAYOUT] = "Invoice Layout",
        _b[TranslationConstant.LABEL_INVOICE_BY_EVENT_PROJECT] = "Invoice by event/project",
        _b[TranslationConstant.LABEL_INCLUDE_SEPARATE_ADMIN_FEE] = "Include Separate Admin Fee",
        _b[TranslationConstant.LABEL_INCLUDE_INVOICE_NUMBER] = "Include Invoice Number",
        _b[TranslationConstant.LABEL_SEPARATE_ADMIN_FEE_INVOICE] = "Separate Admin fee Invoice",
        _b[TranslationConstant.LABEL_SEND_REMINDER_EMAIL_TO_CLIENT] = "Send Reminder Email To Client",
        _b[TranslationConstant.LABLE_MULTIPLE_INVOICE_EMAILS] = "Multiple Invoice Emails",
        _b[TranslationConstant.LABEL_NO_OF_DAYS] = "No Of Days",
        _b[TranslationConstant.LABEL_SEND_TO_DELIVERY_LIST] = "Send To Delivery List",
        _b[TranslationConstant.LABEL_ATTACHED_REPORTS] = "Attached Reports",
        _b[TranslationConstant.LABEL_EMAIL_SUBJECT] = "Email Subject",
        _b[TranslationConstant.LABEL_EMAIL_BODY] = "Email Body",
        _b[TranslationConstant.LABEL_INVOICE_DETAILS] = "Invoice Details",
        _b[TranslationConstant.LABEL_INVOICE_DELIVERY_DETAILS] = "Invoice Delivery Details",
        _b[TranslationConstant.LABEL_CLIENT_ADDRESS] = "Client Address",
        _b[TranslationConstant.LABEL_LOGO_ADMIN_PAYREEL_LOGO] = "PayReel Admin Fee Logo",
        _b[TranslationConstant.LABEL_QVC_PA_MARKUP] = "QVC PA markup",
        _b[TranslationConstant.ERROR_CLIENT_INFO_DATA_NOT_EXIST] = "Client Info data not available. Please fill the client info and revisit this tab.",
        _b[TranslationConstant.LABEL_EMP_ID] = "Employee ID",
        _b[TranslationConstant.COMPANY_NUMBER] = "Company Number",
        _b[TranslationConstant.SWITCH_PER_DIEM_LOW_HIGH] = "Switch per diem from Low to high",
        _b[TranslationConstant.MANUAL_PAYMENT_CREATED_SUCCESS_MSG] = "Manual Payment created successfully.",
        _b[TranslationConstant.CHARGE_TYPE_EXISTS] = "Charge Type already exists.",
        _b[TranslationConstant.DARWIN_CODE_EXISTS] = "Darwin Code already exists.",
        _b[TranslationConstant.AGENCY_RESPONSIBLE] = "Agency Responsible",
        _b[TranslationConstant.EDIT_AGENCY_RES_BTN] = "Edit Agency Responsible Checklist",
        _b[TranslationConstant.CLIENT_ALERT_SETUP] = "Client Setup Alert",
        _b[TranslationConstant.FEDERAL_UNEMPLOYMENT_INSERTED] = "Fenderal Unemployment Inserted",
        _b[TranslationConstant.LABEL_NEXT] = "Next",
        _b[TranslationConstant.LABEL_AMOUNT_TO] = "Amount To",
        _b[TranslationConstant.LABEL_AMOUNT_FROM] = "Amount From",
        _b[TranslationConstant.MULTIPLE_EMAIL_BASED_LABEL] = "Multiple Emails based on",
        _b[TranslationConstant.LABEL_INSERT] = "Insert",
        _b[TranslationConstant.ADD_NEW_CONDITION] = "Add New Condition",
        _b[TranslationConstant.VALIDATE_AMOUNT_FAILURE] = "Amount From/Amount To lies in already existing range. Please select different range.",
        _b[TranslationConstant.ADD_CLIENT_SAVED_MESSAGE] = "Client has not been created yet.",
        _b[TranslationConstant.ADD_CLIENT_SAVED_DESC] = "Please click Continue to proceed and complete invoicing info to create client or click Cancel to exit saving client.All your client data will be lost if you do not save client invoicing info.",
        _b[TranslationConstant.ADD_CLIENT_CREATED_HEADING] = "Complete Invoicing Information!",
        _b[TranslationConstant.STATE_UNEMPLOYMENT_INSERTED] = "State Unemployment Inserted",
        _b[TranslationConstant.CREATED_SUCCESS_MSG] = "Job Type created successfully.",
        _b[TranslationConstant.LABEL_MANAGER_ACCESS] = "Manager Access",
        _b[TranslationConstant.LABEL_SUPERVISOR_ACCESS] = "Supervisor Access",
        _b[TranslationConstant.LABEL_CLIENT_WORKER_ACCESS] = "Client Worker Access",
        _b[TranslationConstant.SOCIAL_SECURITY_INSERTED] = "Social Security Inserted",
        _b[TranslationConstant.VALIDATE_UPLOADED_FILE_TIMESHEET] = "Validate Uploaded File For Timehsheets",
        _b[TranslationConstant.VALIDATE_UPLOADED_FILE_EXPENSE] = "Validate Uploaded File For Expense",
        _b[TranslationConstant.REPORT_MODE] = "Report Mode",
        _b[TranslationConstant.LABEL_PAYREEL_COORDINATORS_LIST] = "PayReel Coordinators",
        _b[TranslationConstant.LABEL_QUARTER] = "Quarter",
        _b[TranslationConstant.MULTIFACTOR_AUTHENTICATION_TITLE] = "Multi-Factor Authentication",
        _b[TranslationConstant.LABEL_WEB_USERID] = "Web UserID",
        _b[TranslationConstant.LABEL_ENABLE_2FACTOR_AUTHENTICATION] = "Enable 2-Factor Authentication",
        _b[TranslationConstant.TITLE_SEXUAL_HARASSMENT_TRAINING_REQUIREMENTS] = "Sexual Harassment Training Requirements",
        _b[TranslationConstant.LABEL_RE_TRAINING_REQUIREMENT] = "Re-Training Requirement",
        _b[TranslationConstant.LABEL_WEBSITE_TRAINING_LINK] = "Website Training Link",
        _b[TranslationConstant.LABEL_ONE_YEAR] = "1 Year",
        _b[TranslationConstant.LABEL_TWO_YEAR] = "2 Year",
        _b[TranslationConstant.LABEL_THREE_YEAR] = "3 Year",
        _b[TranslationConstant.LABEL_FOUR_YEAR] = "4 Year",
        _b[TranslationConstant.LABEL_FIVE_YEAR] = "5 Year",
        _b[TranslationConstant.LABEL_NO_REQUIREMENT] = "No Requirement",
        _b[TranslationConstant.LABEL_CLIENT_INFO] = "Client Info",
        _b[TranslationConstant.LABEL_ACCOUNTS] = "Accounts",
        _b[TranslationConstant.LABEL_ALL_STATUSES] = "All Statuses",
        _b[TranslationConstant.LABEL_PAID_START_DATE] = "Paid Start Date",
        _b[TranslationConstant.LABEL_PAID_END_DATE] = "Paid End Date",
        _b[TranslationConstant.HEADING_INFO] = "Info",
        _b[TranslationConstant.EDIT_INFO_MSG] = "Please make changes to update.",
        _b[TranslationConstant.PER_DIEM_LEVEL] = "Per Diem Level",
        _b[TranslationConstant.PER_DIEM_LEVEL_LOW_LABEL] = "Low",
        _b[TranslationConstant.PER_DIEM_LEVEL_HIGH_LABEL] = "High",
        _b[TranslationConstant.DELETE_DOCUMENT_CONFIRMATION] = "Are you sure you want to delete this document?",
        _b[TranslationConstant.CLIENT_INVOICING_INFO_SAVED_SUCCESS] = "Client Invoicing Info updated successfully",
        _b[TranslationConstant.NEP_WS_LOG_COMPANY_DIVISION_LABEL] = "Company Division",
        _b[TranslationConstant.LABEL_SSN_4_DIGITS] = "Last 4 digits of SSN",
        _b[TranslationConstant.CLICK_ON_CALENDAR_ICON] = "Click the calendar icon to select a date",
        _b[TranslationConstant.LABEL_DEFAULT_MASTER_CLIENT] = "Select Master Client",
        _b[TranslationConstant.BULK_IMPORT_HISTORY] = "Bulk Import History",
        _b[TranslationConstant.BULK_VALIDATION_ERROR] = "Bulk Validation Error",
        _b[TranslationConstant.BULK_VALIDATION_CLOSE] = "Close",
        _b[TranslationConstant.NO_BULK_VALIDATION_ERROR] = "No Validation Error found in bulk Import.",
        _b[TranslationConstant.NO_BULK_ERROR_FOUND] = "No Error Found.",
        _b[TranslationConstant.FILE_NOT_FOUND] = "File Not Found",
        _b[TranslationConstant.TIMECLOCK_DELETE_TIMESTAMP] = "Are you sure you want to delete this timestamp? This action cannot be undone.",
        _b[TranslationConstant.DELETE_TIMESTAMP_HEADING] = "Delete Timestamp",
        _b[TranslationConstant.SEARCH_EMPLOYEE_TIMECLOCK] = "Time Clock Management",
        _b[TranslationConstant.ADD_MISSING_CLOCK_IN_OUT] = "Add Missing Clock In/Out",
        _b[TranslationConstant.Enable_MobileTimeClockApp] = "Enable Mobile Time Clock App",
        _b[TranslationConstant.END_DATE_VALIDATION] = "End date must be greater than or equal to start date.",
        _b[TranslationConstant.LABEL_EXPANDEDDATAVIEW] = "Expanded Data View",
        _b[TranslationConstant.SEARCH_INVOICES] = "Search Invoices",
        _b[TranslationConstant.LABEL_APPROVE_PAYMENTS_TITLE] = "Approve Payments",
        _b[TranslationConstant.MY_PAYMENTS_REPORT] = "My Payments",
        _b[TranslationConstant.ALL_PAYMENTS_REPORT] = "All Payments",
        _b[TranslationConstant.APPROVE_SELECTED] = "Approve Selected",
        _b[TranslationConstant.SELECT_ALL] = "Select All",
        _b[TranslationConstant.LABEL_APPROVE_PAYMENTS] = "Approve Payments",
        _b[TranslationConstant.DESELECT_ALL] = "Unselect All",
        _b[TranslationConstant.CONFIRMATION_MESSAGE] = "Please confirm that you want to approve:",
        _b[TranslationConstant.LABEL_MANUAL_PAYMENT] = "Manual Payment",
        _b[TranslationConstant.ATLEAST_ONE_RECORD_REQUIRED_TO_APPROVE] = "At least one record rquired to approve",
        _b[TranslationConstant.LABEL_APPROVAL_STATUS] = "Approval Status",
        _b[TranslationConstant.PROJECTS] = "Projects",
        _b[TranslationConstant.ENABLE_PROJECTS] = "Enable Projects",
        _b[TranslationConstant.PROJECTS_ALIAS] = "Projects Alias",
        _b[TranslationConstant.ADD_PROJECT] = "Add Project",
        _b[TranslationConstant.PROJECT_NAME] = "Project Name",
        _b[TranslationConstant.ALL_ITEM_CHECKED] = "All items checked",
        _b[TranslationConstant.ITEM_CHECKED] = "items checked",
        _b[TranslationConstant.SEARCH_PROMPT] = "Please enter your search criteria, or just click Search for all results",
        _b[TranslationConstant.INCLUDE_ON_INVOICE] = "Include On Invoice",
        _b[TranslationConstant.WORK_LOCATION_UNAVAILABLE] = "If work location is unavailable in the drop down,",
        _b[TranslationConstant.CLICK] = "click",
        _b[TranslationConstant.ADD_NEW_WORK_LOCATION] = "to add a new work location or call us at",
        _b[TranslationConstant.WORK_LOCATION_NAME] = "Work Location Name",
        _b[TranslationConstant.PROJECT_DETAILS] = "Project Details",
        _b[TranslationConstant.DETAILS] = "Details",
        _b[TranslationConstant.INDIVIDUAL] = "Individual",
        _b[TranslationConstant.PRE_POPULATE_OR_NEW_WORKER_INFO_PROMPT] = "Select an existing individual to pre-populate their information, or enter the required information to request a new worker.",
        _b[TranslationConstant.ADD_WORKER] = "Add Worker",
        _b[TranslationConstant.EDIT_WORKER] = "Edit Worker",
        _b[TranslationConstant.DRIVING_REQUIRED] = "Driving Required",
        _b[TranslationConstant.MINOR] = "Minor",
        _b[TranslationConstant.MINOR_PARENT_GUARDIAN_NAME] = "Minor Parent/Guardian Name",
        _b[TranslationConstant.EMPLOYEE_MAS_ID] = "Employee MAS ID",
        _b[TranslationConstant.WORKER_STATUS] = "Worker Status",
        _b[TranslationConstant.REMOVE_WORKER_CONFIRMATION] = "Are you sure you want to remove this worker from the project?",
        _b[TranslationConstant.NEEDS_REVIEW] = "Needs Review",
        _b[TranslationConstant.REVIEW] = "Review",
        _b[TranslationConstant.REVIEWED] = "Reviewed",
        _b[TranslationConstant.DAILY] = "Daily",
        _b[TranslationConstant.HALF_DAY] = "Half Day",
        _b[TranslationConstant.WORKER_REQUEST_UNDER_REVIEW] = "Worker Requested Under Review",
        _b[TranslationConstant.CURRENTLY_ONBOARDING] = "Currently Onboarding",
        _b[TranslationConstant.APPROVED_TO_START_WORK] = "Approved to Start Work",
        _b[TranslationConstant.PAID_IN_FULL] = "Paid in Full",
        _b[TranslationConstant.EXPORT_TO_EXCEL] = "Export to Excel",
        _b[TranslationConstant.WORKERS] = "Workers",
        _b[TranslationConstant.AR_CSV_START_DATE] = "AR CSV Start Date",
        _b[TranslationConstant.AR_CSV_END_DATE] = "AR CSV End Date",
        _b[TranslationConstant.IDENTIFICATION_NUMBER_TYPE] = "Identification Number Type",
        _b[TranslationConstant.EIN] = "EIN",
        _b[TranslationConstant.LABEL_ENTITY] = "Entity",
        _b[TranslationConstant.REQUEST_EXEMPT_CLASSIFICATION] = "Request Exempt Classification",
        _b[TranslationConstant.EXEMPT_CLASSIFICATION] = "Exempt Classification",
        _b[TranslationConstant.EDIT_EXEMPT_CLASSIFICATION] = "Edit Exempt Classification",
        _b[TranslationConstant.LABEL_WEEKLY_PAY_RATES] = "Weekly Pay Rate",
        _b[TranslationConstant.PRE_POPULATE_EMPLOYEE_INFO_PROMPT] = "Select an existing employee to pre-populate their information, or enter the required information to request classification for a new employee.",
        _b[TranslationConstant.ENABLE_EXEMPT_CLASSIFICATION] = "Enable Exempt Classification",
        _b[TranslationConstant.DELETE_EXEMPT_CONFIRMATION] = "Are you sure you want to permanently delete this exempt classification request?",
        _b[TranslationConstant.CEVN_TICKET] = "CEVN Ticket",
        _b[TranslationConstant.WAITING_ON_CLASSIFICATION] = "Waiting on classification",
        _b[TranslationConstant.WAITING_ON_MORE_INFO_FROM_CLIENT] = "Waiting on more information from client",
        _b[TranslationConstant.CLASSIFICATION_APPROVED] = "Classification approved",
        _b[TranslationConstant.CLASSIFICATION_DENIED] = "Classification denied",
        _b[TranslationConstant.JOB_DESCRIPTION] = "Job Description",
        _b[TranslationConstant.DROP_FILES_TO_UPLOAD] = "Drop files here to upload",
        _b[TranslationConstant.SELECT_FILES] = "Select files...",
        _b[TranslationConstant.MARK_AS_REVIEWED] = "Mark as reviewed",
        _b[TranslationConstant.ORIGINAL_FILE] = "Original File",
        _b[TranslationConstant.FAILURES_FILE] = "Failures File",
        _b[TranslationConstant.LABEL_PREPAID_EXPENSES] = "Expense Entry",
        _b[TranslationConstant.LABEL_EVENT_NAME] = "Event Name",
        _b[TranslationConstant.LABEL_EMPLOYEE_APPROVAL] = "Employee Approval",
        _b[TranslationConstant.LABEL_PREPAID_EXPENSES_TITLE] = "Please enter your filter criteria to create expense entry.",
        _b[TranslationConstant.LABEL_CHECK_ALL_TITLE] = "Check All",
        _b[TranslationConstant.LABEL_CHECK_NONE_TITLE] = "Check None",
        _b[TranslationConstant.LABEL_EXPENSES] = "Expenses",
        _b[TranslationConstant.LABEL_ADD_EXPENSE] = "Add Expense",
        _b[TranslationConstant.EXPENSE_ENTRY_PROCESSED] = "Expense Entry Processed Summary",
        _b[TranslationConstant.VALIDATION_ERROR] = "Validation Error",
        _b[TranslationConstant.UPLOAD_FILE_FOR_TIMESHEETS] = "Upload File For Timesheets",
        _b[TranslationConstant.LABEL_ADD_EXPENSES] = "Add Expense(s)",
        _b[TranslationConstant.LABEL_EXPENSE_ENTRY] = "Expense Entry",
        _b[TranslationConstant.LABEL_EXPENSE_ENTRY_ELIGIBLE] = "Expense entry eligible",
        _b[TranslationConstant.DOWNLOAD_PROPOSED_EXPENSES] = "Download Proposed Expenses",
        _b[TranslationConstant.PROJECTED_TOTAL_PAY] = "Projected Total Pay",
        _b[TranslationConstant.LINK_EXPIRED_HEADER_MESSAGE] = "Link Expired",
        _b[TranslationConstant.EMPLOYEE_HANDBOOK_ATTENTION_TEXT] = " By clicking the button below I acknowledge that I have received, reviewed, and understand the contents of the 2025 PayReel Handbook. I agree to comply with the policies and procedures outlined within the handbook. I further understand that my electronic acknowledgment and signature hold the same legal weight and validity as my handwritten signature.",
        _b[TranslationConstant.EMPLOYMENT_AGREEMENT_ENDED_TEXT] = "Your Endeavor temporary employment agreement has reached the end date on {{formattedDate}}, please contact your supervisor or support@payreel.com if you feel this is incorrect.",
        _b[TranslationConstant.EMPLOYEE_AGREEMENT_ENDING_SOON_TEXT] = "Your Endeavor temporary employment agreement will reach the end date on {{formattedDate}}, please contact your supervisor or support@payreel.com if you feel this is incorrect.",
        _b),
    _a);
