/* eslint-disable no-useless-escape */
var AppConstant = {
    CORP_APPLICATION_TYPE_NAME: "CORP",
    ONBOARDING_APPLICATION_TYPE_NAME: "Onboarding",
    CORP_TYPE_APPLICATION: "pro",
    CORP_BASE_PATHNAME: "/pro",
    ONBOARDING_APPLICATION_ADMIN_PATH: "/admin",
    ONBOARDING_APPLICATION_EMPLOYEE_PATH: "/employee",
    ONBOARDING_APPLICATION_CLIENT_PATH: "/client",
    EMPLOYEE_AUTHORIZED_I9_VERIFIER_PATH: "/employeeauthorizedi9Agent",
    INVALID_USER_ROUTE: "/invaliduser",
    AXIOS_API_TIMEOUT: 120000,
    BULK_IMPORT_API_TIMEOUT: 300000,
    INVOICE_API_TIMEOUT: 360000,
    CORP_BULK_IMPORT_API_TIMEOUT: 360000,
    TIME_TO_LOGOUT_TIMER: 1799,
    AXIOS_API_TIMEOUT_FOR_10_MINS: 600000,
    DOCUMENT_UPLOAD_ALLOWED_FILE_EXTENSIONS: [".pdf"],
    MANUALLY_DOCUMENT_UPLOAD_ALLOWED_FILE_EXTENSIONS: [
        ".pdf",
        ".jpg",
        ".gif",
        ".png",
        ".tif",
        ".bmp",
        ".tiff",
        ".jpeg",
    ],
    CALIFORNIA_MINOR_WORK_PERMIT_DOC_ALLOWED: [".pdf", ".jpg", ".gif", ".png", ".tif", ".bmp"],
    FALSE: false,
    TRUE: true,
    ZERO: 0,
    DEFAULT_LOCALE: "en-US",
    LOCAL_STORAGE_LOCALE_KEY: "Locale",
    LOCAL_STORAGE_TOKEN_KEY: "token",
    LOCAL_STORAGE_USER_PROFILE: "userprofile",
    ALLOWED_MASK_CHAR: /^[ #()-]+$/,
    NUM_RANGE_REG_EXP: /^[0-9]\d*(\.\d+)?$/,
    LOCAL_STORAGE_EMPLOYEE_DATA: "employeeprofile",
    EMAIL_REG_EXP: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    PHONE_REG_EXP: /^[0-9 ()+-]+$/,
    USERNAME_REG_EXP: /^\S{8,}$/,
    PASSWORD_REG_EXP: /^(?!.*[ @])(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[~!#$%^&_-]).{10,}$/,
    ZIP_CODE_REG_EXP: /^[0-9]{5}(?:-[0-9]{4})?$/,
    SSN_REG_EXP: /^[0-9-]+$/,
    MONEY_REGEX_EXP: /^\d{0,4}(\.\d{0,2})?$/,
    COMPENSATION_REGEX_EXP: /^\d{0,12}(\.\d{0,2})?$/,
    TEXT_WITH_SPACE_REG_EXP: /^[a-zA-Z][a-zA-Z\s]+$/,
    ADDRESS_REG_EXP: /^[#.0-9a-zA-Z\s,-]+$/,
    TWO_DECIMAL_REGEX: /^\d+(\.\d{1,2})?$/,
    ENGLISH: "English",
    EMPTY_STRING: "",
    ENGLISH_ABBR: "EN",
    COPYRIGHT: "\u00A9 ".concat(new Date().getFullYear(), " PayReel, LLC"),
    PRODUCTION: "production",
    ROOT: "root",
    DEVELOPMENT: "development",
    HOT: "hot",
    EN: "en",
    POST: "POST",
    APP_PATH: "./App",
    PAGE_SIZE: [10, 20, 50, 100, 200],
    BUTTON_COUNT_GRID: 5,
    GRID_ITEM_TAKE_SIZE: 10,
    SECURITY_DEFAULT_ACCESS_VALUE: 1,
    READ_ONLY_USER_ACCESS_VALUE: 2,
    HTTP_SUCCESS_CODES: [200, 201, 202, 204, 206],
    HTTP_BAD_REQUEST_CODES: [400, 403],
    HTTP_INTERNAL_SERVER_ERROR: [500, 503],
    NEW_HIRE_JOB_STATUS_ID_BATCH: 500,
    USER_SESSION_EXTENDED_POPUP_TIME: 5,
    SPECIAL_CHARACTER_REG_EXP: /^[^áãâäàéêëèíîïìóõôöòúûüùçñ’]+$/,
    SESSION_AUTO_RENEW_TIME: 300000,
    ADD_WORK_LOCTION_VENUE_REQUIRED_STATES: [17, 37, 40],
    DOCUMENT_UPLOAD_ALLOWED_FILE_EXTENSIONS_EXCEL: [".xlsx"],
    DOCUMENT_UPLOAD_ALLOWED_FILE_EXTENSION_XLS: [".xls"],
    WORKER_CLIENT: 277,
    SUPERVISOR: "Supervisor",
    MANAGER: "Manager",
    SUBACCOUNT_PAGE_SIZE: 100,
    CITYGROUP_CLIENTID: 91,
    WORK_LOCATION_LEVEL_EMP: 2,
    ADMIN_AS_EMPLOYEE_COOKIE_NAME: "CORP_EmpCookie",
    PAYROLL_TYPES: ["budgetIssues", "releasePayroll", "batches", "searchInvoice"],
    CA_WORK_STATE_ID: 5,
    PAYREEL_TEXT: "PayReel, LLC",
    PAID_SICK_LEAVE_UPLOAD_ALLOWED_FILE_EXTENSION: [".csv"],
    HOURS_REGEX: /^\d{4}$/,
    TIMESHEET_START_TIME_MAX_VALUE: "0888",
    NO_ACCESS_ROLE: 3,
    THREE: 3,
    CSV_ALLOWED_FILE_EXTENSION: [".csv"],
    CSV_XLS_ALLOWED_FILE_EXTENSION: [".csv", ".xls", ".xlsx"],
    DAILY_GUARANTEE_RATE_TYPE: 3,
    TWO: 2,
    ONE: 1,
    XLS_ALLOWED_FILE_EXTENSION: [".xls", ".xlsx"],
    XLSX_ALLOWED_FILE_EXTENSION: [".xlsx"],
    ADD_IN_OUT_TIME_REGEX: /^\d{2}:?([0-5]\d)$/,
    MOBILE_SCREEN_WIDTH: 800,
    USA_COUNTRY_ID: "23",
    PDF_AND_DOC_FILE_TYPES: [".pdf", ".doc", ".docm", ".docx"],
    CANADA_COUNTRY_ID: "5",
};
export default AppConstant;
