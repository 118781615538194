var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { PDFViewer } from "@progress/kendo-react-pdf-viewer";
import { AppConstant, TranslationConstant } from "common/constant";
import { NEW_HIRE_DOCUMENT_TYPE_ID } from "common/constant/Enums";
import Button from "components/shared/Button";
import { FormDatePicker } from "components/shared/FormComponents";
import { Transform } from "helper/Transform";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useCurrentViewContext } from "store/common/currentViewContext";
import { useDashboardContext } from "store/dashboard/dashboard";
import { useJobsViewContext } from "store/jobs/jobs";
var PDF_WIDTH = 612;
export var DocumentViewer = function (props) {
    var navigate = useNavigate();
    var isDocumentForApprovalReq = props.isDocumentForApprovalReq, fileUrl = props.fileUrl, backButtonLink = props.backButtonLink, showSupportDocViewer = props.showSupportDocViewer, isAddOnboardingFilePrefix = props.isAddOnboardingFilePrefix, isAddAWSDirPathInBucket = props.isAddAWSDirPathInBucket, isEmpPrintDocument = props.isEmpPrintDocument, newHireJobID = props.newHireJobID, sendEmailOnEmpDocError = props.sendEmailOnEmpDocError, applicationID = props.applicationID, _a = props.newHireDocumentTypeID, newHireDocumentTypeID = _a === void 0 ? "" : _a;
    var _b = useCurrentViewContext(), userAccessType = _b.userAccessType, newHireDocuemntUrl = _b.newHireDocuemntUrl, isByteData = _b.isByteData, resetByteDataFlag = _b.resetByteDataFlag, fetchDocumentURLByKey = _b.fetchDocumentURLByKey, resetDropdownsValue = _b.resetDropdownsValue;
    var supportingDocument = useJobsViewContext().supportingDocument;
    var _c = useDashboardContext(), employeeDocPdf = _c.employeeDocPdf, getEmployeeDocPDF = _c.getEmployeeDocPDF;
    var pdfViewerTools = [
        "pager",
        "spacer",
        "zoomInOut",
        "search",
        "download",
        "print",
    ];
    var _d = React.useState(false), isPDFLoadedError = _d[0], updatePDFLoadedError = _d[1];
    var previousPageUrl = localStorage.getItem("dmlld2RvY3VtZW50VVJM");
    var _e = React.useState({
        expDate: "",
        isDateSelected: false,
    }), expiryDate = _e[0], setExpiryDate = _e[1];
    React.useEffect(function () {
        if (isEmpPrintDocument) {
            showEmployeeDocForPrint();
        }
        else {
            fetchDocumentURLByKey(fileUrl, isAddOnboardingFilePrefix, isAddAWSDirPathInBucket, applicationID);
        }
        if (backButtonLink)
            backButtonLink(previousPageUrl);
        return function () {
            resetDropdownsValue();
            resetByteDataFlag();
            localStorage.removeItem("dmlld2RvY3VtZW50VVJM");
        };
    }, []);
    var showEmployeeDocForPrint = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getEmployeeDocPDF(newHireJobID)];
                case 1:
                    response = _a.sent();
                    if (response.data === null || response.data === "") {
                        updatePDFLoadedError(true);
                    }
                    if (response.data && response.data.emailModelList) {
                        sendEmailOnEmpDocError(response.data.emailModelList);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var updateExpiryDate = function (e) {
        setExpiryDate({
            expDate: e.target.value,
            isDateSelected: true,
        });
    };
    var onErrorHandler = function (e) {
        if (e.target !== null)
            updatePDFLoadedError(true);
    };
    var onRenderContent = React.useCallback(function (content) {
        return isPDFLoadedError ? (_jsx(content.type, __assign({}, content.props, { className: "pdf-not-available" }, { children: "PDF Not Available" }))) : (_jsx(content.type, __assign({}, content.props, { children: content.props.children })));
    }, [isPDFLoadedError]);
    var _f = React.useState(1.25), defaultZoom = _f[0], setDefaultZoom = _f[1];
    var pdfViewerRef = React.useRef(null);
    React.useEffect(function () {
        var handleResize = function () {
            if (pdfViewerRef.current && pdfViewerRef.current.element.clientWidth <= PDF_WIDTH) {
                var innerWidth_1 = pdfViewerRef.current.element.clientWidth;
                var zoom = innerWidth_1 / PDF_WIDTH;
                setDefaultZoom(zoom);
            }
            else {
                setDefaultZoom(1.25);
            }
        };
        window.addEventListener("resize", handleResize);
        handleResize();
        return function () {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    return (_jsxs(_Fragment, { children: [isDocumentForApprovalReq && (_jsxs(_Fragment, { children: [_jsx(Form, { render: function () { return (_jsx(FormElement, __assign({ className: "general-form approve-document-form" }, { children: _jsxs("fieldset", __assign({ className: "k-form-fieldset row" }, { children: [_jsx("div", __assign({ className: "col-xs-12 col-md-6 mb-3" }, { children: _jsx(Field, { id: "expiryDate", name: "expiryDate", label: _jsx(Transform, { mkey: TranslationConstant.LABEL_EXPIRY_DATE }), component: FormDatePicker, value: expiryDate.expDate, onChange: updateExpiryDate }) })), _jsx("button", __assign({ type: "submit", className: "k-button k-button-md k-rounded-md k-button-solid k-button-solid-base k-approve-btn ".concat(userAccessType ===
                                            AppConstant.READ_ONLY_USER_ACCESS_VALUE
                                            ? "access-disabled"
                                            : ""), disabled: !expiryDate.isDateSelected }, { children: _jsx(Transform, { mkey: TranslationConstant.BUTTON_APPROVE }) }))] })) }))); } }), _jsx("section", __assign({ className: "button-wrapper" }, { children: _jsxs("ul", { children: [_jsx("li", { children: _jsx(Button, { buttonText: _jsx(Transform, { mkey: TranslationConstant.BACK_BUTTON }), buttonIconClass: "fa-arrow-left", buttonClass: "btn-success", buttonClickHandler: function () { return navigate(-1); } }) }), _jsx("li", { children: _jsx(Button, { buttonText: _jsx(Transform, { mkey: TranslationConstant.BUTTON_NOT_APPROVE }), buttonClass: "btn-success", buttonClickHandler: function () { return navigate(-1); }, disabledForReadOnlyRoles: true }) })] }) }))] })), newHireDocumentTypeID === NEW_HIRE_DOCUMENT_TYPE_ID.PAYREEL_HANDBOOK ? (_jsx("div", __assign({ className: "documentViewer" }, { children: _jsx("iframe", { src: "".concat(newHireDocuemntUrl), width: "100%", height: "100%", title: "PDF Page" }) }), "".concat(newHireDocuemntUrl))) : (_jsx("div", __assign({ className: "documentViewer ".concat(isPDFLoadedError ? "pdf-error-loading" : "") }, { children: showSupportDocViewer ? (_jsx(PDFViewer, { data: supportingDocument, tools: pdfViewerTools, onError: onErrorHandler, onRenderContent: onRenderContent, defaultZoom: defaultZoom })) : (_jsx(PDFViewer, { ref: pdfViewerRef, url: isEmpPrintDocument ? null : isByteData ? null : newHireDocuemntUrl, data: isEmpPrintDocument
                        ? employeeDocPdf
                        : isByteData
                            ? newHireDocuemntUrl
                            : null, defaultZoom: defaultZoom, tools: pdfViewerTools, onError: onErrorHandler, onRenderContent: onRenderContent })) }), "".concat(defaultZoom)))] }));
};
